import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import msSalService from '../../services/msSalService';
import { COLORS } from '../../utils/Application_Constants';
import TimeoutModal from '../LogOutPage/TimeoutModal';

const styles = (theme) => ({
	root: {
		backgroundColor: 'white',
		width: '100%',
		display: 'flex',
	},
	welcomeWrapper: {
		width: '100%',
	},
	logoWrapper: {
		maxWidth: '243px',
		paddingTop: '20px',
	},
	text: {
		backgroundColor: 'white',
		textAlign: 'left',
		padding: '20px',
	},
	buttonWrapper: {
		justifyContent: 'center',
	},
	buttonClass: {
		fontWeight: '700',
		width: '50%',
		background: COLORS.DENIM,
		color: 'White',
		justifyContent: 'center',
	},
});
class Home extends Component {
	constructor() {
		super();
		this.state = {
			displayLoggedOut: false,
		};
	}

	componentDidMount() {
		var path = window.location.hash;
		var loginURL = process.env.REACT_APP_LOGIN_URL;
		console.log(' Home.js  path ' + path);
		if (loginURL.includes('locumtenens.com')) {
			if (path.length <= 2 || path.includes('logout')) {
				msSalService.setNewRedirectUri();
				msSalService.logOut();
			} else {
				msSalService.signIn();
			}
		} else {
			if (path !== '#/')
				// this is path when we log in first time.
				this.setState({ displayLoggedOut: true });
			else {
				msSalService.signIn();
			}
		}
	}
	redirectToLogin = () => {
		this.setState({ displayLoggedOut: false });
		var path = window.location.hash;
		var loginURL = process.env.REACT_APP_LOGIN_URL;
		if (loginURL.includes('locumtenens.com')) {
			if (path.length <= 2 || path.includes('logout')) {
				msSalService.setNewRedirectUri();
				msSalService.logOut();
			} else {
				msSalService.signIn();
			}
		} else {
			msSalService.signIn();
		}
	};

	toggleLogoutDialog = (value) => {
		// this.setState({ displayLogoutModal: value });
		// msSalService.redirectToSignIn();
	};

	renderLoggedoutDialog = () => {
		const { displayLoggedOut } = this.state;
		return (
			<>
				<TimeoutModal
					showModal={displayLoggedOut}
					handleSignin={this.redirectToLogin}
					togglePopup={this.toggleLogoutDialog}
				/>
			</>
		);
	};
	render() {
		const { classes } = this.props;
		return (
			<div className={classes.container}>
				{this.renderLoggedoutDialog()}
			</div>
		);
	}
}

Home.propTypes = {
	classes: PropTypes.shape({
		root: PropTypes.string.isRequired,
		welcomeWrapper: PropTypes.string.isRequired,
		logoWrapper: PropTypes.string.isRequired,
		text: PropTypes.string.isRequired,
		buttonWrapper: PropTypes.string.isRequired,
		buttonClass: PropTypes.string.isRequired,
	}).isRequired,
};

export default withStyles(styles)(Home);
