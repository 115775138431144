import React, { Component } from 'react';
import TimeAndPayV2 from './TimeAndPayV2';

class TimeAndPayWrapper extends Component {
	render() {
		return (
			<>
				<TimeAndPayV2 />
			</>
		);
	}
}

export default TimeAndPayWrapper;
