import React, { Component } from 'react';
import { withStyles, Container, Grid, Paper, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../utils/Application_Constants';
import UserContext from '../../contexts/userContext';
import WithContexts from '../../contexts/withContexts';
import AccountV2 from '../../components/ContentComponents/Account/AccountV2';
import ErrorDialog from '../../components/GlobalComponents/Dialog/ErrorDialog';

const styles = (theme) => ({
	root: {
		padding: '80px 0',
		minHeight: '800px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '60px',
			marginTop: '25px',
		},
		backgroundColor: COLORS.BLACK_HAZE,
	},
	dropdownRoot: {
		padding: '10px 0',
		display: 'flex',
		flexWrap: 'wrap',
	},
	helpPanelRoot: {
		marginTop: '30px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	dropdownContainerRoot: {
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		width: '100%',
		padding: '20px 20px',
		textAlign: 'left',
		borderRadius: '3px',
		position: 'relative',
	},
	buttonRoot: {
		lineHeight: 'inherit',
		margin: '0 30px 20px',
		padding: 0,
		width: '100%',
		borderRadius: '0px',
		textTransform: 'none',
		boxShadow: 'none',
	},
	helpPanelContainerRoot: {
		padding: '20px',
		display: 'flex',
		flexWrap: 'wrap',
	},
	helpPanelHeader: {
		marginBottom: '10px',
	},
	helpPanelLinks: {
		textAlign: 'left',
		marginBottom: '10px',
	},
	helpPanelButton: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		borderLeft: `1px solid ${COLORS.WHITE_LILAC}`,
		width: '100%',
		borderTop: `1px solid ${COLORS.WHITE_LILAC}`,
		paddingBottom: '10px',
		textTransform: 'inherit',
	},
	header: {
		color: COLORS.ST_TROPAZ,
		fontWeight: '700',
		fontSize: '20px',
		margin: 0,
	},
	linkHeader: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '20px',
		margin: 0,
	},
	chevronRoot: {
		position: 'absolute',
		width: '25px',
		right: '20px',
		color: COLORS.TRUE_BLUE,
	},
	progressWrapper: {
		height: '80vh',
	},
	progressRoot: {
		marginTop: '25vh',
	},
	link: {
		color: COLORS.TRUE_BLUE,
		marginBottom: '10px',
		textDecoration: `underline dotted ${COLORS.LIGHT_BLUE}`,
		fontWeight: '400',
		fontSize: '16px',
		cursor: 'pointer',
		display: 'block',
	},
	disabeldInput: {
		opacity: 0.6,
	},
	saveButton: {
		marginLeft: '32px',
		borderRadius: '0px',
		textTransform: 'none',
		boxShadow: 'none',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: '20px 32px',
		},
	},
	saveMessage: {
		margin: '10px',
		[theme.breakpoints.down('sm')]: {
			margin: '0px 30px',
		},
	},
	errorMessage: {
		color: 'red',
	},
});

/**
 * Landing page for Account
 */
class AccountPage extends Component {
	constructor(props) {
		super(props);
		const { setPageName } = this.props.PageContext;
		setPageName('Profile - Account');
		this.state = {
			handleSubmit: null,
			childTab: null,
			flags: {
				missingData: false,
				isCharacterLimit: false,
				dataSaved: false,
				isDirty: false,
				showSaveChanges: false,
				isPendingSave: false,
				targetTab: 0,
				errorMessage: '',
				apiError: null,
			},
		};
	}

	async componentDidMount() {}

	setAccountPageError = (e) => {
		const state = { ...this.state };
		if (e.hasOwnProperty('title') && e.hasOwnProperty('message')) {
			state.flags.apiError = e;
		} else {
			state.flags.errorMessage = 'Error loading page';
		}
		state.flags.isLoading = false;
		this.setState({ ...state });
	};

	closeError = () => {
		const state = { ...this.state };
		state.flags.apiError = null;
		this.setState({ ...state });
	};

	updateFlags = (flags) => {
		this.setState({ flags: flags });
	};

	setSaveHandler = (child, handler) => {
		const state = { ...this.state };
		state.childTab = child;
		state.handleSubmit = handler;
		// Reset save flags
		state.flags.missingData = false;
		state.flags.isCharacterLimit = false;
		state.flags.dataSaved = false;
		state.flags.isDirty = false;
		state.flags.showSaveChanges = false;
		state.flags.isPendingSave = false;
		this.setState({ ...state });
	};

	renderSaveMessage = () => {
		const state = { ...this.state };
		const { t, classes } = this.props;
		if (state.flags.missingData) {
			return (
				<span className={classes.errorMessage}>{t('profile:PROFILE.MISSING_INPUT')}</span>
			);
		}
		if (state.flags.isCharacterLimit) {
			return (
				<span className={classes.errorMessage}>{t('common:MESSAGES.CHARACTER_LIMIT')}</span>
			);
		}
		if (state.flags.dataSaved) {
			return <span>{t('common:MESSAGES.DATA_SAVED')}</span>;
		}
		return null;
	};
	onCallSubmit = () => {
		if (this.state.handleSubmit !== null && this.state.childTab !== null) {
			this.state.handleSubmit.call(this.state.childTab);
		}
	};

	render() {
		const { classes, t } = this.props;
		const { externalId } = this.props.UserContext;
		const state = { ...this.state };
		const { flags } = this.state;
		return (
			<div className={classes.root}>
				<ErrorDialog
					open={!!flags.apiError}
					onClose={this.closeError}
					title={!flags.apiError ? null : flags.apiError.title}
					description={!flags.apiError ? null : flags.apiError.message}
					errorId={!flags.apiError ? null : flags.apiError.errorId}
				/>
				<Container maxWidth='xl'>
					<Grid item xs={12}>
						<Grid container justify='space-evenly'>
							<Grid item xs={12} md={7}>
								{/* <Paper classes={{ root: classes.dropdownRoot }}> */}
								<AccountV2
									externalId={externalId}
									flags={state.flags}
									updateFlags={this.updateFlags}
									setSaveHandler={this.setSaveHandler}
									setAccountPageError={this.setAccountPageError}
								/>
								{/* </Paper> */}
								<Paper
									classes={{ root: classes.dropdownRoot }}
									elevation={0}
									square
								>
									<Button
										className={classes.saveButton}
										classes={{ disabled: classes.disabeldInput }}
										variant='contained'
										color='primary'
										disabled={
											state.flags.isPendingSave ||
											state.flags.missingData ||
											state.flags.isCharacterLimit
										}
										onClick={this.onCallSubmit}
									>
										{t('common:BUTTONS.SAVE')}
									</Button>
									<div className={classes.saveMessage}>
										{' '}
										{this.renderSaveMessage()}
									</div>
								</Paper>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</div>
		);
	}
}
AccountPage.contextType = UserContext;

AccountPage.propTypes = {
	classes: PropTypes.shape({
		root: PropTypes.string.isRequired,
	}).isRequired,
};
export default WithContexts(withTranslation()(withStyles(styles)(AccountPage)));
