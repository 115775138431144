import React, { Component, Fragment } from 'react';
import { withStyles, Grid, Paper, Button, CircularProgress, Chip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Language, Room, Error } from '@material-ui/icons';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../utils/Application_Constants';
import { getSamlResponse } from '../../../services/peoplenetSsoService';
import privMap from '../../../utils/Privileging_Mapper';
import AssignmentShiftList from '../AssignmentShiftList/AssignmentShiftList';
import generateIcsEvent from '../../../services/IcsService';
import { getAssignmentShifts, getAssignmentTeam } from '../../../services/AssignmentsService';
import YourTeamPreview from '../YourTeam/YourTeamPreview.js';

const styles = (theme) => ({
	headerDash: {
		color: COLORS.LT_JOURNEY,
		paddingTop: '25px',
		margin: 0,
	},
	headerInt: {
		paddingTop: '25px',
		margin: 0,
	},
	headerGridRoot: {
		paddingLeft: '25px',
		textAlign: 'left',
		[theme.breakpoints.up('md')]: {
			borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		},
	},
	nextShiftRoot: {
		display: 'flex',
		flexWrap: 'wrap',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '15px',
		},
	},
	chipRoot: {
		border: `1px solid ${COLORS.ASTRAL}`,
		color: COLORS.ASTRAL,
		backgroundColor: COLORS.MYSTIC,
		marginLeft: '10px',
		borderRadius: '5px',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '5px',
		},
	},
	chipLabel: {
		fontWeight: '300',
		fontSize: '14px',
	},
	assignmentDateRange: {
		color: COLORS.RAVEN,
		fontWeight: '500',
		fontSize: '16px',
		marginTop: '5px',
		marginBottom: '5px',
		lineHeight: 'revert',
	},
	assignmentBookingID: {
		color: COLORS.RAVEN,
		fontWeight: '500',
		fontSize: '14px',
		marginTop: '5px',
		fontStyle: 'italic',
		lineHeight: 'revert',
	},
	chipInProcess: {
		border: `1px solid ${COLORS.RICH_ELECTRIC_BLUE}`,
		backgroundColor: COLORS.GLITTER,
		color: COLORS.RICH_ELECTRIC_BLUE,
	},
	chipComplete: {
		border: `1px solid ${COLORS.KELLY_GREEN}`,
		backgroundColor: COLORS.AQUA_SPRING,
		color: COLORS.KELLY_GREEN,
	},
	buttonGridRoot: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		borderLeft: `1px solid ${COLORS.WHITE_LILAC}`,
		[theme.breakpoints.up('md')]: {
			borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			borderTop: `1px solid ${COLORS.WHITE_LILAC}`,
			paddingBottom: '10px',
		},
	},
	privilegingStatusRoot: {
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		display: 'flex',
		paddingTop: '25px',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			justifyContent: 'center',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0 0 20px 15px',
		},
	},
	spanTitleFont: {
		fontWeight: '900',
		color: COLORS.RAVEN,
		fontSize: '12px',
		display: 'flex',
	},
	nextAssignmentDate: {
		marginTop: '5px',
		color: COLORS.RAVEN,
		fontWeight: '500',
		fontSize: '18px',
	},
	noShifts: {
		margin: '5px 0 15px',
		color: COLORS.RAVEN,
		fontWeight: '500',
		fontSize: '18px',
		opacity: '0.7',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '0',
		},
	},
	nextShiftWrapper: {
		textAlign: 'left',
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		paddingLeft: '25px',
		paddingTop: '15px',
	},
	yourTeamWrapper: {
		textAlign: 'left',
	},
	yourTeamPreview: {
		paddingBottom: '50px',
	},
	clickableSpan: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		marginBottom: '15px',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
	},
	addressMapContainer: {
		margin: '15px 0',
	},
	iconRoot: {
		width: '16px',
		marginRight: '5px',
		color: COLORS.TRUE_BLUE,
	},
	clickableLink: {
		display: 'flex',
		alignContent: 'center',
	},
	alertWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '14px',
		marginTop: '5px',
		width: '80%',
		paddingBottom: '15px',
		color: COLORS.MILANO_RED,
	},
	websiteNotAvailable: {
		fontWeight: '500',
		color: COLORS.RAVEN,
		fontSize: '16px',
		display: 'flex',
		flexBasis: '100%',
	},
	addressLine: {
		fontWeight: '500',
		color: COLORS.RAVEN,
		fontSize: '16px',
	},
	addressWrapper: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'left',
		margin: '5px 0',
		paddingBottom: '15px',
	},
	errorIcon: {
		width: '14px',
		marginRight: '5px',
	},
	buttonRoot: {
		marginTop: '25px',
		maxWidth: '80%',
		borderRadius: '0px',
		textTransform: 'none',
		boxShadow: 'none',
	},
	iframeWrapper: {
		width: '320px',
		[theme.breakpoints.down('sm')]: {
			width: '279px',
		},
	},
	gridMapWrapperRoot: {
		display: 'flex',
		justifyContent: 'center',
	},
	addressDetailWrapper: {
		paddingLeft: '25px',
	},
	// dashboardCardWidth: {
	//   // width: '90%',
	//   marginBottom: '20px',
	// },
	viewAssignmentWrapper: {
		display: 'flex',
		justifyContent: 'center',
		paddingTop: '15px',
		paddingBottom: '15px',
		borderTop: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	calendarExportWrapper: {
		textTransform: 'inherit',
		marginBottom: '10px',
		padding: '0',
	},
	calendarSpan: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
		marginBottom: '0',
	},
	disabled: {
		color: COLORS.RAVEN,
	},
	disabledClickableSpan: {
		borderBottom: '1px dashed',
		fontWeight: '500',
		fontSize: '16px',
		cursor: 'pointer',
		marginBottom: '0',
	},
	dateError: {
		marginTop: '5px',
		fontWeight: '500',
		fontSize: '18px',
		color: COLORS.MILANO_RED,
	},
});

class AssignmentDetailCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			shifts: [],
			assignmentTeam: [],
			isLoading: true,
			viewAllShiftsToggled: false,
		};
	}

	async componentDidMount() {
		const { assignment, type } = this.props;
		await this.setState({ isLoading: true });
		const assignmentShift = await getAssignmentShifts(assignment.id);
		const assignmentTeam = await getAssignmentTeam(assignment.clinicianId, assignment.id, type);
		this.setState({
			assignmentTeam: assignmentTeam,
			isLoading: false,
			shifts: assignmentShift,
		});
	}

	/**
	 * Function is passed in the get directions span of the card view.
	 * If the device is an iOS device, we use the native maps abilitiy,
	 * anything else default to google maps.
	 */
	mapsSelector = () => {
		if (
			navigator.platform.indexOf('iPhone') !== -1 ||
			navigator.platform.indexOf('iPad') !== -1 ||
			navigator.platform.indexOf('iPod') !== -1
		) {
			window.open(`https://maps.apple.com/?q=${this.addressParser()}`);
			return;
		}
		window.open(`https://google.com/maps/search/?api=1&query=${this.addressParser()}`);
	};

	addressParser = () => {
		const { assignment } = this.props;
		let encodedAddress = '';
		Object.values(assignment.location).forEach((item) => {
			if (item) {
				encodedAddress += encodeURI(item);
			}
		});
		return encodedAddress;
	};

	isDashboard = () => {
		const { dashboard, classes } = this.props;
		if (!dashboard) {
			return '';
		}
		return classes.dashboardCardWidth;
	};

	getPrivilegingStatusStyle = () => {
		const { classes, assignment } = this.props;
		const privStatus = privMap.get(assignment.privilegingStatus);
		switch (privStatus) {
			case 'In Process':
				return classes.chipInProcess;
			case 'Complete':
				return classes.chipComplete;
			default:
				return classes.chipInProcess;
		}
	};

	doesAssignmentsContainShifts = () => {
		const { assignment } = this.props;
		if (!assignment.shifts || assignment.shifts.length === 0) {
			return false;
		}
		return true;
	};

	exportNextShiftsAsIcs = async () => {
		const { assignment } = this.props;
		const state = { ...this.state };
		generateIcsEvent(
			state.shifts,
			assignment.displayName,
			assignment.websiteUrl,
			assignment.location
		);
	};

	toggleAllShiftView = (isViewable) => {
		//const state = { ...this.state };
		//state.viewAllShiftsToggled = isViewable;
		//this.setState({ ...state });
		this.setState({ viewAllShiftsToggled: isViewable });
	};

	shiftsEmpty() {
		const { shifts } = this.state;
		if (!shifts) {
			return true;
		}
		const shiftsAsArray = Object.entries(shifts);
		return shiftsAsArray.length === 0;
	}

	renderAssignmentShiftListView = () => {
		const { viewAllShiftsToggled, shifts } = this.state;
		if (viewAllShiftsToggled) {
			return <AssignmentShiftList shifts={shifts} toggleHandler={this.toggleAllShiftView} />;
		}
		return null;
	};

	renderShowTeam() {
		const { classes, type, t, assignment } = this.props;

		const { assignmentTeam } = this.state;

		if (type === 'past') {
			return null;
		}

		return (
			<Grid item xs={12}>
				<Grid item xs={12} classes={{ root: classes.yourTeamWrapper }}>
					<span className={classes.spanTitleFont}>
						{t('assignments:YOURTEAM.PREVIEW_TITLE')}
					</span>
				</Grid>
				<Grid item xs={12} classes={{ root: classes.yourTeamWrapper }}>
					<YourTeamPreview
						yourteam={assignmentTeam}
						assignmentLink={`/assignments/${type}/${assignment.id}`}
						classes={{ root: classes.yourTeamPreview }}
					/>
				</Grid>
			</Grid>
		);
	}

	renderTimesheetButton() {
		const { classes, type, t } = this.props;
		if (type === 'upcoming') {
			return null;
		}
		if (type === 'past') {
			return (
				<Grid item md={4} classes={{ root: classes.buttonGridRoot }}>
					<Button variant='contained' color='primary' onClick={getSamlResponse}>
						Manage Timesheet
					</Button>
				</Grid>
			);
		}
		return (
			<Grid item md={4} classes={{ root: classes.buttonGridRoot }}>
				<Button
					variant='contained'
					color='primary'
					classes={{ root: classes.buttonRoot }}
					onClick={getSamlResponse}
				>
					Manage Timesheet
				</Button>
				<div className={classes.alertWrapper}>
					<Error classes={{ root: classes.errorIcon }} />
					<span className={classes.alertSpan}>
						{t('error:TIMESHEET_ALERTS.TIMESHEET_DUE_MONDAY')}
					</span>
				</div>
			</Grid>
		);
	}

	/**
	 * When the assignment card is renderd in the dashboard,
	 * there is a bottom to the component that is unique to that
	 * view. This method checks if it is coming from the dashboard.
	 */
	renderViewAssignment() {
		const { dashboard, classes, type, assignment } = this.props;
		if (!dashboard) {
			return null;
		}
		return (
			<Grid item xs={12} classes={{ root: classes.viewAssignmentWrapper }}>
				<Link className='textLink assignmentDetailLink' to={`/assignments/${type}/${assignment.id}`}>
					View Assignment
				</Link>
			</Grid>
		);
	}

	renderPrivilegingStatus() {
		const { classes, type, assignment } = this.props;
		if (type !== 'upcoming') {
			return null;
		}
		const { privilegingStatus } = assignment;
		if (!privilegingStatus || !privMap.get(privilegingStatus)) {
			return null;
		}
		return (
			<Grid item md={4} classes={{ root: classes.privilegingStatusRoot }}>
				<Chip
					classes={{ root: this.getPrivilegingStatusStyle() }}
					label={`Privileging Status: ${privMap.get(privilegingStatus)}`}
				/>
			</Grid>
		);
	}

	renderWebsiteLink = () => {
		const { classes, assignment } = this.props;
		if (!assignment.websiteUrl) {
			return <span className={classes.websiteNotAvailable}>Not Available</span>;
		}

		const lenAllowed = 40;
		let { websiteUrl } = assignment;
		if (assignment.websiteUrl.length > lenAllowed) {
			websiteUrl = `${assignment.websiteUrl.substring(0, lenAllowed)}...`;
		}
		return (
			<>
				<Grid item xs={12} classes={{ root: classes.clickableLink }}>
					<Language classes={{ root: classes.iconRoot }} />
					<a target='_blank' rel='noopener noreferrer' href={assignment.websiteUrl} className="assignmentWebsite">
						<span className={classes.clickableSpan}>{websiteUrl}</span>
					</a>
				</Grid>
			</>
		);
	};

	showShiftTimes = (assignment) => {
		if (
			assignment === undefined ||
			assignment.division === undefined ||
			process.env.REACT_APP_HIDE_SHIFTS_FOR === undefined
		)
			return true;
		return !process.env.REACT_APP_HIDE_SHIFTS_FOR.includes(assignment.division);
	};

	renderNextShift = () => {
		const { classes, assignment, type, t } = this.props;
		if (type === 'past' || !this.showShiftTimes(assignment)) {
			return null;
		}
		return (
			<Grid item xs={12} classes={{ root: classes.nextShiftWrapper }}>
				<Grid item xs={12}>
					<span className={classes.spanTitleFont}>
						{t('common:GENERIC_PHRASES.NEXT_SHIFTS')}
					</span>
				</Grid>
				<Grid item xs={12} classes={{ root: classes.nextShiftRoot }}>
					{this.doesAssignmentsContainShifts() ? (
						assignment.shifts.map((item, index) => {
							if (item.account.timeZoneName) {
								return (
									<Fragment key={`g-${index + 1}`}>
										{index === 0 ? (
											<h3
												key={`asssignmentShift-${index + 1}`}
												className={classes.nextAssignmentDate}
											>
												{`${moment.utc(item.start)
													.tz(item.account.timeZoneName)
													.format('dddd, MMM D ')} at `}
											</h3>
										) : null}
										<Chip
											classes={{
												root: classes.chipRoot,
												label: classes.chipLabel,
											}}
											label={`${moment.utc(item.start)
												.tz(item.account.timeZoneName)
												.format('h:mma z')} - ${moment.utc(item.end)
												.tz(item.account.timeZoneName)
												.format('h:mma z')}`}
										/>
									</Fragment>
								);
							}
							return (
								<h3 className={classes.dateError}>
									{t('assignments:DATE.TIMEZONE_MISSING')}
								</h3>
							);
						})
					) : (
						<h3 className={classes.noShifts}>{t('common:GENERIC_PHRASES.NONE')}</h3>
					)}
				</Grid>
			</Grid>
		);
	};

	renderAssigmentDates = () => {
		const { classes, assignment, t } = this.props;

		return (
			<h3 className={classes.assignmentDateRange}>
				{assignment.startDate && assignment.endDate ? (
					<span>
						{moment.utc(assignment.startDate).format('MMM DD, YYYY')}
						&nbsp;- &nbsp;
						{moment.utc(assignment.endDate).format('MMM DD, YYYY')}
					</span>
				) : (
					t('assignments:DATE.TBD')
				)}
			</h3>
		);
	};

	renderAssigmentBookingID = () => {
		const { classes, assignment, t } = this.props;

		return (
			<h3 className={classes.assignmentBookingID}>
				{assignment.name && assignment.startDate && assignment.endDate ? (
					<span>
						{t('assignments:BOOKING.BOOKING_ID')}: {assignment.name}
					</span>
				) : null}
			</h3>
		);
	};

	renderAllShifts() {
		const { classes, assignment } = this.props;
		if (!this.showShiftTimes(assignment)) {
			return null;
		}
		return (
			<Button
				classes={{
					root: `${classes.calendarExportWrapper}`,
				}}
				onClick={() => {
					this.toggleAllShiftView(true);
				}}
				component='span'
				disabled={this.shiftsEmpty()}
			>
				<span
					className={
						this.shiftsEmpty()
							? `${classes.disabledClickableSpan}`
							: `${classes.calendarSpan}`
					}
				>
					View All Shifts
				</span>
			</Button>
		);
	}

	render() {
		const { dashboard, classes, assignment, type } = this.props;
		const { isLoading } = this.state;

		if (isLoading) {
			return (
				<div>
					<CircularProgress color='primary' />
				</div>
			);
		}

		return (
			<>
				<Paper classes={{ root: this.isDashboard() }}>
					<Grid container>
						<Grid item xs={12} classes={{ root: classes.headerGridRoot }}>
							<Grid item xs={12}>
								{dashboard ? (
									<Link to={`/assignments/${type}/${assignment.id}`} className="assignmentTitle">
										<h2 className={classes.headerDash}>
											{assignment.displayName}
										</h2>
									</Link>
								) : (
									<h2 className={classes.headerInt}>{assignment.displayName}</h2>
								)}
							</Grid>
							<Grid item xs={12}>
								{this.renderAssigmentDates()}
								{this.renderAssigmentBookingID()}
								{this.renderAllShifts()}
							</Grid>
						</Grid>
						{this.renderPrivilegingStatus()}
						{/* <Hidden only={['xs', 'sm']}>{this.renderTimesheetButton()}</Hidden> */}
						{this.renderNextShift()}
						<Grid container classes={{ root: classes.addressMapContainer }}>
							<Grid
								item
								xs={12}
								md={6}
								classes={{ root: classes.gridMapWrapperRoot }}
							>
								<div className={classes.iframeWrapper + ' assignmentMap'}>
									<iframe
										title='googlemap'
										width='100%'
										height='144'
										frameBorder='0'
										src={`https://www.google.com/maps/embed/v1/place?key=${
											process.env.REACT_APP_GOOGLEMAPS_API_KEY
										}&q=${this.addressParser()}`}
									/>
								</div>
							</Grid>
							<Grid
								item
								xs={12}
								md={4}
								classes={{ root: classes.addressDetailWrapper }}
							>
								<Grid item xs={12}>
									<span className={classes.spanTitleFont}>Website</span>
								</Grid>
								{this.renderWebsiteLink()}

								<Grid item xs={12}>
									<span className={classes.spanTitleFont}>Address</span>
								</Grid>
								<Grid item xs={12} classes={{ root: classes.addressWrapper }}>
									<span className={classes.addressLine}>
										{' '}
										{assignment.location.addressLine1}{' '}
									</span>
									<span className={classes.addressLine}>
										{' '}
										{assignment.location.addressLine2}{' '}
									</span>
									<span className={classes.addressLine}>
										{' '}
										{assignment.location.city},{assignment.location.state}{' '}
										{assignment.location.zipCode}{' '}
									</span>
								</Grid>
								<Grid item xs={12} classes={{ root: classes.clickableLink }}>
									<Room classes={{ root: classes.iconRoot }} />
									<span
										className={classes.clickableSpan + ' assignmentDirectionsLink'}
										onClick={this.mapsSelector}
									>
										Get directions
									</span>
								</Grid>
								{this.renderShowTeam()}
							</Grid>
						</Grid>
						{/* <Hidden only={['md', 'lg', 'xl']}>
              {this.renderTimesheetButton()}
            </Hidden> */}
						{this.renderViewAssignment()}
					</Grid>
				</Paper>
				{this.renderAssignmentShiftListView()}
			</>
		);
	}
}

export default withTranslation()(withStyles(styles)(AssignmentDetailCard));
