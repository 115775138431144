/**
 *@file Services file that fetches from the API for the Profile Pages
 */
import {
	axiosSalesforceInstance,
	axiosWCApiInstance,
} from '../../configuration/axios/axiosConfiguration';
import axios from 'axios';
import { ApiError } from '../../utils/ApiError';
import { ltdomain } from '../../utils/helpers';
import msSalService from '../msSalService';
import { appInsights } from '../../AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { getConfigurationSetting } from '../AppConfigService';
/**
 * Method returns the clinician account
 * @param {Number} externalId
 */
async function getClinicianAccount(externalId) {
	let response;
	try {
		response = await axiosSalesforceInstance.get('GetClinicianAccount', {
			params: {
				clinicianExternalId: externalId,
			},
		});
	} catch (error) {
		console.log('getClinicianAccount error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'getClinicianAccount', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getClinicianAccount'
			);
		}
	}
	return response.data;
}

async function getClinicianAccountV2(externalId) {
	let response;
	try {
		response = await axiosWCApiInstance(`clinicians/${externalId}`);
	} catch (error) {
		console.log('getClinicianAccount error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'getClinicianAccount', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getClinicianAccount'
			);
		}
	}
	return response.data;
}

async function getPortalUserLevel(externalId) {
	let response;
	try {
		var useRewiredUserLevel = await getConfigurationSetting('UseNewAPIs',true);

		if (useRewiredUserLevel) {
			response = await axiosWCApiInstance(`clinicians/${externalId}/UserLevel`).then(
				(response) => {
					if (response.data.userLevel !== '1' && response.data.userLevel !== '2') {
						let url = ltdomain();
						msSalService.clearAuthInfo();
						window.open(url, '_Self');
					}

					// response.data = 2;
					response.data = parseInt(response.data.userLevel);

					return response;
				}
			);
		} else {
			response = await axiosSalesforceInstance
				.get('GetPortalUserLevel', {
					params: {
						clinicianExternalId: externalId,
					},
				})
				.then((response) => {
					if (response.data === 0) {
						let url = ltdomain();
						msSalService.clearAuthInfo();
						window.open(url, '_Self');
					}

					// response.data = 2;

					return response;
				});
		}
	} catch (error) {
		console.log('GetPortalUserLevel error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'getPortalUserLevel', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'GetPortalUserLevel'
			);
		}
	}
	return response.data;
}

/**
 * Method puts the post clinicain account api.
 * @param {*} account
 */
async function postClinicianAccount(account) {
	try {
		await axiosSalesforceInstance.post('PostClinicianAccount', account);
	} catch (error) {
		console.log('PostClinicianAccount error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'postClinicianAccount', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'PostClinicianAccount'
			);
		}
	}
}

async function putClinicianAccount(externalId, account) {
	try {
		await axiosWCApiInstance.put(`clinicians/${externalId}`, account);
	} catch (error) {
		console.log('PostClinicianAccount error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'postClinicianAccount', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'PostClinicianAccount'
			);
		}
	}
}

async function postClinicianTravelPreferences(externalId, preferences) {
	try {
		await axiosWCApiInstance.put(`clinicians/${externalId}/TravelPreferences`, preferences);
	} catch (error) {
		console.log('PostClinicianTravelPreference error=' + error);
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'postClinicianTravelPreferences',
				severityLevel: SeverityLevel.Error,
			},
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'PostClinicianTravelPreference'
			);
		}
	}
}

async function getClinicianTravelPreferences(externalId) {
	let response;
	try {
		response = await axiosWCApiInstance.get(`clinicians/${externalId}/TravelPreferences`);
	} catch (error) {
		console.log('GetClinicianTravelPreference error=' + error);
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'getClinicianTravelPreferences',
				severityLevel: SeverityLevel.Error,
			},
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'GetClinicianTravelPreference'
			);
		}
	}
	return response.data;
}
// New API added for preferences TDS-2329 and TDS-2690

async function getClinicianPaymentElectionPreferences(externalId) {
	let response;
	try {
		response = await axiosWCApiInstance.get(`clinicians/${externalId}/W9DirectDeposit`);
	} catch (error) {
		console.log('getClinicianPaymentElectionPreferences error=' + error);
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'getClinicianPaymentElectionPreferences',
				severityLevel: SeverityLevel.Error,
			},
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getClinicianPaymentElectionPreferences'
			);
		}
	}
	return response.data;
}

async function postClinicianAssignPreference(externalId, preferences) {
	try {
		await axiosWCApiInstance.post(`clinicians/${externalId}/AssignPreferences`, preferences);
	} catch (error) {
		console.log('PostClinicianAssignPreference error=' + error);
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'postClinicianAssignPreference',
				severityLevel: SeverityLevel.Error,
			},
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'PostClinicianAssignPreference'
			);
		}
	}
}

//GetClinicianCommPreference
async function getClinicianCommPreference(externalId) {
	let response;
	try {
		response = await axiosWCApiInstance.get(`clinicians/${externalId}/CommPreferences`);
	} catch (error) {
		console.log('GetClinicianCommPreferenceV2 error=' + error);
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'getClinicianCommPreference',
				severityLevel: SeverityLevel.Error,
			},
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'GetClinicianCommPreferenceV2'
			);
		}
	}
	return response.data;
}

//PostClinicianCommPreference
async function postClinicianCommPreference(externalId, preferences) {
	try {
		await axiosWCApiInstance.put(`clinicians/${externalId}/CommPreferences`, preferences);
	} catch (error) {
		console.log('PostClinicianCommPreferenceV2 error=' + error);
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'postClinicianCommPreference',
				severityLevel: SeverityLevel.Error,
			},
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'PostClinicianCommPreferenceV2'
			);
		}
	}
}

/**
 * Method returns the clinician account
 * @param {Number} externalId
 */
async function getClinicianQualification(externalId) {
	let response;
	try {
		response = await axiosSalesforceInstance.get('GetClinicianQualification', {
			params: {
				clinicianExternalId: externalId,
			},
		});
	} catch (error) {
		console.log('GetClinicianQualification error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'getClinicianQualification', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'GetClinicianQualification'
			);
		}
	}
	return response.data;
}

async function postClinicianQualification(qualifications) {
	try {
		await axiosSalesforceInstance.post('PostClinicianQualification', qualifications);
	} catch (error) {
		console.log('PostClinicianQualification error=' + error);
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'postClinicianQualification',
				severityLevel: SeverityLevel.Error,
			},
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'PostClinicianQualification'
			);
		}
	}
}

async function postVerifyEmailSF(data, aBearer) {
	let response;
	try {
		let useNewAPIs = await getConfigurationSetting('UseNewAPIs', true);
		let aBaseURL = useNewAPIs ? process.env.REACT_APP_WC_API_BASE_URI : process.env.REACT_APP_SALESFORCE_BASE_API_URI;

		var afullBearer = 'Bearer ' + aBearer;
		const axiosVerifyEmail = axios.create({
			headers: { Authorization: afullBearer },
			baseURL: aBaseURL,
		});

		const aApiURL = useNewAPIs ? 'clinicians/UpdateVerifiedDate' : 'UpdateVerifiedDate';

		response = await axiosVerifyEmail.post(aApiURL, data);
		console.log('postVerifyEmailSF response =' + JSON.stringify(response));
	} catch (error) {
		console.log('UpdateVerifiedDate error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'postVerifyEmailSF', severityLevel: SeverityLevel.Error },
		});
		if (error.response) {
			if (error.response.status === 403) {
				return false;
			}
		}
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'UpdateVerifiedDate'
			);
		}
	}
	return response.data;
}

async function postVerifyEmail(data, aBearer) {
	let response;
	try {
		let useNewAPIs = await getConfigurationSetting('UseNewAPIs', true);
		let aBaseURL = useNewAPIs ? process.env.REACT_APP_WC_API_BASE_URI : process.env.REACT_APP_SALESFORCE_BASE_API_URI;

		var afullBearer = 'Bearer ' + aBearer;
		const axiosVerifyEmail = axios.create({
			headers: { Authorization: afullBearer },
			baseURL: aBaseURL,
		});

		const aApiURL = useNewAPIs ? 'clinicians/VerifyEmail' : 'VerifyEmail';

		response = await axiosVerifyEmail.post(aApiURL, data);
		console.log('postVerifyEmail response =' + JSON.stringify(response));
	} catch (error) {
		console.log('postVerifyEmail error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'postVerifyEmail', severityLevel: SeverityLevel.Error },
		});
		if (error.response) {
			if (error.response.status === 403) {
				return false;
			}
		}
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'postVerifyEmail'
			);
		}
	}
	return response.data;
}

async function postSendEmail(aEmail) {
	let response;
	try {
		//await axiosSalesforceInstance.post('SendEmailVerification', aEmail);
		response = await axiosSalesforceInstance.post('SendEmailVerification', null, {
			params: {
				email: aEmail,
			},
		});
	} catch (error) {
		console.log('postSendEmail error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'postSendEmail', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'postSendEmail'
			);
		}
	}

	return response.data;
}

/**
 * Method returns the clinician account
 * @param {Number} externalId
 */
async function getClinicianSpecialtyList(externalId) {
	let response;
	try {
		response = await axiosSalesforceInstance.get('GetClinicianSpecialtyList', {
			params: {
				clinicianExternalId: externalId,
			},
		});
	} catch (error) {
		console.log('GetClinicianSpecialtyList error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'getClinicianSpecialtyList', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'GetClinicianSpecialtyList'
			);
		}
	}
	return response.data;
}

async function getRecommendedJobList(externalId, useAltSearchMethod) {
	/************ TEST CODE  ******
    if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === "DEV") {
        return [{ "Url": "https://www.google.com", "Headline": "Awesome Atlanta Job", "UpdateDate": "May  2 2022  6:26PM", "CreateDate": "Aug 26 2020 12:48PM", "Specialties": "Psychiatry", "Disciplines": "MD", "City": "Alpharetta", "State": "GA", "Description": "Awesome job in an awesome city with awesome people.", "JobPostName": "ORD-128416-MD-GA", "Score": 12 }, { "Url": "https://images.google.com", "Headline": "Doctor Desperately Desired", "UpdateDate": "May  2 2022  7:51PM", "CreateDate": "May  2 2022  7:37PM", "Specialties": "Psychiatry", "Disciplines": "MD", "City": "Alpharetta", "State": "GA", "Description": "We are desperate and will hire pretty much anyone.", "JobPostName": "ORD-129356-MD-GA", "Score": 12 }];
    }
    ************ TEST CODE  ****/
	let response;
	try {
		response = await axiosWCApiInstance.get(
			`clinicians/${externalId}/RecommendedJobs?useAltSearch=${useAltSearchMethod}`
		);

		console.log('getRecommendedJobList  ->  ' + JSON.stringify(response));
	} catch (error) {
		console.log('GetRecommendedJobList error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'getRecommendedJobList', severityLevel: SeverityLevel.Error },
		});
		return {};
		/* THE RETURN STATEMENT ABOVE IS TEMPORARY. Remove it once the Job board fixes the code to not error out if it doens't know about a provider.
        if (!!error.response && !!error.response.data) {
            console.log('throwing ApiError:' + JSON.stringify(error.response.data));
            throw new ApiError(error.response.data);
        } else if (!!error.message) {
            console.log('throwing ApiError:' + JSON.stringify(error.message));
            throw new ApiError(error.message);
        } else {
            throw ApiError.UnwrappedError('Server Error', 'There was a problem processing that request.', 'GetRecommendedJobList');
        }*/
	}
	// Filter out useless results (no Url to go to)
	return response.data.filter((j) => !!j.url);
}

// API call to update IsFirstLogin filed in B2C user API
async function updateUserFirstLoginIn(aEmail) {
	let response;
	try {
		//await axiosSalesforceInstance.post('SendEmailVerification', aEmail);
		response = await axiosSalesforceInstance.post('SetUserIsFirstLogin', null, {
			params: {
				email: aEmail,
			},
		});
	} catch (error) {
		console.log('SetUserIsFirstLogin error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'updateUserFirstLoginIn', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'SetUserIsFirstLogin'
			);
		}
	}

	return response.data;
}

//WCAPI Additions
async function patchClinicianAssignPreference(externalId, preferences) {
	try {
		await axiosWCApiInstance.patch(`clinicians/${externalId}/AssignPreferences`, preferences);
	} catch (error) {
		console.log('patchClinicianAssignPreference error=' + error);
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'patchClinicianAssignPreference'
			);
		}
	}
}

async function getClinicianAssignPreference(externalId) {
	let response;
	try {
		response = await axiosWCApiInstance.get(`clinicians/${externalId}/AssignPreferences`);
	} catch (error) {
		console.log('getClinicianAssignPreference error=' + error);
		if (!!error.response && error.response.status === 404) {
			console.log('getClinicianAssignPreference status 404 =: ' + error.response.status);
			//Return Empty List on 404
			response = {
				data: [],
			};
		} else if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getClinicianAssignPreference'
			);
		}
	}
	if (!response) return null;
	return response.data;
}

async function getSkillsForSpecialties() {
	let response;
	try {
		response = await axiosWCApiInstance.get(`clinicians/SpecialtySkillMap`);
	} catch (error) {
		console.log('getSkillsForSpecialties error=' + error);
		if (!!error.response && error.response.status === 404) {
			console.log('getSkillsForSpecialties status 404 =: ' + error.response.status);
			//Return Empty List on 404
			response = {
				data: [],
			};
		} else if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getSkillsForSpecialties'
			);
		}
	}
	if (!response) return null;
	return response.data;
}

//WCAPI Additions
export {
	getClinicianAccount,
	getClinicianAccountV2,
	postClinicianAccount,
	putClinicianAccount,
	getClinicianQualification,
	postClinicianQualification,
	getClinicianSpecialtyList,
	postVerifyEmailSF,
	postVerifyEmail,
	postSendEmail,
	updateUserFirstLoginIn,
	postClinicianAssignPreference,
	getClinicianCommPreference,
	postClinicianCommPreference,
	getClinicianTravelPreferences,
	postClinicianTravelPreferences,
	getRecommendedJobList,
	getPortalUserLevel,
	patchClinicianAssignPreference,
	getClinicianAssignPreference,
	getSkillsForSpecialties,
	getClinicianPaymentElectionPreferences,
};
