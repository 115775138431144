import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import AssignmentDetailCard from '../AssignmentDetailCard/AssignmentDetailCard';
import AssignmentDocumentComponentV2 from '../AssignmentDocumentComponent/AssignmentDocumentComponentV2';
import TravelV2 from '../Travel/TravelV2';

const styles = (theme) => ({});

class PastAssignmentDetailView extends Component {
	render() {
		const { assignment, externalId } = this.props;
		return !assignment ? (
			<p>Error, Assignment not found</p>
		) : (
			<>
				<AssignmentDetailCard assignment={assignment} type='past' />
				<AssignmentDocumentComponentV2 assignmentName={assignment.name} />
				<TravelV2 assignment={assignment} externalId={externalId} filter='past' />
			</>
		);
	}
}

export default withStyles(styles)(PastAssignmentDetailView);
