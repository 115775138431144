import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	withStyles,
	Grid,
	FormControl,
	InputLabel,
	Chip,
	TextField,
	CircularProgress,
	InputAdornment,
	ThemeProvider,
	Button,
} from '@material-ui/core';
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Event } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withTranslation } from 'react-i18next';
import UtcUtils from '../../GlobalComponents/UtcUtils/UtcUtils';
import {
	availabilityOptions,
	assignmentTypeOptions,
	shiftTypeOptions,
	workInterestsOptions,
	locationTypeOptions,
	QUESITON_LIST_PREFERENCE_CENTER,
} from './preferencesOptions';
import { states } from '../Account/accountOptions';
import { COLORS } from '../../../utils/Application_Constants';
import DatePickerComponent from '../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import datePickerTheme from '../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import {
	getClinicianAssignPreference,
	getSkillsForSpecialties,
	patchClinicianAssignPreference,
} from '../../../services/ProfileServices/profileService';
import ErrorDialog from '../../GlobalComponents/Dialog/ErrorDialog';
import { NavLink } from 'react-router-dom';

const styles = (theme) => ({
	typeAheadInput: {
		'label + &': {
			marginTop: theme.spacing(2),
		},
		borderRadius: 0,
		padding: '12px',
		position: 'relative',
		backgroundColor: COLORS.WHITE,
		color: COLORS.LT_MIDNIGHT,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		fontSize: '16px',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			padding: '5px',
		},
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			// boxShadow: COLORS.WHITE_LILAC,
			border: `1px solid ${COLORS.LT_JOURNEY}!important`,
		},
		'&:hover': {
			border: `1px solid ${COLORS.LT_JOURNEY}!important`,
		},
	},
	chipRoot: {
		backgroundColor: COLORS.ZIRCON,
		borderRadius: '3px',
	},
	chipLabel: {
		color: COLORS.RAVEN,
	},
	cssOutlinedInput: {
		height: 45,
		fontSize: '16px',
		color: COLORS.RAVEN,
		padding: '0px 0px',
		'&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //default
		},
		'&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //hovered
		},
		'&$cssFocused $notchedOutline': {
			borderColor: '#6D707E', //focused
			borderWidth: '1px',
			borderRadius: 1,
		},
	},
	disabledInput: {
		opacity: '0.6',
	},
	completionTextStyle: {
		color: '#596674',
		textAlign: 'center',
		fontWeight: '600',
		display: 'flex',
	},
	tileHeaderPreferecne: {
		color: '#596674',
		fontWeight: '700',
		letterSpacing: '.5px',
		fontSize: '1rem',
		display: 'block',
		marginBottom: '.5rem',
		textAlign: 'center',
	},
	sectionStyle: {
		color: COLORS.LT_JOURNEY,
		display: 'flex',
		textDecorationLine: 'underline',
	},
	linkHeader: {
		// fontWeight: '700',
		// justifyContent: 'center',
		// display: 'flex',
		// fontWeight: '700',
		// fontSize: '16px',
		// margin: 0,
		// color:COLORS.LT_JOURNEY
	},
	preferecneCompletionText: {
		fontWeight: '600',
		justifyContent: 'center',
		display: 'flex',
		fontSize: '18px',
		margin: 0,
	},
	helpPanelButton: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		borderLeft: `1px solid ${COLORS.WHITE_LILAC}`,
		width: '85%',
		borderTop: `1px solid ${COLORS.WHITE_LILAC}`,
		paddingBottom: '5px',
		textTransform: 'inherit',
		marginTop: '2rem',
		marginLeft: '1rem',
		[theme.breakpoints.down('sm')]: {
			width: 'fit-content',
			marginTop: '20%',
		},
	},
	circularProgressHeader: {
		marginTop: '-30px',
		paddingTop: '10px',
		//background: '#f7f7f7',
		textAlign: 'left',
	},
});

class PreferenceCompletion extends Component {
	constructor(props) {
		super(props);
		this.availabilityOptions = availabilityOptions;
		this.assignmentTypeOptions = assignmentTypeOptions;
		this.shiftTypeOptions = shiftTypeOptions;
		this.workInterestsOptions = workInterestsOptions;
		this.locationTypeOptions = locationTypeOptions;
		this.states = states;
		this.state = {
			clinicianPreferences: {},
			flags: {
				missingData: false,
				dataSaved: false,
				isDirty: false,
				showSaveChanges: false,
				isPendingSave: false,
				targetTab: 0,
				isLoading: false,
				validationError: false,
				navDisabled: false,
				apiError: '',
				errorMessage: '',
			},
			preferenceCompleted: false,
			lookForUpdate: true,
			currentField: null,
		};
	}

	setPreferencePageError = (e) => {
		const { flags } = { ...this.state };
		if (e.hasOwnProperty('title') && e.hasOwnProperty('message')) {
			flags.apiError = e;
		} else {
			flags.errorMessage = 'Error loading page';
		}
		flags.isLoading = false;
		flags.isPendingSave = false;
		this.setState({ flags: flags });
	};

	closeError = () => {
		const state = { ...this.state };
		state.flags.apiError = null;
		this.setState({ flags: state.flags });
	};

	async componentDidMount() {
		const { externalId } = this.props;
		const state = { ...this.state };

		await Promise.all([getClinicianAssignPreference(externalId), getSkillsForSpecialties()])
			.then(async ([clinicianPreferences, skillMappingList]) => {
				state.clinicianPreferences = this.sortClinicianPreference(clinicianPreferences);
			})
			.catch((e) => {
				this.setPreferencePageError(e);
			});

		this.setState({
			clinicianPreferences: state.clinicianPreferences,
		});
	}

	sortClinicianPreference = (inputPreference) => {
		const { externalId } = this.props;
		var sortedList = {};
		sortedList['clinicianExternalId'] = externalId;
		sortedList['workInterests'] = inputPreference['workInterests'];
		QUESITON_LIST_PREFERENCE_CENTER.forEach((item) => {
			Object.keys(inputPreference).forEach((key) => {
				if (item === key) {
					sortedList[key] = inputPreference[key];
				}
			});
		});
		return sortedList;
	};
	async doSubmit() {
		// Validate required fields
		const state = { ...this.state };
		let nothingSelected = false;
		const flags = state.flags;
		const currentField = state.currentField;
		if (
			state.clinicianPreferences[currentField] === null ||
			state.clinicianPreferences[currentField] === undefined ||
			(Array.isArray(state.clinicianPreferences[currentField]) &&
				state.clinicianPreferences[currentField].length === 0)
		) {
			nothingSelected = true;
		}
		const missingAnyFields = false;
		flags.missingData = missingAnyFields;
		const { externalId } = this.props;
		if (!missingAnyFields && !nothingSelected) {
			flags.isPendingSave = true;
			this.updateFlags(flags);
			await patchClinicianAssignPreference(externalId, state.clinicianPreferences).catch(
				(e) => {
					this.setPreferencePageError(e);
				}
			);
			flags.dataSaved = true;
			flags.isDirty = false;
			flags.isPendingSave = false;
			this.updateFlags(flags);
			this.setState({ currentField: null });
		}
	}

	updateFlags = (flags) => {
		this.setState({ flags: flags });
	};

	markFlagsDirty = () => {
		const { flags } = this.state;
		flags.isDirty = true;
		flags.dataSaved = false;
		this.updateFlags(flags);
	};

	labelValueConstructor = (value, label) => ({
		label,
		value,
	});

	filterLocationOptionsFromValue = (selectedValue) => {
		const selectedState = this.states.filter((item) => item.value === selectedValue);
		if (!selectedState[0]) return selectedValue;
		return selectedState[0].label;
	};

	filterAvailabilityOptionsFromValue = (selectedValue) => {
		const selectedAvailabilityOption = this.availabilityOptions.filter(
			(item) => item.value === selectedValue
		);
		if (!selectedAvailabilityOption[0]) return selectedValue;
		return selectedAvailabilityOption[0].label;
	};

	filterLocationTypeOptionsFromValue = (selectedValue) => {
		const selectedLocationTypeOption = this.locationTypeOptions.filter(
			(item) => item.value === selectedValue
		);
		if (!selectedLocationTypeOption[0]) return selectedValue;
		return selectedLocationTypeOption[0].label;
	};

	filterAssignmentTypeOptionsFromValue = (selectedValue) => {
		const selectedAssignmentType = this.assignmentTypeOptions.filter(
			(item) => item.value === selectedValue
		);
		if (!selectedAssignmentType[0]) return selectedValue;
		return selectedAssignmentType[0].label;
	};

	filterShiftTypeOptionsFromValue = (selectedValue) => {
		const selectedShiftType = this.shiftTypeOptions.filter(
			(item) => item.value === selectedValue
		);
		if (!selectedShiftType[0]) return selectedValue;
		return selectedShiftType[0].label;
	};

	dateChangeHandler = (date, parsedDate, name) => {
		const state = { ...this.state };
		if (date) {
			//When the Date is valid strip out the time, otherwise keep the invalid value
			if (date.toJSON()) {
				state.clinicianPreferences[name] = date.toJSON().split('T')[0];
			} else {
				state.clinicianPreferences[name] = moment.utc(
					date.startOf('day').format('YYYY-MM-DD HH:mm:ss')
				);
			}
		} else {
			state.clinicianPreferences[name] = null;
		}
		state.currentField = name;
		this.markFlagsDirty();
		this.setState({
			clinicianPreferences: state.clinicianPreferences,
			currentField: state.currentField,
		});
	};

	autoCompleteHandler = (newValue, name) => {
		const newValues = [];
		newValue.forEach((item) => {
			if (typeof item === 'string') {
				newValues.push(item);
				return;
			}
			newValues.push(item.value);
		});
		const state = { ...this.state };

		state.currentField = name;
		state.clinicianPreferences[name] = newValues;
		if (name === 'locationType') {
			if (newValues.includes('Telehealth')) {
				state.clinicianPreferences['workInterests'].telehealth = true;
			} else {
				state.clinicianPreferences['workInterests'].telehealth = false;
			}
		}
		this.markFlagsDirty();
		this.setState({
			clinicianPreferences: state.clinicianPreferences,
			currentField: state.currentField,
		});
	};

	autoCompleteDelete = async (index, name) => {
		const state = { ...this.state };
		state.clinicianPreferences[name].splice(index, 1);
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	datePickerHelper(date) {
		if (date) {
			return date;
		}
		return null;
	}

	toBeDisplayed = () => {
		const { clinicianPreferences, flags, currentField } = this.state;
		let fieldToShow = currentField;
		if (flags.isDirty === false) {
			fieldToShow = null;
		}
		Object.keys(clinicianPreferences).find((key) => {
			if (QUESITON_LIST_PREFERENCE_CENTER.indexOf(key) >= 0) {
				if (
					(clinicianPreferences[key] === null ||
						clinicianPreferences[key] === undefined ||
						(Array.isArray(clinicianPreferences[key]) &&
							clinicianPreferences[key].length === 0)) &&
					flags.isDirty === false
				) {
					console.log('Find toBeDisplayed  ' + key);
					fieldToShow = key;
					return fieldToShow;
				}
			}
		});
		return fieldToShow;
	};

	render() {
		let fieldToShow = this.toBeDisplayed();
		let displayCompletion = false;
		if (fieldToShow === null) {
			displayCompletion = true;
		} else {
			displayCompletion = false;
		}

		const { classes, t } = this.props;
		const { clinicianPreferences, flags } = this.state;
		if (Object.keys(clinicianPreferences).length === 0) {
			return (
				<div className={classes.progress}>
					<CircularProgress />
				</div>
			);
		}

		if (flags.isPendingSave === true) {
			return (
				<div className={classes.progress}>
					<CircularProgress />
				</div>
			);
		}

		if (displayCompletion === true) {
			return (
				<>
					<Grid container spacing={4}>
						{/* <Grid item xs={12} classes={{ root: classes.circularProgressHeader }}>
                            <div key={`desktopPercent-${this.state.totalCompletionKey}`}>
                                <CustomCircularProgress size={80} thickness={3} progressType="mobile" progress={100} />
                            </div>
                        </Grid> */}
						<Grid item xs={12}>
							<h3>Manage your preferences</h3>
							<p>{t('profile:PREFERENCES.COMPLETION_TEXTV2')}</p>

							<NavLink className='textLink' to={{ pathname: '/profile/preference' }}>
								{t('profile:PREFERENCES.SEE_PREFERENCES')}
							</NavLink>
						</Grid>
					</Grid>
				</>
			);
		}
		const {
			availability,
			nextAvailableDate,
			assignmentType,
			shiftType,
			locationPreference,
			locationType,
		} = clinicianPreferences;

		return (
			<>
				<ErrorDialog
					open={!!flags.apiError}
					onClose={this.closeError}
					title={!flags.apiError ? null : flags.apiError.title}
					description={!flags.apiError ? null : flags.apiError.message}
					errorId={!flags.apiError ? null : flags.apiError.errorId}
				/>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<h3>Preferences</h3>
					</Grid>
				</Grid>

				{this.toBeDisplayed() === 'assignmentType' ? (
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<FormControl>
								<InputLabel htmlFor='AssignmentType'>
									{t('profile:PREFERENCES.ASSIGNMENT_TYPE_PREF')}
								</InputLabel>
								<Autocomplete
									multiple
									options={this.assignmentTypeOptions.map((item) => {
										return item.label;
									})}
									getOptionLabel={(option) =>
										this.filterAssignmentTypeOptionsFromValue(option)
									}
									id='AssignmentType'
									filterSelectedOptions
									disableCloseOnSelect
									onChange={(event, newValue) => {
										this.autoCompleteHandler(newValue, 'assignmentType');
									}}
									value={assignmentType || []}
									renderTags={(value, { className }) =>
										value.map((option, index) => (
											<Chip
												key={`${index + 1}`}
												variant='outlined'
												classes={{
													root: classes.chipRoot,
													label: classes.chipLabel,
												}}
												data-tag-index={index}
												tabIndex={-1}
												label={this.filterAssignmentTypeOptionsFromValue(
													option
												)}
												className={className}
												onDelete={() =>
													this.autoCompleteDelete(index, 'assignmentType')
												}
											/>
										))
									}
									renderInput={(params) => (
										<TextField
											{...params}
											classes={{ root: classes.typeAheadInput }}
											variant='standard'
											margin='dense'
											fullWidth
										/>
									)}
								/>
							</FormControl>
						</Grid>
					</Grid>
				) : null}
				{this.toBeDisplayed() === 'locationPreference' ? (
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<FormControl>
								<InputLabel htmlFor='LocationPreference'>
									{t('profile:PREFERENCES.LOCATION_PREFERENCES')}
								</InputLabel>
								<Autocomplete
									multiple
									options={this.states.map((item) => {
										return item.value;
									})}
									filterSelectedOptions
									disableCloseOnSelect
									getOptionLabel={(option) =>
										this.filterLocationOptionsFromValue(option)
									}
									id='LocationPreference'
									onChange={(event, newValue) => {
										this.autoCompleteHandler(newValue, 'locationPreference');
									}}
									value={locationPreference || []}
									renderTags={(value, { className }) =>
										value.map((option, index) => (
											<Chip
												key={`${index + 1}`}
												variant='outlined'
												classes={{
													root: classes.chipRoot,
													label: classes.chipLabel,
												}}
												data-tag-index={index}
												tabIndex={-1}
												label={this.filterLocationOptionsFromValue(option)}
												className={className}
												onDelete={() =>
													this.autoCompleteDelete(
														index,
														'locationPreference'
													)
												}
											/>
										))
									}
									renderInput={(params) => (
										<TextField
											{...params}
											classes={{ root: classes.typeAheadInput }}
											variant='standard'
											fullWidth
											margin='dense'
										/>
									)}
								/>
							</FormControl>
						</Grid>
					</Grid>
				) : null}

				{this.toBeDisplayed() === 'shiftType' ? (
					<Grid container spacing={2}>
						<Grid item xs={12} md={10}>
							<FormControl>
								<InputLabel htmlFor='ShiftType' disableAnimation='true'>
									{t('profile:PREFERENCES.SHIFT_TYPE')}
								</InputLabel>
								<Autocomplete
									multiple
									options={this.shiftTypeOptions.map((item) => {
										return item.label;
									})}
									getOptionLabel={(option) =>
										this.filterShiftTypeOptionsFromValue(option)
									}
									id='ShiftType'
									filterSelectedOptions
									disableCloseOnSelect
									onChange={(event, newValue) => {
										this.autoCompleteHandler(newValue, 'shiftType');
									}}
									value={shiftType || []}
									renderTags={(value, { className }) =>
										value.map((option, index) => (
											<Chip
												key={`${index + 1}`}
												variant='outlined'
												classes={{
													root: classes.chipRoot,
													label: classes.chipLabel,
												}}
												data-tag-index={index}
												tabIndex={-1}
												label={this.filterShiftTypeOptionsFromValue(option)}
												className={className}
												onDelete={() =>
													this.autoCompleteDelete(index, 'shiftType')
												}
											/>
										))
									}
									renderInput={(params) => (
										<TextField
											{...params}
											classes={{ root: classes.typeAheadInput }}
											variant='standard'
											margin='dense'
											fullWidth
										/>
									)}
								/>
							</FormControl>
						</Grid>
					</Grid>
				) : null}
				{this.toBeDisplayed() === 'availability' ? (
					<Grid container spacing={2}>
						<Grid item xs={12} md={10}>
							<FormControl>
								<InputLabel htmlFor='Availability'>
									{t('profile:PREFERENCES.AVAILABILITY')}
								</InputLabel>
								<Autocomplete
									multiple
									options={this.availabilityOptions.map((item) => {
										return item.label;
									})}
									getOptionLabel={(option) =>
										this.filterAvailabilityOptionsFromValue(option)
									}
									id='Availability'
									filterSelectedOptions
									disableCloseOnSelect
									onChange={(event, newValue) => {
										this.autoCompleteHandler(newValue, 'availability');
									}}
									value={availability || []}
									renderTags={(value, { className }) =>
										value.map((option, index) => (
											<Chip
												key={`${index + 1}`}
												variant='outlined'
												classes={{
													root: classes.chipRoot,
													label: classes.chipLabel,
												}}
												data-tag-index={index}
												tabIndex={-1}
												label={this.filterAvailabilityOptionsFromValue(
													option
												)}
												className={className}
												onDelete={() =>
													this.autoCompleteDelete(index, 'availability')
												}
											/>
										))
									}
									renderInput={(params) => (
										<TextField
											{...params}
											classes={{ root: classes.typeAheadInput }}
											variant='standard'
											margin='dense'
											fullWidth
										/>
									)}
								/>
							</FormControl>
						</Grid>
					</Grid>
				) : null}
				{this.toBeDisplayed() === 'nextAvailableDate' ? (
					<Grid container spacing={2}>
						<Grid item xs={12} md={10}>
							<ThemeProvider theme={datePickerTheme}>
								<MuiPickersUtilsProvider utils={UtcUtils} moment={moment}>
									<FormControl>
										<InputLabel htmlFor='NextAvailableDate'>
											{t('profile:PREFERENCES.NEXT_AVAILABLE_DATE')}
										</InputLabel>
										<DatePickerComponent
											theme={datePickerTheme}
											variant='inline'
											autoOk='true'
											format='MM/DD/YYYY'
											placeholder='MM/DD/YYYY'
											name='nextAvailableDate'
											margin='dense'
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<Event />
													</InputAdornment>
												),
												classes: { root: classes.cssOutlinedInput },
											}}
											id='NextAvailableDate'
											value={this.datePickerHelper(nextAvailableDate)}
											onChange={(date, parsedDate) => {
												this.dateChangeHandler(
													date,
													parsedDate,
													'nextAvailableDate'
												);
											}}
											KeyboardButtonProps={{
												'aria-label': 'Next Available Date',
											}}
											inputVariant='outlined'
										/>
									</FormControl>
								</MuiPickersUtilsProvider>
							</ThemeProvider>
						</Grid>
					</Grid>
				) : null}
				{this.toBeDisplayed() === 'locationType' ? (
					<Grid container spacing={2}>
						<Grid item xs={12} md={10}>
							<FormControl>
								<InputLabel htmlFor='LocationType'>
									{t('profile:PREFERENCES.LOCATION_TYPE')}
								</InputLabel>
								<Autocomplete
									multiple
									options={this.locationTypeOptions.map((item) => {
										return item.label;
									})}
									getOptionLabel={(option) =>
										this.filterLocationTypeOptionsFromValue(option)
									}
									id='LocationType'
									filterSelectedOptions
									disableCloseOnSelect
									onChange={(event, newValue) => {
										this.autoCompleteHandler(newValue, 'locationType');
									}}
									value={locationType || []}
									renderTags={(value, { className }) =>
										value.map((option, index) => (
											<Chip
												key={`${index + 1}`}
												variant='outlined'
												classes={{
													root: classes.chipRoot,
													label: classes.chipLabel,
												}}
												data-tag-index={index}
												tabIndex={-1}
												label={this.filterLocationTypeOptionsFromValue(
													option
												)}
												className={className}
												onDelete={() =>
													this.autoCompleteDelete(index, 'locationType')
												}
											/>
										))
									}
									renderInput={(params) => (
										<TextField
											{...params}
											classes={{ root: classes.typeAheadInput }}
											variant='standard'
											margin='dense'
											fullWidth
										/>
									)}
								/>
							</FormControl>
						</Grid>
					</Grid>
				) : null}
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Button variant='contained' color='primary' onClick={() => this.doSubmit()}>
							Save
						</Button>
					</Grid>
					<Grid item xs={12} className='cr-journey'>
						<NavLink className='textLink' to={{ pathname: '/profile/preference' }}>
							{t('profile:PREFERENCES.SEE_PREFERENCES')}
						</NavLink>
					</Grid>
				</Grid>
			</>
		);
	}
}

PreferenceCompletion.propTypes = {
	classes: PropTypes.shape({
		header: PropTypes.string.isRequired,
	}).isRequired,
	t: PropTypes.func.isRequired,
	tabHandler: PropTypes.func.isRequired,
	flags: PropTypes.shape({
		missingData: PropTypes.bool.isRequired,
		dataSaved: PropTypes.bool.isRequired,
		isDirty: PropTypes.bool.isRequired,
		showSaveChanges: PropTypes.bool.isRequired,
		isPendingSave: PropTypes.bool.isRequired,
		targetTab: PropTypes.number.isRequired,
	}).isRequired,
	updateFlags: PropTypes.func.isRequired,
	externalId: PropTypes.string.isRequired,
};

export default withTranslation()(withStyles(styles)(PreferenceCompletion));
