import React, { Component } from 'react';
import msSalService from '../../services/msSalService';
import TimeoutModal from './TimeoutModal';

function parse_query_string(query) {
	var vars = query.split('&');
	var query_string = {};
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		var key = decodeURIComponent(pair[0]);
		var value = decodeURIComponent(pair[1]);
		// If first entry with this name
		if (typeof query_string[key] === 'undefined') {
			query_string[key] = decodeURIComponent(value);
			// If second entry with this name
		} else if (typeof query_string[key] === 'string') {
			var arr = [query_string[key], decodeURIComponent(value)];
			query_string[key] = arr;
			// If third or later entry with this name
		} else {
			query_string[key].push(decodeURIComponent(value));
		}
	}
	return query_string;
}

class LogOutPage extends Component {
	constructor() {
		super();
		this.state = {
			action: undefined,
			redirecturl: undefined,
			showModal: true,
			flag: false,
		};
	}

	componentWillMount() {
		var query_string = window.location.hash.split('?')[1];
		var parsed_qs = parse_query_string(query_string);
		//this.authenticationLogOut(parsed_qs.url);
		localStorage.removeItem('LocalStorage_UserSignedIN');
		localStorage.removeItem('LocalStorage_FirstTimeUserLogin');
		localStorage.removeItem('LocalStorage_PersonalInfoDisclaimerAlert');
		localStorage.removeItem('snoozeNotification');

		msSalService.clearAuthInfo();
		msSalService.fireLogOutEvent();
		if (parsed_qs.action === 'sessiontimeout') {
			this.setState({
				redirecturl: encodeURI(
					`${process.env.REACT_APP_LT_API_URI}/Resources/Security/LogoutApplication?url=${process.env.REACT_APP_LOGIN_URL}`
				),
				action: parsed_qs.action,
			});
		} else if (parsed_qs.action === 'user') {
			this.setState({
				redirecturl: encodeURI(
					`${process.env.REACT_APP_LT_API_URI}/Resources/Security/LogoutApplication?url=${process.env.REACT_APP_LOGIN_URL}`
				),
				action: parsed_qs.action,
			});
		} else if (parsed_qs.action === 'jobboard') {
			this.setState({
				redirecturl: parsed_qs.url,
				action: parsed_qs.action,
			});
		} else if (parsed_qs.action === 'jobboardRedirect') {
			this.setState({
				redirecturl: parsed_qs.url,
				action: parsed_qs.action,
			});
		}
	}

	redirectToLogin = () => {
		this.setState({ showModal: false });
		msSalService.redirectToSignIn();
	};

	toggleLogoutDialog = (value) => {
		this.setState({ displayLogoutModal: value });
		msSalService.redirectToSignIn();
	};

	clearLocalStorage = () => {
		const startTime = localStorage.getItem('LocalStorgae_startTimerViewandSign');
		window.localStorage.clear();
		if (startTime) {
			localStorage.setItem('LocalStorgae_startTimerViewandSign', startTime);
		}
	};

	renderLoggedoutDialog = () => {
		const { showModal } = this.state;
		this.clearLocalStorage();
		return (
			<TimeoutModal
				showModal={showModal}
				handleSignin={this.redirectToLogin}
				togglePopup={this.toggleLogoutDialog}
			/>
		);
	};

	renderOlderSignOut = () => {
		const { flag } = this.state;
		if (this.state.redirecturl && !flag) {
			window.location = this.state.redirecturl;
			return null;
		} else {
			return null;
		}
	};
	render() {
		var query_string = window.location.hash.split('?')[1];
		var parsed_qs = parse_query_string(query_string);
		var reasonFlag = localStorage.getItem('LocalStorage_Reason');
		if (parsed_qs.action === 'sessiontimeout' && reasonFlag && reasonFlag === 'timesup') {
			return (
				<>
					{this.renderLoggedoutDialog()}
				</>
			);
		}
		if (this.state.redirecturl) {
			this.clearLocalStorage();
			window.location = this.state.redirecturl;
			return null;
		} else {
			this.clearLocalStorage();
			return null;
		}
	}
}

export default LogOutPage;
