import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	withStyles,
	Paper,
	Grid,
	FormControl,
	InputLabel,
	Chip,
	TextField,
	CircularProgress,
	InputAdornment,
	ThemeProvider,
} from '@material-ui/core';
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Event } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withTranslation } from 'react-i18next';
import UtcUtils from '../../GlobalComponents/UtcUtils/UtcUtils';
import {
	availabilityOptions,
	assignmentTypeOptions,
	shiftTypeOptions,
	workInterestsOptions,
	secondLanguageOptions,
} from './preferencesOptions';
import { states } from '../Account/accountOptions';
import {
	COLORS,
	ACTIVE_PATHNAME_AP,
	ACTIVE_PATHNAME_MD,
} from '../../../utils/Application_Constants';
import DatePickerComponent from '../../GlobalComponents/DatePickerComponent/DatePickerComponent';
import datePickerTheme from '../../GlobalComponents/DatePickerComponent/DatePickerTheme';
import {
	getClinicianAssignPreference,
	postClinicianAssignPreference,
	getSkillsForSpecialties,
	getClinicianAccount,
} from '../../../services/ProfileServices/profileService';
import SpecialtyAndSkillsComponent from '../SpecialtyAndSkillComponent/SpecialtyAndSkillComponent';

const styles = (theme) => ({
	header: {
		color: COLORS.ST_TROPAZ,
		textAlign: 'left',
		paddingTop: '36px',
		fontSize: '17px',
		fontWeight: '700',
	},
	inputLabel: {
		// color: COLORS.RAVEN,
		// fontWeight: '700',
		// fontSize: '16px',
		// textAlign: 'left',
		[theme.breakpoints.down('sm')]: {
			margin: '-10px 0',
		},
	},
	progress: {
		height: '100vh',
	},
	paperRoot: {
		paddingBottom: '60px',
	},
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			padding: '0 32px',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '5px 10px',
		},
	},
	setWidthTo100: {
		width: '100%',
		paddingTop: '5px',
	},

	typeAheadInput: {
		'label + &': {
			marginTop: theme.spacing(2),
		},
		borderRadius: 0,
		padding: '12px',
		position: 'relative',
		backgroundColor: COLORS.WHITE,
		color: COLORS.LT_MIDNIGHT,
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		fontSize: '16px',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			padding: '5px',
		},
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			// boxShadow: COLORS.WHITE_LILAC,
			border: `1px solid ${COLORS.LT_JOURNEY}!important`,
		},
		'&:hover': {
			border: `1px solid ${COLORS.LT_JOURNEY}!important`,
		},
	},
	chipRoot: {
		backgroundColor: COLORS.ZIRCON,
		borderRadius: '3px',
	},
	chipLabel: {
		color: COLORS.RAVEN,
	},
	cssOutlinedInput: {
		height: 45,
		fontSize: '16px',
		color: COLORS.RAVEN,
		padding: '0px 0px',
		'&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //default
		},
		'&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
			borderColor: '#E7E7E7', //hovered
		},
		'&$cssFocused $notchedOutline': {
			borderColor: '#6D707E', //focused
			borderWidth: '1px',
			borderRadius: 1,
		},
	},
	disabledInput: {
		opacity: '0.6',
	},
});

class AssignmentPreferences extends Component {
	constructor(props) {
		super(props);
		this.availabilityOptions = availabilityOptions;
		this.assignmentTypeOptions = assignmentTypeOptions;
		this.shiftTypeOptions = shiftTypeOptions;
		this.workInterestsOptions = workInterestsOptions;
		this.secondLanguageOptions = secondLanguageOptions;
		this.states = states;
		this.state = {
			clinicianPreferences: {},
			workInterestArray: [],
			skillMappingList: [],
			specialtyList: [],
			activePath: null,
		};
	}

	async componentDidMount() {
		const { externalId, setSaveHandler, setPreferencePageError } = this.props;
		const state = { ...this.state };

		await Promise.all([
			getClinicianAssignPreference(externalId),
			getSkillsForSpecialties(),
			getClinicianAccount(externalId),
		])
			.then(async ([clinicianPreferences, skillMappingList, personalInformationObj]) => {
				const activePath =
					!!personalInformationObj && personalInformationObj.providerLevel === 'Doctor'
						? ACTIVE_PATHNAME_MD
						: ACTIVE_PATHNAME_AP;

				state.clinicianPreferences = clinicianPreferences;
				state.skillMappingList = skillMappingList;
				state.activePath = activePath;
			})
			.catch((e) => {
				setPreferencePageError(e);
			});

		state.specialtyList = [{ label: 'Select', value: null }];
		state.specialtyList = state.specialtyList.concat(
			Object.keys(state.skillMappingList)
				.reverse()
				.map((item) => ({
					label: item,
					value: item,
				}))
		);
		state.specialtyList = state.specialtyList.filter(
			(specialty) =>
				![
					'Burn Surgery',
					'Hospitalist',
					'Hospice and Palliative Care',
					'Urgent Care',
					'Urogynecology',
					'Women\u{2019}s Imaging',
					'Wound Care',
				].includes(specialty.value)
		);
		state.workInterestArray = this.workInterestTransformer(
			state.clinicianPreferences.workInterests
		);
		this.setState({
			clinicianPreferences: state.clinicianPreferences,
			skillMappingList: state.skillMappingList,
			specialtyList: state.specialtyList,
			workInterestArray: state.workInterestArray,
			activePath: state.activePath,
		});
		setSaveHandler(this, this.doSubmit);
	}

	async doSubmit() {
		// Validate required fields
		const state = { ...this.state };
		const { externalId, setPreferencePageError } = this.props;
		//const specialitiesVal = state.clinicianPreferences.specialties
		for (var key in state.clinicianPreferences.specialties) {
			if (state.clinicianPreferences.specialties.hasOwnProperty(key)) {
				if (
					state.clinicianPreferences.specialties[key].isNew !== undefined &&
					state.clinicianPreferences.specialties[key].isNew === true
				) {
					delete state.clinicianPreferences.specialties[key].isNew;
				}
			}
		}

		const flags = this.props.flags;
		const missingAnyFields = this.isMissingFieldsCheckSubmit(
			state.clinicianPreferences.specialties,
			state.activePath
		);
		flags.missingData = missingAnyFields;
		this.props.updateFlags(flags);
		if (!missingAnyFields) {
			flags.isPendingSave = true;
			this.props.updateFlags(flags);
			await postClinicianAssignPreference(externalId, state.clinicianPreferences).catch(
				(e) => {
					setPreferencePageError(e);
				}
			);

			let refreshedClinicianPreferences = [];
			await getClinicianAssignPreference(externalId).then(function (data) {
				refreshedClinicianPreferences = data;
			})
			.catch(
				(e) => {
					setPreferencePageError(e);
				}
			);

			this.setState({ clinicianPreferences: refreshedClinicianPreferences });
			flags.dataSaved = true;
			flags.isDirty = false;
			flags.isPendingSave = false;
			this.props.updateFlags(flags);
		}
	}

	markFlagsDirty = () => {
		const flags = this.props.flags;
		flags.isDirty = true;
		flags.dataSaved = false;
		this.props.updateFlags(flags);
	};

	isMissingFieldsCheckSubmit = (specialties, activePath) => {
		if (activePath !== ACTIVE_PATHNAME_MD) {
			for (let i = 0; i < specialties.length; i++) {
				if (
					specialties[i].specialtyName === '' ||
					specialties[i].specialtyName === 'Select'
				) {
					return true;
				}
				if (
					specialties[i].isActivelyPracticing === true &&
					specialties[i].startedPracticing === null
				) {
					return true;
				}
				if (
					specialties[i].startedPracticing !== null &&
					(specialties[i].isActivelyPracticing === null ||
						specialties[i].isActivelyPracticing === false) &&
					specialties[i].lastPracticed === null
				) {
					return true;
				}
				if (
					specialties[i].lastPracticed !== null &&
					specialties[i].startedPracticing === null
				) {
					return true;
				}
				return false;
			}
		}
		return false;
	};

	isMissingFields = (specialty, activePath) => {
		if (activePath !== ACTIVE_PATHNAME_MD) {
			if (specialty.specialtyName === '' || specialty.specialtyName === 'Select') {
				return true;
			}
			if (specialty.isActivelyPracticing === true && specialty.startedPracticing === null) {
				return true;
			}
			if (
				specialty.startedPracticing !== null &&
				(specialty.isActivelyPracticing === null ||
					specialty.isActivelyPracticing === false) &&
				specialty.lastPracticed === null
			) {
				return true;
			}
			if (specialty.lastPracticed !== null && specialty.startedPracticing === null) {
				return true;
			}
			return false;
		}
		return false;
	};

	shouldDisableSave = (index) => {
		const flags = this.props.flags;
		const state = { ...this.state };
		const missingAnyFields =
			state.clinicianPreferences.specialties[index] !== undefined
				? this.isMissingFields(
						state.clinicianPreferences.specialties[index],
						state.activePath
				  )
				: false;
		flags.missingData = missingAnyFields;
		this.props.updateFlags(flags);
	};

	getWorkInterestLabelFromValue = (value) => {
		let label;
		Object.entries(this.workInterestsOptions).forEach((item) => {
			if (item[1].value === value) {
				label = item[1].label;
			}
		});
		return label;
	};

	labelValueConstructor = (value, label) => ({
		label,
		value,
	});

	/**
	 * Work interests are handled differently than the rest of the
	 * multi selects in the profile page. We must have logic to
	 * transform them to what material ui expects.
	 */
	workInterestTransformer = (workInterests) => {
		const currentlySelectedWorkIntersts = [];
		for (const [key, value] of Object.entries(workInterests)) {
			const label = this.getWorkInterestLabelFromValue(key);
			if (value) {
				currentlySelectedWorkIntersts.push(this.labelValueConstructor(key, label));
			}
		}
		return currentlySelectedWorkIntersts;
	};

	/**
	 * Handler for work interest. Work interests are the most complicated of logic flow
	 * due to the way it's coming back from the api.
	 * Note - the first line in the method is just  making sure duplicates aren't selected by the user.
	 */
	workInterestHandler = (newValue) => {
		newValue = Array.from(new Set(newValue.map(JSON.stringify))).map(JSON.parse);
		const newWorkInterests = {};
		const state = { ...this.state };
		newValue.forEach((item) => {
			newWorkInterests[item.value] = true;
		});
		const formattedInterests = this.formatWorkInterestsForSave(newWorkInterests);
		state.clinicianPreferences.workInterests = formattedInterests;
		state.workInterestArray = newValue;
		this.markFlagsDirty();
		this.setState({ ...state });
	};

	/**
	 * NOTE - missionWork is hardcoded here to always be false (it has to be right now).
	 * When it is no longer needed, must remove it here.
	 */
	formatWorkInterestsForSave = (selectedInterests) => {
		const unSelectedWorkInterests = {};
		const allWorkInterests = this.getWorkInterestOptionValueList();
		for (const [key] of Object.entries(selectedInterests)) {
			if (allWorkInterests.indexOf(key) > -1) {
				const index = allWorkInterests.indexOf(key);
				allWorkInterests.splice(index, 1);
			}
		}
		allWorkInterests.forEach((item) => {
			unSelectedWorkInterests[item] = false;
		});
		//unSelectedWorkInterests.missionWork = false;
		return Object.assign(unSelectedWorkInterests, selectedInterests);
	};

	getWorkInterestOptionValueList = () => {
		const allWorkInterests = [];
		Object.entries(this.workInterestsOptions).forEach((item) => {
			allWorkInterests.push(item[1].value);
		});
		return allWorkInterests;
	};

	workInterestDelete = (index) => {
		const state = { ...this.state };
		const { workInterestArray } = state;
		const newWorkInterests = {};
		workInterestArray.splice(index, 1);
		state.workInterestArray = workInterestArray;
		workInterestArray.forEach((item) => {
			newWorkInterests[item.value] = true;
		});
		const formattedInterests = this.formatWorkInterestsForSave(newWorkInterests);
		state.clinicianPreferences.workInterests = formattedInterests;
		this.markFlagsDirty();
		this.setState({ ...state });
	};

	filterLocationOptionsFromValue = (selectedValue) => {
		const selectedState = this.states.filter((item) => item.value === selectedValue);
		if (!selectedState[0]) return selectedValue;
		return selectedState[0].label;
	};

	filterAvailabilityOptionsFromValue = (selectedValue) => {
		const selectedAvailabilityOption = this.availabilityOptions.filter(
			(item) => item.value === selectedValue
		);
		if (!selectedAvailabilityOption[0]) return selectedValue;
		return selectedAvailabilityOption[0].label;
	};

	filterAssignmentTypeOptionsFromValue = (selectedValue) => {
		const selectedAssignmentType = this.assignmentTypeOptions.filter(
			(item) => item.value === selectedValue
		);
		if (!selectedAssignmentType[0]) return selectedValue;
		return selectedAssignmentType[0].label;
	};

	filterShiftTypeOptionsFromValue = (selectedValue) => {
		const selectedShiftType = this.shiftTypeOptions.filter(
			(item) => item.value === selectedValue
		);
		if (!selectedShiftType[0]) return selectedValue;
		return selectedShiftType[0].label;
	};

	filterSecondLanguageOptionsFromValue = (selectedValue) => {
		const selectedLanguageType = this.secondLanguageOptions.filter(
			(item) => item.value === selectedValue
		);
		if (!selectedLanguageType[0]) return selectedValue;
		return selectedLanguageType[0].label;
	};

	dropdownHandler = (event) => {
		const state = { ...this.state };
		const { name, value } = event.target;
		state.clinicianPreferences[name] = value;
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	handleChangeMultiple = (event) => {
		const state = { ...this.state };
		const { name, options, value } = event.target;
		const newArr = [];
		options.forEach((item) => {
			if (item.selected) {
				newArr.push(item);
			}
		});
		state.clinicianPreferences[name] = value;
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	checkboxHandler = (event, index) => {
		const state = { ...this.state };
		const { name, checked } = event.target;
		state.clinicianPreferences.specialties[index][name] = checked;
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
		this.shouldDisableSave(index);
	};

	dateChangeHandler = (date, parsedDate, name) => {
		const state = { ...this.state };
		if (date) {
			//When the Date is valid strip out the time, otherwise keep the invalid value
			if (date.toJSON()) {
				state.clinicianPreferences[name] = date.toJSON().split('T')[0];
			} else {
				state.clinicianPreferences[name] = moment.utc(
					date.startOf('day').format('YYYY-MM-DD HH:mm:ss')
				);
			}
		} else {
			state.clinicianPreferences[name] = null;
		}
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	specialtiesDateChangeHandler = (date, index, name) => {
		const state = { ...this.state };
		if (date) {
			//When the Date is valid strip out the time, otherwise keep the invalid value
			if (date.toJSON()) {
				state.clinicianPreferences.specialties[index][name] = date.toJSON().split('T')[0];
			} else {
				state.clinicianPreferences.specialties[index][name] = moment.utc(
					date.startOf('day').format('YYYY-MM-DD HH:mm:ss')
				);
			}
		} else {
			state.clinicianPreferences.specialties[index][name] = null;
		}
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
		this.shouldDisableSave(index);
	};

	autoCompleteHandler = (newValue, name) => {
		const newValues = [];
		newValue.forEach((item) => {
			if (typeof item === 'string') {
				newValues.push(item);
				return;
			}
			newValues.push(item.value);
		});
		const state = { ...this.state };
		state.clinicianPreferences[name] = newValues;
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	autoCompleteSpecialityHandler = (newValue, name, index) => {
		const state = { ...this.state };
		const newValues = [];
		newValue.forEach((item) => {
			if (typeof item === 'string') {
				newValues.push(item);
				return;
			}
			newValues.push(item.value);
		});

		if (state.clinicianPreferences.specialties[index][name] !== newValues) {
			state.clinicianPreferences.specialties[index][name] = newValues;
			this.markFlagsDirty();
		}
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	/**
	 * There can only be one preferred item in a group. This method checks whether or not
	 * another travel item has been set to preferred and removes the previous flag.
	 * @param {Number} the currently selected index;
	 * @param {String} the group currenyl being toggled
	 */
	preferredGroupToggled = (currentlyToggledIndex, travelGroup) => {
		const state = { ...this.state };
		state.clinicianPreferences[travelGroup].forEach((item, index) => {
			if (index === currentlyToggledIndex) {
				return;
			}
			state.clinicianPreferences[travelGroup][index].isPreferred = false;
		});
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	/**
	 * When clicking a preference this logic ensures that theres always
	 * a preference selected
	 * @param {Number} index
	 * @param {String} travelGroup
	 */
	handlePreferredButtonClick = (index, travelGroup) => {
		const state = { ...this.state };
		state.clinicianPreferences[travelGroup][index].isPreferred =
			!state.clinicianPreferences[travelGroup][index].isPreferred;
		this.preferredGroupToggled(index, travelGroup);
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	autoCompleteDelete = async (index, name) => {
		const state = { ...this.state };
		state.clinicianPreferences[name].splice(index, 1);
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	autoCompleteSpecialtiesDelete = async (index, name, specialtyIndex) => {
		const state = { ...this.state };
		state.clinicianPreferences.specialties[specialtyIndex][name].splice(index, 1);
		this.markFlagsDirty();
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	specialtiesUpdateHandler = (specialties) => {
		const state = { ...this.state };
		state.clinicianPreferences.specialties = specialties;
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	clearTags = (index) => {
		const state = { ...this.state };
		state.clinicianPreferences.specialties[index].skills = [];
		this.setState({ clinicianPreferences: state.clinicianPreferences });
	};

	datePickerHelper(date) {
		if (date) {
			return date;
		}
		return null;
	}

	render() {
		const { classes, t, flags } = this.props;
		const {
			clinicianPreferences,
			workInterestArray,
			skillMappingList,
			specialtyList,
			activePath,
		} = this.state;
		if (
			Object.keys(clinicianPreferences).length === 0 &&
			Object.keys(skillMappingList).length === 0
		) {
			return (
				<div className={classes.progress}>
					<CircularProgress />
				</div>
			);
		}
		const {
			availability,
			nextAvailableDate,
			lastAvailableDate,
			assignmentType,
			shiftType,
			locationPreference,
			specialties,
			secondLanguage,
		} = clinicianPreferences;

		return (
			<>
				<Paper classes={{ root: classes.paperRoot }}>
					<Grid container classes={{ root: classes.gridRoot }} item xs={12}>
						<h3 className={classes.header}>
							{t('profile:PREFERENCES.ASSIGNMENT_PREFERENCES')}
						</h3>
					</Grid>
					<Grid container classes={{ root: classes.gridRoot }} spacing={2}>
						<Grid item xs={12} md={10}>
							<FormControl classes={{ root: classes.setWidthTo100 }}>
								<InputLabel
									shrink
									htmlFor='Availability'
									classes={{ root: classes.inputLabel }}
								>
									{t('profile:PREFERENCES.AVAILABILITY')}
								</InputLabel>
								<Autocomplete
									multiple
									options={this.availabilityOptions.map((item) => {
										return item.label;
									})}
									getOptionLabel={(option) =>
										this.filterAvailabilityOptionsFromValue(option)
									}
									id='Availability'
									filterSelectedOptions
									disableCloseOnSelect
									onChange={(event, newValue) => {
										this.autoCompleteHandler(newValue, 'availability');
									}}
									value={availability || []}
									renderTags={(value, { className }) =>
										value.map((option, index) => (
											<Chip
												key={`${index + 1}`}
												variant='outlined'
												classes={{
													root: classes.chipRoot,
													label: classes.chipLabel,
												}}
												data-tag-index={index}
												tabIndex={-1}
												label={this.filterAvailabilityOptionsFromValue(
													option
												)}
												className={className}
												onDelete={() =>
													this.autoCompleteDelete(index, 'availability')
												}
											/>
										))
									}
									renderInput={(params) => (
										<TextField
											{...params}
											classes={{ root: classes.typeAheadInput }}
											variant='standard'
											margin='dense'
											fullWidth
										/>
									)}
								/>
							</FormControl>
						</Grid>
					</Grid>
					<Grid container classes={{ root: classes.gridRoot }} spacing={2}>
						<Grid item xs={12} md={6}>
							<ThemeProvider theme={datePickerTheme}>
								<MuiPickersUtilsProvider utils={UtcUtils} moment={moment}>
									<FormControl classes={{ root: classes.setWidthTo100 }}>
										<InputLabel
											shrink
											htmlFor='NextAvailableDate'
											classes={{ root: classes.inputLabel }}
										>
											{t('profile:PREFERENCES.NEXT_AVAILABLE_DATE')}
										</InputLabel>
										<DatePickerComponent
											theme={datePickerTheme}
											variant='inline'
											autoOk='true'
											format='MM/DD/YYYY'
											placeholder='MM/DD/YYYY'
											name='nextAvailableDate'
											margin='dense'
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<Event />
													</InputAdornment>
												),
												classes: { root: classes.cssOutlinedInput },
											}}
											id='NextAvailableDate'
											value={this.datePickerHelper(nextAvailableDate)}
											onChange={(date, parsedDate) => {
												this.dateChangeHandler(
													date,
													parsedDate,
													'nextAvailableDate'
												);
											}}
											KeyboardButtonProps={{
												'aria-label': 'Next Available Date',
											}}
											inputVariant='outlined'
										/>
									</FormControl>
								</MuiPickersUtilsProvider>
							</ThemeProvider>
						</Grid>
						<Grid item xs={12} md={6}>
							<ThemeProvider theme={datePickerTheme}>
								<MuiPickersUtilsProvider utils={UtcUtils} moment={moment}>
									<FormControl classes={{ root: classes.setWidthTo100 }}>
										<InputLabel
											shrink
											htmlFor='LastAvailableDate'
											classes={{ root: classes.inputLabel }}
										>
											{t('profile:PREFERENCES.LAST_AVAILABLE_DATE')}
										</InputLabel>
										<DatePickerComponent
											theme={datePickerTheme}
											variant='inline'
											autoOk='true'
											format='MM/DD/YYYY'
											placeholder='MM/DD/YYYY'
											name='lastAvailableDate'
											margin='dense'
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<Event />
													</InputAdornment>
												),
												classes: { root: classes.cssOutlinedInput },
											}}
											id='LastAvailableDate'
											value={this.datePickerHelper(lastAvailableDate)}
											onChange={(date, parsedDate) => {
												this.dateChangeHandler(
													date,
													parsedDate,
													'lastAvailableDate'
												);
											}}
											KeyboardButtonProps={{
												'aria-label': 'Last Available Date',
											}}
											inputVariant='outlined'
										/>
									</FormControl>
								</MuiPickersUtilsProvider>
							</ThemeProvider>
						</Grid>
					</Grid>

					<Grid container classes={{ root: classes.gridRoot }} spacing={2}>
						<Grid item xs={12} md={10}>
							<FormControl classes={{ root: classes.setWidthTo100 }}>
								<InputLabel
									shrink
									htmlFor='AssignmentType'
									classes={{ root: classes.inputLabel }}
								>
									{t('profile:PREFERENCES.ASSIGNMENT_TYPE')}
								</InputLabel>
								<Autocomplete
									multiple
									options={this.assignmentTypeOptions.map((item) => {
										return item.label;
									})}
									getOptionLabel={(option) =>
										this.filterAssignmentTypeOptionsFromValue(option)
									}
									id='AssignmentType'
									filterSelectedOptions
									disableCloseOnSelect
									onChange={(event, newValue) => {
										this.autoCompleteHandler(newValue, 'assignmentType');
									}}
									value={assignmentType || []}
									renderTags={(value, { className }) =>
										value.map((option, index) => (
											<Chip
												key={`${index + 1}`}
												variant='outlined'
												classes={{
													root: classes.chipRoot,
													label: classes.chipLabel,
												}}
												data-tag-index={index}
												tabIndex={-1}
												label={this.filterAssignmentTypeOptionsFromValue(
													option
												)}
												className={className}
												onDelete={() =>
													this.autoCompleteDelete(index, 'assignmentType')
												}
											/>
										))
									}
									renderInput={(params) => (
										<TextField
											{...params}
											classes={{ root: classes.typeAheadInput }}
											variant='standard'
											margin='dense'
											fullWidth
										/>
									)}
								/>
							</FormControl>
						</Grid>
					</Grid>
					<Grid container classes={{ root: classes.gridRoot }} spacing={2}>
						<Grid item xs={12} md={10}>
							<FormControl classes={{ root: classes.setWidthTo100 }}>
								<InputLabel
									shrink
									htmlFor='ShiftType'
									classes={{ root: classes.inputLabel }}
								>
									{t('profile:PREFERENCES.SHIFT_TYPE')}
								</InputLabel>
								<Autocomplete
									multiple
									options={this.shiftTypeOptions.map((item) => {
										return item.label;
									})}
									getOptionLabel={(option) =>
										this.filterShiftTypeOptionsFromValue(option)
									}
									id='ShiftType'
									filterSelectedOptions
									disableCloseOnSelect
									onChange={(event, newValue) => {
										this.autoCompleteHandler(newValue, 'shiftType');
									}}
									value={shiftType || []}
									renderTags={(value, { className }) =>
										value.map((option, index) => (
											<Chip
												key={`${index + 1}`}
												variant='outlined'
												classes={{
													root: classes.chipRoot,
													label: classes.chipLabel,
												}}
												data-tag-index={index}
												tabIndex={-1}
												label={this.filterShiftTypeOptionsFromValue(option)}
												className={className}
												onDelete={() =>
													this.autoCompleteDelete(index, 'shiftType')
												}
											/>
										))
									}
									renderInput={(params) => (
										<TextField
											{...params}
											classes={{ root: classes.typeAheadInput }}
											variant='standard'
											margin='dense'
											fullWidth
										/>
									)}
								/>
							</FormControl>
						</Grid>
					</Grid>

					<Grid container classes={{ root: classes.gridRoot }} spacing={2}>
						<Grid item xs={12} md={10}>
							<FormControl classes={{ root: classes.setWidthTo100 }}>
								<InputLabel
									shrink
									htmlFor='WorkInterests'
									classes={{ root: classes.inputLabel }}
								>
									{t('profile:PREFERENCES.WORK_INTERESTS')}
								</InputLabel>
								<Autocomplete
									multiple
									options={this.workInterestsOptions}
									getOptionLabel={(option) => option.label}
									filterSelectedOptions
									id='workInterests'
									value={workInterestArray || []}
									onChange={(event, newValue) => {
										this.workInterestHandler(newValue);
									}}
									renderTags={(value, { className }) =>
										value.map((option, index) => (
											<Chip
												key={`${index + 1}`}
												variant='outlined'
												classes={{
													root: classes.chipRoot,
													label: classes.chipLabel,
												}}
												data-tag-index={index}
												tabIndex={-1}
												label={option.label}
												className={className}
												onDelete={(item) =>
													this.workInterestDelete(index, item)
												}
											/>
										))
									}
									renderInput={(params) => (
										<TextField
											{...params}
											classes={{ root: classes.typeAheadInput }}
											variant='standard'
											margin='dense'
											fullWidth
										/>
									)}
								/>
							</FormControl>
						</Grid>
					</Grid>
					<Grid container classes={{ root: classes.gridRoot }} spacing={2}>
						<Grid item xs={12} md={10}>
							<FormControl classes={{ root: classes.setWidthTo100 }}>
								<InputLabel
									shrink
									htmlFor='LocationPreference'
									classes={{ root: classes.inputLabel }}
								>
									{t('profile:PREFERENCES.LOCATION_PREFERENCES')}
								</InputLabel>
								<Autocomplete
									multiple
									options={this.states.map((item) => {
										return item.value;
									})}
									filterSelectedOptions
									disableCloseOnSelect
									getOptionLabel={(option) =>
										this.filterLocationOptionsFromValue(option)
									}
									id='LocationPreference'
									onChange={(event, newValue) => {
										this.autoCompleteHandler(newValue, 'locationPreference');
									}}
									value={locationPreference || []}
									renderTags={(value, { className }) =>
										value.map((option, index) => (
											<Chip
												key={`${index + 1}`}
												variant='outlined'
												classes={{
													root: classes.chipRoot,
													label: classes.chipLabel,
												}}
												data-tag-index={index}
												tabIndex={-1}
												label={this.filterLocationOptionsFromValue(option)}
												className={className}
												onDelete={() =>
													this.autoCompleteDelete(
														index,
														'locationPreference'
													)
												}
											/>
										))
									}
									renderInput={(params) => (
										<TextField
											{...params}
											classes={{ root: classes.typeAheadInput }}
											variant='standard'
											margin='dense'
											fullWidth
										/>
									)}
								/>
							</FormControl>
						</Grid>
					</Grid>
					<Grid container classes={{ root: classes.gridRoot }} spacing={2}>
						<Grid item xs={12} md={10}>
							<FormControl classes={{ root: classes.setWidthTo100 }}>
								<InputLabel
									shrink
									htmlFor='SecondLanguage'
									classes={{ root: classes.inputLabel }}
								>
									{t('profile:PREFERENCES.SECOND_LANGUAGE')}
								</InputLabel>
								<Autocomplete
									multiple
									options={this.secondLanguageOptions.map((item) => {
										return item.label;
									})}
									getOptionLabel={(option) =>
										this.filterSecondLanguageOptionsFromValue(option)
									}
									id='SecondLanguage'
									filterSelectedOptions
									disableCloseOnSelect
									onChange={(event, newValue) => {
										this.autoCompleteHandler(newValue, 'secondLanguage');
									}}
									value={secondLanguage || []}
									renderTags={(value, { className }) =>
										value.map((option, index) => (
											<Chip
												key={`${index + 1}`}
												variant='outlined'
												classes={{
													root: classes.chipRoot,
													label: classes.chipLabel,
												}}
												data-tag-index={index}
												tabIndex={-1}
												label={this.filterSecondLanguageOptionsFromValue(
													option
												)}
												className={className}
												onDelete={() =>
													this.autoCompleteDelete(index, 'secondLanguage')
												}
											/>
										))
									}
									renderInput={(params) => (
										<TextField
											{...params}
											classes={{ root: classes.typeAheadInput }}
											variant='standard'
											margin='dense'
											fullWidth
										/>
									)}
								/>
							</FormControl>
						</Grid>
					</Grid>
					<SpecialtyAndSkillsComponent
						setDirtyFlag={this.markFlagsDirty}
						specialties={specialties}
						specialtiesUpdateHandler={this.specialtiesUpdateHandler}
						checkboxHandler={this.checkboxHandler}
						autoCompleteHandler={this.autoCompleteSpecialityHandler}
						autoCompleteDelete={this.autoCompleteSpecialtiesDelete}
						clearTags={this.clearTags}
						specialtyList={specialtyList}
						skillMappingList={skillMappingList}
						activePath={activePath}
						datePickerHelper={this.datePickerHelper}
						specialtiesDateChangeHandler={this.specialtiesDateChangeHandler}
						flags={flags}
						shouldDisableSave={this.shouldDisableSave}
					/>
				</Paper>
			</>
		);
	}
}

AssignmentPreferences.propTypes = {
	classes: PropTypes.shape({
		header: PropTypes.string.isRequired,
	}).isRequired,
	t: PropTypes.func.isRequired,
	tabHandler: PropTypes.func.isRequired,
	flags: PropTypes.shape({
		missingData: PropTypes.bool.isRequired,
		dataSaved: PropTypes.bool.isRequired,
		isDirty: PropTypes.bool.isRequired,
		showSaveChanges: PropTypes.bool.isRequired,
		isPendingSave: PropTypes.bool.isRequired,
		targetTab: PropTypes.number.isRequired,
	}).isRequired,
	updateFlags: PropTypes.func.isRequired,
	setSaveHandler: PropTypes.func.isRequired,
	externalId: PropTypes.string.isRequired,
};

export default withTranslation()(withStyles(styles)(AssignmentPreferences));
