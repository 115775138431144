import React, { Component } from 'react';
import Navbar from '../HeaderComponents/Navbar/Navbar';
import WithGracefulUnmount from '../GlobalComponents/WithGracefulUnmount/WithGracefulUnmount';
import Footer from '../FooterComponents/Footer/Footer';
import AppRouter from '../../router/AppRouter';
import Banner from '../HeaderComponents/Banner/Banner';
import PageContext from '../../contexts/pageContext';
import { Offline } from 'react-detect-offline';
import { withStyles, Dialog, DialogTitle, DialogContent, Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import WithContexts from '../../contexts/withContexts';
import { COLORS } from '../../utils/Application_Constants';
import {
	getNotificationsByReceiptId,
} from '../../services/NotificationsService';

const styles = (theme) => ({
	root: {
		padding: '80px 0',
		minHeight: '800px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '60px',
			marginTop: '25px',
		},
		backgroundColor: COLORS.BLACK_HAZE,
	},
	progressButton: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		whiteSpace: 'nowrap',
	},
});

class Layout extends Component {
	constructor(props, context) {
		super(props);
		const { externalId } = this.props.UserContext;
		this.state = {
			pageName: '',
			openOfflineDialog: true,
			refreshNotifications: []
		};
		context.setPageName = this.setPageName;
	}

	async componentDidMount() {
		const { externalId } = this.props.UserContext;
		if (externalId !== null && externalId !== undefined) {
			const notifications = await getNotificationsByReceiptId(externalId);
			this.setState({ refreshNotifications: notifications});	
		}
	}

	setPageName = (pageName) => {
		this.setState({ pageName: pageName });
	};

	setRefreshNotifications = async () => {
		const { externalId } = this.props.UserContext;
		if (externalId !== null && externalId !== undefined) {
			const notifications = await getNotificationsByReceiptId(externalId);
			this.setState({ refreshNotifications: notifications});	
		}
	};

	toggleOfflineDialog = (view) => {
		this.setState({ openOfflineDialog: view });
	};

	renderOfflineContent = () => {
		const { openOfflineDialog } = this.state;
		return (
			<Dialog
				open={openOfflineDialog}
				scroll='body'
				maxWidth={'md'}
				fullWidth
				onClose={() => this.toggleOfflineDialog(false)}
			>
				<DialogTitle style={{ padding: '8px 16px' }}>
					<Grid item xs={12}>
						<h1>Offline Notification Message</h1>
					</Grid>
				</DialogTitle>
				<DialogContent style={{ padding: '8px 16px' }}>
					<h3>You are offline, Please check your internet connection</h3>
				</DialogContent>
			</Dialog>
		);
	};

	render() {
		return (
			<>
				<WithGracefulUnmount>
					<Navbar refreshNotifications={this.state.refreshNotifications} />
					<Banner pageName={this.state.pageName} />
					<AppRouter setRefreshNotifications={this.setRefreshNotifications} />
					<Footer />
					<Offline polling={{ url: 'https://ipv4.icanhazip.com' }}>
						{this.renderOfflineContent()}
					</Offline>
				</WithGracefulUnmount>
			</>
		);
	}
}

Layout.contextType = PageContext;
//export default Layout;

export default WithContexts(withTranslation()(withStyles(styles)(Layout)));
