import { useTheme } from "@material-ui/styles";
import { COLORS } from "../../../utils/Application_Constants";

function MileageEntryStylesV2() {
    const theme = useTheme();

    return {
        entryRow: {
            [theme.breakpoints.down('sm')]: {
                backgroundColor: COLORS.LT_MIDNIGHT10,
                padding: '1.5rem',
                borderRadius: '.75rem',
            },
        },
        entryCell: {
            [theme.breakpoints.down('sm')]: {
                marginBottom: '12px',
            },
            '&:first-child': {
                '& .MuiSelect-outlined': {
                    borderTopLeftRadius: '8px',
                    [theme.breakpoints.up('md')]: {
                        borderBottomLeftRadius: '8px',
                    },
                    [theme.breakpoints.down('md')]: {
                        borderRight: 'none',
                    },
                },
            },
            '&:nth-child(2)': {
                '& .MuiSelect-outlined': {
                    [theme.breakpoints.down('sm')]: {
                        borderRight: 'none',
                    },
                },
            },
        },
        entryMenu: {
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            }
        },
        menuButtonCell: {
            border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
            padding: '2px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            overflow: 'hidden',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                border: 'unset',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
            background: COLORS.LT_JOURNEY10,
        },
        disabledBackgroundColor: {
            background: COLORS.LT_MIDNIGHTBG2,
        },
        formControl: {
            marginBottom: '0',
            [theme.breakpoints.up('md')]: {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    borderRadius: '8px',
                },
            },
        },
        formControlOrange: {
			marginBottom: '0',
            [theme.breakpoints.down('md')]: {
                '& .MuiOutlinedInput-multiline': {
                    '& fieldset': {
                        borderColor: `${COLORS.LT_WARNING} !important`,
                    },
                },
                '& .MuiOutlinedInput-inputMultiline': {
                    borderColor: `${COLORS.LT_WARNING} !important`,
                    borderRadius: '8px',
                },
            },
            [theme.breakpoints.up('md')]: {
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: `${COLORS.LT_WARNING} !important`,
                    },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: COLORS.LT_WARNING,
                    borderRadius: '8px',
                },
            },
		},
        menuButton: {
            color: COLORS.TRUE_BLUE,
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            }
        },
        menuIcon: {
            color: COLORS.LT_MIDNIGHT25,
            display: 'flex',
            alignItems: 'center',
        },
        menuIconRemove: {
            color: COLORS.LT_PEPPER,
        },
        infoIcon: {
            color: COLORS.LT_MIDNIGHT25,
            height: '12px',
        },
        expandMore: {
            color: COLORS.LT_MIDNIGHT25,
            height: '20px',
            right: '8px',
            top: 'calc(50% - 10px)',
        },
        input: {
            padding: '16px !important',
            height: 'auto',
            fontSize: '14px',
            lineHeight: '20px',
            border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
            [theme.breakpoints.up('md')]: {
                borderRight: 'none',
                '&::placeholder': {
                    opacity: 0,
                },
            },
            [theme.breakpoints.down('sm')]: {
                borderBottom: 'none',
            },
            '&$disabled': {
                background: COLORS.LT_MIDNIGHTBG2,
            },
        },
        inputRoot: {
            '&$disabled $notchedOutline': {
                borderColor: 'transparent',
            },

            '&$disabled $input': {
                background: COLORS.LT_MIDNIGHTBG2,
                color: COLORS.LT_MIDNIGHT33,
            },
        },
        selectOutlined: {
            padding: '16px 24px 16px 16px !important',
            fontSize: '14px',
            lineHeight: '20px',
            borderRadius: '0px',
            borderColor: COLORS.LT_MIDNIGHT5,
            border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
            [theme.breakpoints.up('md')]: {
                borderRight: 'none',
                '& .select-placeholder': {
                    opacity: 0,
                },
            },
            '& .select-placeholder': {
                color: COLORS.LT_MIDNIGHT33,
            },
            '&$disabled': {
                background: COLORS.LT_MIDNIGHTBG2,
                color: COLORS.LT_MIDNIGHT33,
            },
        },
        disabled: {},
        notchedOutline: {},
        commentsInput: {
            flexGrow: '1',
            '& .MuiOutlinedInput-input': {
                [theme.breakpoints.up('md')]: {
                    borderBottom: `1px solid ${COLORS.LT_MIDNIGHT5}`,
                },
            },
            [theme.breakpoints.down('xs')]: {
                marginBottom: '0px',
                width: '100%',
            },
        },
        mobileMenuButton: {
            display: 'none',
        },
        mileageMobileHeader: {
            [theme.breakpoints.up('md')]: {
                display: 'none'
            },
            [theme.breakpoints.down('sm')]: {
                color: COLORS.LT_JOURNEY,
            },
        },
        mileageActionButton: {
            width: '100%',
            background: COLORS.LT_JOURNEY,
            color: 'white',
            marginTop: '16px',
        },
        actionRemoveButton: {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        mileageDeleteButton: {
            width: '100%',
            background: COLORS.LT_PEPPER,
            color: 'white',
            marginTop: '16px',
        },
        inputLabel: {
            [theme.breakpoints.up('md')]: {
                display: 'none'
            },
            [theme.breakpoints.down('sm')]: {
                color: COLORS.RAVEN,
            },
        }
    };
}

export default MileageEntryStylesV2;
