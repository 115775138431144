import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { CircularProgress } from '@material-ui/core';
import CheckBoxControl from '../../SideNavigationControl/CheckBoxControl';
import Grid from '@material-ui/core/Grid';
import WithContexts from '../../../../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import {
	COLORS,
	DOCUMENT_TYPE,
	DOCUMENT_FIELD_NAME,
	ACTIVE_PATHNAME_AP,
} from '../../../../../utils/Application_Constants';
import UploadClinicianDocument from './UploadClinicianDocument';
import UploadClinicianDocumentV2 from './UploadClinicianDocumentV2';
import ClinicianDocumentTableV2 from './ClinicianDocumentTableV2';
import { getDocumentsForTypes } from '../../../../../services/DocumentService';
import { getNestedProperty } from '../../../../../utils/helpers';
import { commonOtherDocTypes, apOtherDocTypes, mdOtherDocTypes } from './DocumentsOptions';
import {
	documentLibraryStatusCheck,
	documentDateValidation,
} from '../../../../../utils/CredentialingAppUtility';
import FeatureFlag from '../../../../GlobalComponents/FeatureFlagComponent/FeatureFlag';

const styles = makeStyles((theme) => ({
	outerContainer: {},
	innerContainer: {
		margin: '30px',
	},
	sectionCheck: {
		paddingTop: '0px',
	},
	sectionLabel: {
		textAlign: 'left',
		paddingTop: '10px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '10px',
		},
	},
	sectionButtons: {
		textAlign: 'right',
		paddingTop: '10px',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left',
		},
	},
	sectionButton: {
		color: COLORS.TRUE_BLUE,
		cursor: 'pointer',
	},
	sectionMaxReached: {
		color: COLORS.GRAY_SUIT,
	},
	section: {
		marginTop: '10px',
		borderTop: 'solid 1px #e7e7e7',
		textAlign: 'left',
	},
	centerText: {
		textAlign: 'center',
	},

	deleteButton: {
		width: '24px',
		cursor: 'pointer',
		alignContent: 'center',
	},
	header: {
		color: COLORS.BLACK_MARLIN,
		textAlign: 'left',
		paddingLeft: '10px',
		paddingBottom: '22px',
		fontSize: '24px',
		fontWeight: '700',
	},
	nestedContainer: {
		[theme.breakpoints.up('md')]: {
			marginTop: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '5px',
		},
	},
	secondaryHeader: {
		color: COLORS.TRUE_BLUE,
		fontSize: '18px',
		textAlign: 'left',
		fontWeight: '800',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	progressWrapper: {
		width: '100%',
		textAlign: 'center',
		padding: '15px 0',
	},
	docLibCheckStyle: {
		pointer: 'none',
	},
	infoText: {
		color: COLORS.ST_TROPAZ,
		'&.Mui-focused': {
			color: COLORS.ST_TROPAZ,
		},

		fontWeight: '700',
		fontSize: '14px',
		whiteSpace: 'wrap',
		textAlign: 'left',
	},
	sectionWarning: {
		textAlign: 'left',
		paddingTop: '0px',
		paddingLeft: '60px',
		color: COLORS.MILANO_RED,
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0px',
			marginLeft: '35px',
		},
	},
}));

function DocumentsForm({
	t,
	UserContext,
	setSaveHandler,
	shouldHighlightFields,
	updateHighlightFieldsState,
	activePath,
	credState,
	sectionCompletion,
	updateSectionCompletion,
}) {
	const [documents, setDocuments] = useState({});
	const [loadedRequired, setLoadedRequired] = useState(false);
	const [loadedAdditional, setLoadedAdditional] = useState(false);
	const [uploadToShow, setUploadToShow] = useState(null);
	const [missingFields, setMissingFields] = useState([]);
	const [localShouldHighlightFields, setLocalShouldHighlightFields] = useState(false);

	const defaultAddButtons = ['+ Add Document'];
	const documentSections = [
		{
			id: '1',
			title: 'Current CV (in Month/Year format)',
			docTypes: [DOCUMENT_TYPE.CurriculumVitae],
			buttons: ['+ Add CV'],
			maxCount: 3,
		},
		{
			id: '2',
			title: 'CME or Life Support Certifications for the past two years',
			docTypes: [DOCUMENT_TYPE.ContinuingEducation, DOCUMENT_TYPE.LifeSupportCerts],
			buttons: ['+ Add CME', '+ Add Life Support Certificate'],
		},
		{
			id: '3',
			title: 'Color copy of your current Driver’s License or Passport',
			docTypes: [DOCUMENT_TYPE.PhotoId],
			buttons: ["+ Add Driver's License", '+ Add Passport'],
		},
		{
			id: '4',
			title: 'Current color headshot photo',
			docTypes: [DOCUMENT_TYPE.Photo],
			buttons: ['+ Add Color Headshot'],
		},
		// Other Docs
		{
			id: '5',
			title: 'Medical School Diploma and Training Certificate (if applicable) or Non Medical School Diploma',
			docTypes: [DOCUMENT_TYPE.Medical, DOCUMENT_TYPE.NonMedical],
			buttons: ['+ Add Medical School Diploma', '+ Add Non Medical School Diploma'],
			optional: true,
		},
		{
			id: '6',
			title: 'All Current State Medical License(s)<br/>(Wallet card with expiration date and number, if not available, the wall certificate)',
			docTypes: [DOCUMENT_TYPE.StateLicense],
			optional: true,
		},
		{
			id: '7',
			title: 'Letter of Qualification (LOQ)',
			docTypes: [DOCUMENT_TYPE.LOQ],
			optional: true,
		},
		{
			id: '8',
			title: 'State Controlled Substance Registrations (if applicable)',
			docTypes: [DOCUMENT_TYPE.CSR],
			optional: true,
		},
		{
			id: '9',
			title: 'Federal DEA Certificate(s) (if applicable)',
			docTypes: [DOCUMENT_TYPE.DeaRegistration],
			optional: true,
		},
		{
			id: '10',
			title: 'Case Logs for last 24 months (if applicable)',
			docTypes: [DOCUMENT_TYPE.CaseLogsNonMammos],
			optional: true,
		},
		{
			id: '11',
			title: 'Permanent Residence Card or VISA (non-US citizen)',
			docTypes: [DOCUMENT_TYPE.Visa],
			optional: true,
		},
		{
			id: '13',
			title: 'Current Immunization Records and Recent Test Results (i.e. TB, MMR, etc.)',
			docTypes: [DOCUMENT_TYPE.Health],
			optional: true,
		},
		{
			id: '14',
			title: 'NPI Number Confirmation Letter',
			docTypes: [DOCUMENT_TYPE.NPI],
			optional: true,
		},
		{
			id: '15',
			title: 'PDMP Confirmation (if applicable)',
			docTypes: [DOCUMENT_TYPE.PrescriptionMonitoring],
			optional: true,
		},
		{
			id: '16',
			title: 'DD-214 (Military Service Discharge) (if applicable)',
			docTypes: [DOCUMENT_TYPE.DD214],
			optional: true,
		},
	];

	const loadDocs = async (externalId, sections) => {
		let docTypes = [];
		sections.forEach((s) => (docTypes = docTypes.concat(s.docTypes)));
		const docList = {};
		await Promise.all([getDocumentsForTypes(externalId, docTypes)]).then(([docs]) => {
			if (!!docs) {
				docs.forEach((d) => {
					const docType = getNestedProperty(d, DOCUMENT_FIELD_NAME.DocumentType);
					if (!docList[docType]) docList[docType] = [d];
					else docList[docType].push(d);
				});
			}
		});
		return docList;
	};

	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;
			setSaveHandler(this, 'Documents', null);

			// Load required docs
			const reqDocs = await loadDocs(
				externalId,
				documentSections.filter((s) => !s.optional)
			);
			setDocuments(reqDocs);
			if (shouldHighlightFields) {
				let status = await documentLibraryStatusCheck(externalId);
				if (!!status.missingFields) {
					setMissingFields([...status.missingFields]);
					setLocalShouldHighlightFields(true);
				}
			}
			setLoadedRequired(true);

			// Load optional docs
			const addDocs = await loadDocs(
				externalId,
				documentSections.filter((s) => !!s.optional)
			);
			const newDocs = { ...reqDocs, ...addDocs };
			setDocuments(newDocs);
			setLoadedAdditional(true);
		})();
	}, [UserContext]);

	const calcOtherDocTypes = () => {
		var calcOtherDocTypes = commonOtherDocTypes.slice();

		if (activePath === ACTIVE_PATHNAME_AP) {
			calcOtherDocTypes = calcOtherDocTypes.concat(apOtherDocTypes.slice());
		} else {
			calcOtherDocTypes = calcOtherDocTypes.concat(mdOtherDocTypes.slice());
		}

		return calcOtherDocTypes.sort((a, b) => (a.label > b.label ? 1 : -1));
	};

	/**
	 * @description UseFieldArray is a custom hook to help with creating dynamic form actions like
	 *  append, remove, delete, insert ect...
	 */

	const classes = styles();

	const uploadDoc = async (docType) => {
		if (uploadToShow === docType) setUploadToShow(null);
		else {
			await setUploadToShow(null);
			await setUploadToShow(docType);
		}
	};

	const reloadSectionForDocType = async (docType) => {
		const { externalId } = UserContext;
		await Promise.all([getDocumentsForTypes(externalId, [docType])]).then(([docs]) => {
			if (!!docs) {
				documents[docType] = docs;
				setDocuments(documents);

				setUploadToShow(docType);
				uploadStatus(true, docs);
				setUploadToShow(null);
			}
		});
	};

	const translateButtonText = (bText, docType) => {
		// If currently selected, replace leading + with -
		if (uploadToShow === docType) return '-' + bText.substring(1);
		return bText;
	};

	const renderAddButtons = (section) => {
		// See if we have a limit on how many docs can go here
		if (!!section.maxCount) {
			let docList = [];
			section.docTypes.forEach((t) => (docList = docList.concat(documents[t] ?? [])));
			if (docList.length >= section.maxCount) {
				return (
					<span type='button' className={classes.sectionMaxReached}>
						{t('credentials:DOCUMENTS.REACHED_DOC_LIMIT')}
					</span>
				);
			}
		}
		const btns = section.buttons ?? defaultAddButtons;
		return btns.map((bText, index) => (
			<>
				<span
					type='button'
					className={classes.sectionButton}
					onClick={() => uploadDoc(section.docTypes[index] ?? section.docTypes[0])}
				>
					{translateButtonText(bText, section.docTypes[index] ?? section.docTypes[0])}
				</span>
				{index === 0 && btns.length > 0 ? <br /> : ''}
			</>
		));
	};

	const renderCheckbox = (section) => {
		// See if we have a limit on how many docs can go here
		let docList = [];
		section.docTypes.forEach((t) => (docList = docList.concat(documents[t] ?? [])));

		let validDocList = [];
		for (const x of section.docTypes) {
			if (documents[x]) {
				validDocList = validDocList.concat(
					documents[x].filter(function (val) {
						const dateModified = getNestedProperty(val, DOCUMENT_FIELD_NAME.Modified);
						return documentDateValidation(dateModified);
					})
				);
			}
		}

		if (
			section.title === `Current CV (in Month/Year format)` ||
			section.title === `CME or Life Support Certifications for the past two years` ||
			section.title === `Color copy of your current Driver’s License or Passport` ||
			section.title === `Current color headshot photo`
		) {
			return (
				<CheckBoxControl
					checked={
						validDocList.length > 0 ||
						(localShouldHighlightFields && missingFields.includes(section.title))
					}
					missing={localShouldHighlightFields && missingFields.includes(section.title)}
				/>
			);
		} else {
			return (
				<CheckBoxControl
					checked={
						docList.length > 0 ||
						(localShouldHighlightFields && missingFields.includes(section.title))
					}
					missing={localShouldHighlightFields && missingFields.includes(section.title)}
				/>
			);
		}
	};

	const deleteHandle = async (sectionTitle, documentCount) => {
		if (localShouldHighlightFields && documentCount === 1) {
			setMissingFields((missingFields) => [...missingFields, sectionTitle]);
			//console.log(`Manually set error: ${missingFields}`)
		}
		return null;
	};
	const uploadStatus = async (success, newDocList) => {
		if (!!uploadToShow) {
			if (success) {
				documents[uploadToShow] = newDocList;
				setDocuments(documents);
				if (localShouldHighlightFields) {
					let status = await documentLibraryStatusCheck(UserContext.externalId);
					if (!!status.missingFields) {
						//console.log(`Manually set error: ${status.missingFields}`)
						setMissingFields([...status.missingFields]);
					}
				}
			}
			setUploadToShow(null);
		}
	};

	const renderUploadControls = (docTypes) => {
		let docType = null;
		for (let idx = 0; idx < docTypes.length; ++idx) {
			const dt = docTypes[idx];
			if (dt === uploadToShow) {
				docType = docTypes[idx];
				break;
			}
		}
		if (!docType) return null;
		return (
			<>
				<FeatureFlag name='EnableHasSharepointDocs' isFlagEnabled='true'>
					<UploadClinicianDocumentV2
						documentType={docType}
						headerText={''}
						showDocuments={false}
						showClose={true}
						statusCallback={uploadStatus}
						sectionCompletion={sectionCompletion}
						updateSectionCompletion={updateSectionCompletion}
					/>
				</FeatureFlag>
				<FeatureFlag name='EnableHasSharepointDocs' isFlagEnabled='False'>
					<UploadClinicianDocument
						documentType={docType}
						headerText={''}
						showDocuments={false}
						showClose={true}
						statusCallback={uploadStatus}
						sectionCompletion={sectionCompletion}
						updateSectionCompletion={updateSectionCompletion}
					/>
				</FeatureFlag>
			</>
		);
	};

	const renderWarning = (section) => {
		if (
			section.title === `Current CV (in Month/Year format)` ||
			section.title === `CME or Life Support Certifications for the past two years` ||
			section.title === `Color copy of your current Driver’s License or Passport` ||
			section.title === `Current color headshot photo`
		) {
			if (missingFields.includes(section.title)) {
				return (
					<Grid xs={12} className={classes.sectionWarning}>
						{t('credentials:DOCUMENTS.REQUIRED_DOC_WARNING')}
					</Grid>
				);
			}
		}
	};

	const renderSection = (section) => {
		// Collect all docs for this section
		let docList = [];
		section.docTypes.forEach((t) => (docList = docList.concat(documents[t] ?? [])));

		return (
			<Grid xs={12} key={section.title} className={classes.section}>
				<Grid xs={12} container direction='row'>
					<Grid xs={12} md={9}>
						<Grid container direction='row'>
							<Grid xs={1} className={classes.docLibCheckStyle}>
								{renderCheckbox(section)}
							</Grid>
							<Grid
								xs={11}
								className={classes.sectionLabel}
								dangerouslySetInnerHTML={{ __html: section.title }}
							></Grid>
							{renderWarning(section)}
						</Grid>
					</Grid>
					<Grid xs={12} md={3} className={classes.sectionButtons}>
						{renderAddButtons(section)}
					</Grid>
				</Grid>
				<Grid xs={12}>{renderUploadControls(section.docTypes)}</Grid>

				<ClinicianDocumentTableV2
					key={section.title + docList.length.toString()}
					documents={docList}
					reloadDocuments={async (docType) => reloadSectionForDocType(docType)}
					deleteHandle={deleteHandle}
					sectionTitle={section.title}
					credState={credState}
					sectionCompletion={sectionCompletion}
					updateSectionCompletion={updateSectionCompletion}
				/>
			</Grid>
		);
	};

	const renderSections = (sections, isLoaded) => {
		if (isLoaded !== true) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress />
				</div>
			);
		}
		return sections.map((k) => renderSection(k));
	};

	return (
		<>
			<CssBaseline />
			<Grid className={classes.outerContainer}>
				<Grid item xs={12}>
					<h1 className={classes.header}>{t('credentials:DOCUMENTS.TITLE')}</h1>
				</Grid>
				<Grid item className={classes.innerContainer} xs={12}>
					<h2 className={'credAppAdditionalHeaderTitle'}>
						{t('credentials:DOCUMENTS.REQUIRED_TITLE')}
					</h2>
					<h3 className={classes.infoText}>
						{t('credentials:DOCUMENTS.REQUIRED_DOC_INFO')}
					</h3>
					{renderSections(
						documentSections.filter((s) => !s.optional),
						loadedRequired
					)}
				</Grid>
				<Grid item className={classes.innerContainer} xs={12}>
					<h2 className={'credAppAdditionalHeaderTitle'}>
						{t('credentials:DOCUMENTS.ADDITIONAL_TITLE')}
					</h2>
					<h3 className={classes.infoText}>
						{t('credentials:DOCUMENTS.ADDITIONAL_DOC_INFO')}
					</h3>
					{renderSections(
						documentSections.filter((s) => !!s.optional),
						loadedAdditional
					)}
				</Grid>
				<Grid item className={classes.innerContainer} xs={12}>
					<h2 className={classes.secondaryHeader}>
						{t('credentials:DOCUMENTS.OTHER_TITLE')}
					</h2>
					<Grid item xs={12}>
						<FeatureFlag name='EnableHasSharepointDocs' isFlagEnabled='true'>
							<UploadClinicianDocumentV2
								docTypeList={calcOtherDocTypes()}
								credState={credState}
								sectionCompletion={sectionCompletion}
								updateSectionCompletion={updateSectionCompletion}
							/>
						</FeatureFlag>
						<FeatureFlag name='EnableHasSharepointDocs' isFlagEnabled='false'>
							<UploadClinicianDocument
								docTypeList={calcOtherDocTypes()}
								credState={credState}
								sectionCompletion={sectionCompletion}
								updateSectionCompletion={updateSectionCompletion}
							/>
						</FeatureFlag>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

export default WithContexts(withTranslation()(DocumentsForm));
