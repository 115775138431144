/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
	Button,
	Checkbox,
	CircularProgress,
	Container,
	FormControlLabel,
	FormGroup,
	Grid,
	Menu,
	MenuItem,
	Paper,
	Popover,
	Snackbar,
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import { COLORS } from '../../utils/Application_Constants';
import { withTranslation } from 'react-i18next';
import { config as ClientTableConfig } from '../../Client/Configurations/TimesheetReviewConfig';
import { config as AdminTableConfig } from '../../Admin/Configurations/TimesheetReviewConfig';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router-dom';
import useQuery from '../../utils/useQuery';
import TimesheetReviewCard from '../Components/TimesheetReviewCard';
import { Alert, PaginationItem } from '@material-ui/lab';
import { getTimesheets } from '../../Shared/Services/TimesheetsService';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import TimesheetStatusEnum, {
	TimesheetStatusEnumApi,
} from '../../components/ContentComponents/Timesheets/Status.enum';
import moment from 'moment';
import { ClientAuthContext } from '../../Client/Contexts/ClientAuthContext';
import { AdminAuthContext } from '../../Admin/Contexts/AdminAuthContext';
import useDebounce from '../../utils/useDebounce';
import { formatErrorMessage } from '../Utils/ErrorMessage';

const styles = (theme) => ({
	PaperRoot: {
		padding: '1.5rem 2rem',
		display: 'flex',
		gap: '24px',
		flexDirection: 'column',
	},
	ContainerRoot: {
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '16px',
			paddingRight: '16px',
		},
		paddingTop: '2.5rem',
		paddingBottom: '5rem',
		display: 'flex',
		flexDirection: 'column',
		gap: '32px',
	},
	header: {
		fontWeight: '700',
		margin: 0,
	},
	menuPaperRoot: {
		minWidth: '250px',
		padding: '24px',
		maxHeight: '375px',
	},
	menuList: {
		paddingTop: 0,
		paddingBottom: 0,
		display: 'flex',
		flexDirection: 'column',
		gap: '16px',
	},
	filterButton: {
		borderRadius: '20px',
		border: `1px solid ${COLORS.LT_MIDNIGHT25}`,
		background: 'white',
		color: COLORS.LT_MIDNIGHT75,
		'&:hover': {
			background: COLORS.LT_JOURNEY10,
			color: COLORS.LT_JOURNEY,
			borderColor: COLORS.LT_JOURNEY,
		},
	},
	filterButtonDisabled: {
		borderColor: 'transparent',
	},
	filtersActive: {
		background: COLORS.LT_JOURNEY10,
		color: COLORS.LT_JOURNEY,
		borderColor: COLORS.LT_JOURNEY,
	},
	dateRangeFilter: {
		minWidth: '250px',
		padding: '24px',
		display: 'flex',
		gap: '16px',
		flexWrap: 'wrap',
	},
	selectedPage: {
		backgroundColor: COLORS.LT_JOURNEY,
		padding: '6px 16px',
		color: COLORS.WHITE,
		'&:hover': {
			backgroundColor: COLORS.LT_JOURNEY75,
		},
	},
	pageButton: {
		color: COLORS.LT_MIDNIGHT75,
		background: 'white',
		padding: '6px 16px',
	},
	outlinedPageButton: {
		color: COLORS.LT_MIDNIGHT75,
		background: 'white',
	},
	paginationNumber: {
		color: COLORS.LT_MIDNIGHT50,
		fontWeight: '600',
		fontSize: '14px',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '15px',
		},
	},
	formControl: {
		marginBottom: '0',
		minWidth: '215px',
		'& .MuiOutlinedInput-notchedOutline': {
			borderRadius: '6px',
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
		},
		'& $disabled': {
			borderRadius: '6px',
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
			background: COLORS.LT_MIDNIGHTBG2,
			color: COLORS.LT_MIDNIGHT33,
		},
		borderRadius: '6px',
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
	},

	statusFormGroup: {
		display: 'block',
	},
	inputRoot: {
		'&$disabled $notchedOutline': {
			borderColor: 'transparent',
		},

		'&$disabled $input': {
			background: COLORS.LT_MIDNIGHTBG2,
			color: COLORS.LT_MIDNIGHT33,
		},
	},
	disabled: {},
	notchedOutline: {},
	input: {
		border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
		borderRadius: '6px',
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		padding: '8px 16px !important',
		height: 'auto',
		fontSize: '14px',
		lineHeight: '20px',
		'&$disabled': {
			background: COLORS.LT_MIDNIGHTBG2,
		},
	},
	checkboxLabel: {
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '20px',
	},
	searchKeyButton: {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		height: '37px',
		minWidth: '130px',
		justifyContent: 'space-between',
	},
	searchHelperText: {
		position: 'absolute',
		bottom: '-24px',
		marginLeft: 0,
	},
});

const Timesheets = (props) => {
	// Config
	const isClientApp = process.env.REACT_APP_TYPE === 'Client';
	const isAdminApp = process.env.REACT_APP_TYPE === 'Admin';
	const config = isClientApp ? ClientTableConfig : isAdminApp ? AdminTableConfig : null;
	const hasStatusFilter = config.filters.includes('Status');
	const hasDivisionsFilter = config.filters.includes('Divisions');
	const hasWeekEndingFilter = config.filters.includes('Week Ending');
	const hasSearch = config.hasSearch;
	const clientAuth = useContext(ClientAuthContext);
	const adminAuth = useContext(AdminAuthContext);

	const { classes } = props;
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(true);
	const [isSorting, setIsSorting] = useState(false);
	const [weeks, setWeeks] = useState([]);
	const query = useQuery();
	const [limit, setLimit] = useState(50);
	const [page, setPage] = useState(query.get('page') ? Number(query.get('page')) : (sessionStorage.getItem('page') ? Number(sessionStorage.getItem('page')) : 1));
	const [pages, setPages] = useState(0);
	const getOffset = page === 1 ? 0 : (page - 1) * limit;
	const [offset, setOffset] = useState(getOffset);
	const criteriaString = isAdminApp ? localStorage.getItem('criteria') : sessionStorage.getItem('criteria');
	const criteria = criteriaString ? JSON.parse(criteriaString) : null;
	const [sortBy, setSortBy] = useState(criteria ? criteria.sortBy : 'clinicianLastName');
	const [sortDirection, setSortDirection] = useState(criteria ? criteria.sortDirection : 'asc');
	const [anchorEl, setAnchorEl] = useState(null);
	const defaultStatuses = isClientApp
		? [TimesheetStatusEnumApi.Submitted]
		: [];
	const [statuses, setStatuses] = useState(criteria ? criteria.statuses : defaultStatuses);
	const [divisions, setDivisions] = useState(criteria ? criteria.divisions : []);
	const [displayStatuses, setDisplayStatuses] = useState(statuses);
	const [displayDivisions, setDisplayDivisions] = useState(divisions);
	const [alert, setAlert] = useState();
	var prevWeekStart = new Date();
	prevWeekStart.setDate(prevWeekStart.getDate() - ((prevWeekStart.getDay() + 6) % 7) - 7);
	var prevWeekEnd = new Date(prevWeekStart);
	prevWeekEnd.setDate(prevWeekStart.getDate() + 6);
	const format = 'YYYY-MM-DD';
	const defaultStartDateAdmin = moment(prevWeekStart).format(format);
	const defaultEndDateAdmin = moment(prevWeekEnd).format(format);
	const defaultStartDateClient = null;
	const defaultEndDateClient = defaultStartDateClient;
	const defaultStartDate = isClientApp
		? defaultStartDateClient
		: isAdminApp
		? defaultStartDateAdmin
		: null;
	const defaultEndDate = isClientApp
		? defaultEndDateClient
		: isAdminApp
		? defaultEndDateAdmin
		: null;
	const [selectedStartDate, setSelectedStartDate] = useState(
		criteria ? criteria.selectedStartDate : defaultStartDate
	);
	const [displayStartDate, setDisplayStartDate] = useState(selectedStartDate);
	const [selectedEndDate, setSelectedEndDate] = useState(
		criteria ? criteria.selectedEndDate : defaultEndDate
	);
	const [displayEndDate, setDisplayEndDate] = useState(selectedEndDate);
	const [user, setUser] = useState(null);
	const [search, setSearch] = useState(criteria ? criteria.search : '');
	const [debouncedSearch, setDebouncedSearch] = useState(search);

	const [searchKey, setSearchKey] = useState(
		!isAdminApp
			? null
			: criteria && criteria.searchKey
			? criteria.searchKey
			: config.searchKeys[0]
	);
	const searchRef = useRef(null);

	useEffect(() => {
		history.replace(`/timesheets?page=${page}`);
		sessionStorage.setItem('page',Number(page));
		getData();
	}, []);

	useEffect(() => {
		getUserInfo();
	}, [adminAuth, clientAuth]);

	useEffect(() => {
		history.replace(`/timesheets?page=${page}`);
		sessionStorage.setItem('page',Number(page));
	}, [page]);

	const debouncedRequest = useDebounce(() => {
		if (search.length >= 3 && search.length <= 200) {
			setDebouncedSearch(search);
			handleSearch(search);
		} else if (debouncedSearch !== '') {
			setDebouncedSearch('');
			handleSearch('');
		}
	});

	const getUserInfo = async () => {
		if (adminAuth?.adminUser) {
			setUser(adminAuth.adminUser);
		} else {
			setUser(clientAuth?.clientUser);
		}
	};

	const getData = async () => {
		setIsLoading(true);
		const request = {
			offset,
			sortBy,
			sortDirection,
			limit,
			statuses,
			divisions,
			selectedStartDate,
			selectedEndDate,
			search,
			searchKey,
		};
		await fetchData(request);
	};

	const fetchData = async (request) => {
		isAdminApp ? localStorage.setItem('criteria', JSON.stringify(request)) : sessionStorage.setItem('criteria', JSON.stringify(request));
		try {
			var response = await getTimesheets(request);
			setWeeks(response.data);
			var aLastPage = Math.ceil(response.totalCount == 0 ? 0 :response.totalCount / limit);
			setPages(aLastPage);
			//If for some reason they come back into the page and that page is no longer
			//available the user will be sent back to the Last Page
			//Example user was on page 4 and only 1 item existed...they submitted it but submitted
			//is not in their status filter so now only 3 pages exist.  Instead of being presented a 
			//blank page 4 page it will send to the Last Page (3).
			if (page > aLastPage && page != 1) {
				var aPage = (aLastPage == 0 ? 1 : aLastPage);
				setPage(Number(aPage));
				var aGetOffset = (aPage === 1 ? 0 : (aPage - 1) * limit);
				setOffset(aGetOffset);
				request.offset = aGetOffset;
				response = await getTimesheets(request);
				setWeeks(response.data);
				aLastPage = Math.ceil(response.totalCount == 0 ? 0 :response.totalCount / limit);
				setPages(aLastPage);
			}
			setIsSorting(false);
		} catch (error) {
			const message = formatErrorMessage(error);
			setAlert({
				message,
				type: 'error',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		const sessionString = isAdminApp ? localStorage.getItem('criteria') : sessionStorage.getItem('criteria');
		const session = JSON.parse(sessionString);
		if (session) {
			const sessionDivisions = session ? session.divisions : [];
			const sessionStatuses = session ? session.statuses : defaultStatuses;
			const sessionStartDate = session ? session.selectedStartDate : defaultStartDate;
			const sessionEndDate = session ? session.selectedEndDate : defaultEndDate;
			setDisplayStatuses(sessionStatuses);
			setDisplayDivisions(sessionDivisions);
			setDisplayStartDate(sessionStartDate);
			setDisplayEndDate(sessionEndDate);
		} else {
			setDisplayStatuses(statuses);
			setDisplayDivisions(divisions);
			setDisplayStartDate(selectedStartDate);
			setDisplayEndDate(selectedEndDate);
		}
	};

	const handleAlertClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlert(null);
	};

	const handleCheckboxChange = (targetState, targetItem, isChecked) => {
		const newState = isChecked
			? targetState.filter((item) => item !== targetItem)
			: [...targetState, targetItem];

		if (targetState === displayStatuses) {
			setDisplayStatuses(newState);
		} else if (targetState === displayDivisions) {
			setDisplayDivisions(newState);
		}
	};

	const getNewSortValues = (sortKey) => {
		let newSortBy = sortBy;
		let newSortDirection = sortDirection;

		if (sortKey !== sortBy) {
			newSortBy = sortKey;
			newSortDirection = 'asc';
			setSortBy(sortKey);
			setSortDirection(newSortDirection);
		} else {
			newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
			newSortBy = newSortDirection === 'asc' && sortDirection === 'desc' ? null : sortKey;
			if (newSortBy !== sortKey) setSortBy(newSortBy);
			setSortDirection(newSortDirection);
		}

		return { newSortBy, newSortDirection };
	};

	const handleSort = async (sortKey) => {
		setIsSorting(true);
		const { newSortBy, newSortDirection } = getNewSortValues(sortKey, sortBy, sortDirection);
		const request = {
			offset,
			sortBy: newSortBy,
			sortDirection: newSortDirection,
			limit,
			statuses,
			divisions,
			selectedStartDate,
			selectedEndDate,
			search,
			searchKey,
		};
		await fetchData(request);
	};

	const handlePageChange = async (e, newPage) => {
		setIsLoading(true);
		const newOffset = newPage === 1 ? 0 : (newPage - 1) * limit;
		setOffset(newOffset);
		setPage(newPage);
		const request = {
			offset: newOffset,
			sortBy,
			sortDirection,
			limit,
			statuses,
			divisions,
			selectedStartDate,
			selectedEndDate,
			search,
			searchKey,
		};
		await fetchData(request);
	};

	const handleFilterApply = async () => {
		setIsLoading(true);
		const newOffset = 0;
		const newPage = 1;
		setOffset(newOffset);
		setPage(newPage);
		const request = {
			offset: newOffset,
			sortBy,
			sortDirection,
			limit,
			statuses: displayStatuses,
			divisions: displayDivisions,
			selectedStartDate: displayStartDate,
			selectedEndDate: displayEndDate,
			search,
			searchKey,
		};

		setStatuses(displayStatuses);
		setDivisions(displayDivisions);
		setSelectedStartDate(displayStartDate);
		setSelectedEndDate(displayEndDate);
		await fetchData(request);
	};

	const handleSearchKey = (key) => {
		setSearchKey(key);
		handleClose();
		if (debouncedSearch !== '') handleSearch(search, key);
	};

	const handleSearch = async (query, selectedSearchKey) => {
		setIsLoading(true);
		const newOffset = 0;
		const newPage = 1;
		setOffset(newOffset);
		setPage(newPage);
		const request = {
			offset: newOffset,
			sortBy,
			sortDirection,
			limit,
			statuses,
			divisions,
			selectedStartDate,
			selectedEndDate,
			search: query,
			searchKey: selectedSearchKey ? selectedSearchKey : searchKey,
		};
		await fetchData(request);
		searchRef.current && searchRef.current.focus();
	};

	const formatWeekEndingDate = (date, set) => {
		if (!date) {
			set(null);
			return;
		}
		const newDate = moment(date).format(format);
		set(newDate);
	};

	const renderStatusFilter = () => {
		return (
			<Grid item>
				<Button
					aria-controls='status-filter'
					aria-haspopup='true'
					onClick={handleClick}
					variant='contained'
					id='status-filter'
					endIcon={<ExpandMoreIcon fontSize='small' />}
					classes={{ root: classes.filterButton, disabled: classes.filterButtonDisabled }}
					className={statuses.length > 0 && !isLoading ? classes.filtersActive : ''}
					disabled={isLoading}
				>
					Statuses {statuses.length > 0 ? `(${statuses.length})` : ''}
				</Button>
				<Menu
					id='status-filter-menu'
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl?.id === 'status-filter')}
					onClose={handleClose}
					classes={{ list: classes.menuList }}
					PaperProps={{ className: classes.menuPaperRoot }}
				>
					<form>
						<FormGroup>
							{config.availableStatuses.map((status) => {
								const isChecked = displayStatuses.includes(status);
								return (
									<FormControlLabel
										key={status}
										control={
											<Checkbox
												checked={isChecked}
												onChange={() =>
													handleCheckboxChange(
														displayStatuses,
														status,
														isChecked
													)
												}
												name={status}
												color='primary'
											/>
										}
										classes={{ label: classes.checkboxLabel }}
										label={TimesheetStatusEnum[status]}
									/>
								);
							})}
						</FormGroup>
					</form>
					<Grid container justifyContent='flex-end' spacing={1}>
						<Grid item>
							<Button
								color='primary'
								onClick={() => {
									setDisplayStatuses([]);
								}}
							>
								Clear
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant='contained'
								color='primary'
								onClick={() => {
									handleFilterApply();
									handleClose();
								}}
							>
								Apply
							</Button>
						</Grid>
					</Grid>
				</Menu>
			</Grid>
		);
	};

	const renderDivisionsFilter = () => {
		return (
			<Grid item>
				<Button
					aria-controls='divisions-filter'
					aria-haspopup='true'
					onClick={handleClick}
					variant='contained'
					id='divisions-filter'
					className={divisions.length > 0 && !isLoading ? classes.filtersActive : ''}
					endIcon={<ExpandMoreIcon fontSize='small' />}
					classes={{ root: classes.filterButton, disabled: classes.filterButtonDisabled }}
					disabled={isLoading}
				>
					Divisions {divisions.length > 0 ? `(${divisions.length})` : ''}
				</Button>
				<Menu
					id='divisions-filter-menu'
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl?.id === 'divisions-filter')}
					onClose={handleClose}
					classes={{ list: classes.menuList }}
					PaperProps={{ className: classes.menuPaperRoot }}
				>
					<form>
						<FormGroup>
							{config.availableDivisions.map((division) => {
								const isChecked = displayDivisions.includes(division);
								return (
									<FormControlLabel
										key={division}
										control={
											<Checkbox
												checked={isChecked}
												onChange={() =>
													handleCheckboxChange(
														displayDivisions,
														division,
														isChecked
													)
												}
												name={division}
												color='primary'
											/>
										}
										classes={{ label: classes.checkboxLabel }}
										label={division}
									/>
								);
							})}
						</FormGroup>
					</form>

					<Grid container justifyContent='flex-end' spacing={1}>
						<Grid item>
							<Button
								color='primary'
								onClick={() => {
									setDisplayDivisions([]);
								}}
							>
								Clear
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant='contained'
								color='primary'
								onClick={() => {
									handleFilterApply();
									handleClose();
								}}
							>
								Apply
							</Button>
						</Grid>
					</Grid>
				</Menu>
			</Grid>
		);
	};

	const renderWeekEndingFilter = () => {
		return (
			<Grid item>
				<Button
					aria-controls='week-ending-filter'
					aria-haspopup='true'
					onClick={handleClick}
					variant='contained'
					id='week-ending-filter'
					endIcon={<ExpandMoreIcon fontSize='small' />}
					classes={{ root: classes.filterButton, disabled: classes.filterButtonDisabled }}
					disabled={isLoading}
					className={
						(selectedEndDate || selectedStartDate) && !isLoading
							? classes.filtersActive
							: ''
					}
				>
					Week Ending
				</Button>
				<Popover
					id='week-ending-filter-menu'
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl?.id === 'week-ending-filter')}
					onClose={handleClose}
					PaperProps={{ className: classes.menuPaperRoot }}
				>
					{/* TODO: Disable highlighting today */}
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<Grid
							container
							direction='row'
							justifyContent='space-between'
							alignItems='center'
							spacing={2}
						>
							<Grid item md={6}>
								<KeyboardDatePicker
									disableToolbar
									variant='static'
									format='MM/dd/yyyy'
									id='start-picker-inline'
									label='Start'
									value={moment(displayStartDate)}
									minDate={
										displayStartDate
											? moment(selectedStartDate).subtract('2', 'years')
											: moment(new Date()).subtract(2, 'years')
									}
									maxDate={displayEndDate ? moment(displayEndDate) : new Date()}
									onChange={(e) => {
										formatWeekEndingDate(e, setDisplayStartDate);
									}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
								/>
							</Grid>
							<Grid item md={6}>
								<KeyboardDatePicker
									disableToolbar
									minDate={
										displayStartDate
											? moment(displayStartDate)
											: moment(new Date()).subtract(2, 'years')
									}
									maxDate={moment(new Date()).add(6, 'days')}
									variant='static'
									format='MM/dd/yyyy'
									id='end-picker-inline'
									label='End'
									value={moment(displayEndDate)}
									onChange={(e) => {
										formatWeekEndingDate(e, setDisplayEndDate);
									}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
								/>
							</Grid>
						</Grid>
					</MuiPickersUtilsProvider>

					<Grid container justifyContent='flex-end' spacing={1}>
						<Grid item>
							<Button
								color='primary'
								onClick={() => {
									setDisplayStartDate(null);
									setDisplayEndDate(null);
								}}
							>
								Clear
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant='contained'
								color='primary'
								onClick={() => {
									handleFilterApply();
									handleClose();
								}}
							>
								Apply
							</Button>
						</Grid>
					</Grid>
				</Popover>
			</Grid>
		);
	};

	const renderFilters = () => (
		<Grid item>
			<Grid container alignItems='center' spacing={1}>
				<Grid item>
					<Typography variant='body2'>Filters:</Typography>
				</Grid>
				{hasStatusFilter && renderStatusFilter()}
				{hasDivisionsFilter && renderDivisionsFilter()}
				{hasWeekEndingFilter && renderWeekEndingFilter()}
			</Grid>
		</Grid>
	);

	const renderSearch = () => (
		<Grid item>
			<Grid container>
				<Grid item>
					<Button
						aria-controls='search-key'
						aria-haspopup='true'
						onClick={handleClick}
						variant='contained'
						color='primary'
						id='search-key'
						endIcon={<ExpandMoreIcon fontSize='small' />}
						disabled={isLoading}
						className={classes.searchKeyButton}
					>
						{searchKey.name}
					</Button>
					<Menu
						id='search-key-menu'
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl?.id === 'search-key')}
						onClose={handleClose}
					>
						{config.searchKeys.map((key) => (
							<MenuItem
								key={key.name}
								selected={key === searchKey}
								onClick={() => handleSearchKey(key)}
							>
								{key.name}
							</MenuItem>
						))}
					</Menu>
				</Grid>
				<Grid item>
					<TextField
						disabled={isLoading}
						id='search'
						type='search'
						variant='outlined'
						defaultValue={search}
						onChange={(e) => {
							setSearch(e.target.value);
							debouncedRequest();
						}}
						className={classes.formControl}
						InputProps={{
							classes: {
								root: classes.inputRoot,
								input: classes.input,
								disabled: classes.disabled,
								notchedOutline: classes.notchedOutline,
							},
						}}
						inputRef={searchRef}
						FormHelperTextProps={{ className: classes.searchHelperText }}
						helperText={
							search && search.length < 3
								? 'Please enter 3 or more characters'
								: search && search.length > 200
								? 'Must be less than 200 characters'
								: ''
						}
						error={Boolean(search && search.length > 200)}
						placeholder='Search'
					/>
				</Grid>
			</Grid>
		</Grid>
	);

	const renderPagination = () => (
		<Grid container justifyContent='space-between' alignItems='center'>
			<Grid item>
				<div className={classes.paginationNumber}>
					{`Page ${page} of ${!weeks ? 0 : pages}`}
				</div>
			</Grid>
			<Grid item>
				{pages > 1 && (
					<Pagination
						color='primary'
						variant='outlined'
						shape='rounded'
						page={page}
						count={!weeks ? 0 : pages}
						onChange={(e, page) => handlePageChange(e, page)}
						renderItem={(item) => {
							const hidden =
								(item.type === 'previous' || item.type === 'next') && item.disabled;
							if (hidden) return;
							return (
								<PaginationItem
									{...item}
									classes={{ outlined: classes.outlinedPageButton }}
								/>
							);
						}}
					/>
				)}
			</Grid>
		</Grid>
	);

	const renderAlert = () => (
		<Snackbar
			open={Boolean(alert)}
			autoHideDuration={6000}
			onClose={handleAlertClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<Alert onClose={handleAlertClose} severity={alert?.type}>
				{alert?.message}
			</Alert>
		</Snackbar>
	);

	return (
		<Container maxWidth='xl' className={classes.ContainerRoot}>
			{renderAlert()}
			<Typography variant='h3' component='h3' className={classes.header}>
				Timesheets for Review
			</Typography>
			<Grid container alignItems='center' justifyContent='space-between' spacing={2}>
				{renderFilters()}
				{hasSearch && renderSearch()}
			</Grid>
			{isLoading && <CircularProgress color='primary' />}
			{!isLoading && weeks?.length > 0 && (
				<>
					{pages > 0 && renderPagination()}
					{weeks.map((week) => (
						<TimesheetReviewCard
							key={week.weekEnding}
							timesheets={week.timesheets}
							weekEnding={week.weekEnding}
							sortBy={sortBy}
							sortDirection={sortDirection}
							handleSort={handleSort}
							isSorting={isSorting}
							getData={getData}
							setAlert={setAlert}
							user={user}
							config={config}
						/>
					))}
					{pages > 0 && renderPagination()}
				</>
			)}
			{!isLoading && (weeks?.length === 0 || !weeks) && (
				<Paper classes={{ root: classes.PaperRoot }}>
					<Typography variant='body2'>No results found</Typography>
				</Paper>
			)}
		</Container>
	);
};

export default withTranslation()(withStyles(styles)(Timesheets));
