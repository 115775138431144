import { useTheme } from '@material-ui/styles';
import { COLORS } from '../../../utils/Application_Constants';

function TimesheetEntryStylesV1() {
	const theme = useTheme();

	return {
		entryRow: {
			[theme.breakpoints.up('md')]: {
				flexWrap: 'nowrap',
			},
			zIndex: 0,
			position: 'relative',
		},
		entryCell: {
			'&:first-child': {
				'& .MuiSelect-outlined': {
					borderTopLeftRadius: '8px',
					[theme.breakpoints.up('md')]: {
						borderBottomLeftRadius: '8px',
					},
					[theme.breakpoints.down('md')]: {
						borderRight: 'none',
					},
				},
			},
			'&:nth-child(2)': {
				'& .MuiSelect-outlined': {
					[theme.breakpoints.down('sm')]: {
						borderRight: 'none',
					},
				},
			},
			'&:nth-child(3)': {
				'& .MuiSelect-outlined': {
					[theme.breakpoints.down('sm')]: {
						borderTopRightRadius: '8px',
					},
				},
			},
			'&:nth-child(4)': {
				'& .MuiInputBase-input': {
					[theme.breakpoints.down('md')]: {
						borderRight: 'none',
					},
				},
			},
			'&:nth-child(5)': {
				'& .MuiInputBase-input': {
					[theme.breakpoints.down('sm')]: {
						borderRight: 'none',
					},
				},
			},
			'&:nth-child(7)': {
				'& .MuiInputBase-input': {
					[theme.breakpoints.down('sm')]: {
						borderBottomRightRadius: '8px',
						borderBottomLeftRadius: '8px',
						borderBottom: `1px solid ${COLORS.LT_MIDNIGHT5}`,
					},
				},
			},
		},
		menuButtonCell: {
			border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
			padding: '2px',
			borderTopRightRadius: '8px',
			borderBottomRightRadius: '8px',
			overflow: 'hidden',
			background: COLORS.LT_JOURNEY10,
		},
		copyADayMenu: {
			'& .MuiList-root': {
				padding: '0'
			},
		},
		disabledBackgroundColor: {
			background: COLORS.LT_MIDNIGHTBG2,
		},
		formControl: {
			marginBottom: '0',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
				borderRadius: '8px',
			},
		},
		formControlOrange: {
			marginBottom: '0',
			'& .MuiOutlinedInput-root': {
				'& fieldset': {
					borderColor: `${COLORS.LT_WARNING} !important`,
				},
			},
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: COLORS.LT_WARNING,
				borderRadius: '8px',
			},
		},
		menuButton: {
			color: COLORS.TRUE_BLUE,
		},
		menuIcon: {
			color: COLORS.LT_MIDNIGHT25,
			display: 'flex',
			alignItems: 'center',
		},
		menuIconRemove: {
			color: COLORS.LT_PEPPER,
		},
		menuIconCallBack: {
			color: COLORS.LT_MIDNIGHT33,
		},
		menuIconBreak: {
			color: COLORS.LT_FOUNDATION,
		},
		expandMore: {
			color: COLORS.LT_MIDNIGHT25,
			height: '20px',
			right: '8px',
			top: 'calc(50% - 10px)',
		},
		inputRoot: {
			'&$disabled $notchedOutline': {
				borderColor: 'transparent',
			},

			'&$disabled $input': {
				background: COLORS.LT_MIDNIGHTBG2,
				color: COLORS.LT_MIDNIGHT33,
			},
		},
		disabled: {},
		notchedOutline: {},
		input: {
			padding: '16px !important',
			height: 'auto',
			fontSize: '14px',
			lineHeight: '20px',
			border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
			[theme.breakpoints.up('md')]: {
				borderRight: 'none',
				'&::placeholder': {
					opacity: 0,
				},
			},
			[theme.breakpoints.down('sm')]: {
				borderBottom: 'none',
			},
			'&$disabled': {
				background: COLORS.LT_MIDNIGHTBG2,
			},
			'&[type="time"]': {
				height: '20px',
			},
		},

		selectOutlined: {
			padding: '16px 24px 16px 16px !important',
			fontSize: '14px',
			lineHeight: '20px',
			borderRadius: '0px',
			borderColor: COLORS.LT_MIDNIGHT5,
			border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
			[theme.breakpoints.up('md')]: {
				borderRight: 'none',

				'& .select-placeholder': {
					opacity: 0,
				},
			},
			'&$disabled': {
				background: COLORS.LT_MIDNIGHTBG2,
				color: COLORS.LT_MIDNIGHT33,
			},
			'& .select-placeholder': {
				color: COLORS.LT_MIDNIGHT33,
			},
			[theme.breakpoints.down('sm')]: {
				borderBottom: 'none',
			},
		},
		commentsInput: {
			flexGrow: '1',
			maxWidth: '100%',
		},
		mobileMenuButton: {
			marginBottom: '4px',
			marginLeft: 'auto',
			display: 'flex',
			justifyContent: 'flex-end',

			'& .MuiIconButton-root': {
				padding: '6px',
			},
		},
		entryContainer: {
			flexWrap: 'nowrap',
		},
		midCallBackConnector: {
			position: 'absolute',
			height: '35px',
			width: '600px',
			borderRight: '1px solid #EEF0F1',
			right: '28px',
			borderBottom: '1px solid #EEF0F1',
			bottom: '52%',
			zIndex: -1,
		},
		endCallBackConnector: {
			position: 'absolute',
			height: '6em',
			borderRight: '1px solid #EEF0F1',
			right: '28px',
			borderBottom: '1px solid #EEF0F1',
			bottom: '0%',
			zIndex: -1
		},
		midCallBackConnectorContainer: {
			height: '3.5em',
			width: '3.5em',
			position: 'relative',
		},
		connectorGrid: {
			zIndex: -1,
			backgroundColor: COLORS.WHITE,
		},
		endCallBackConnectorContainer: {
			height: '4em',
			position: 'absolute',
			right: '0%',
		},
		menuGrid: {
			backgroundColor: COLORS.WHITE,
			zIndex: 1,
		},
		mobileMenuGrid: {
			display: 'none',
		},
		endRowContainer: {
			flexWrap: 'nowrap',
		},
		shiftMobileHeader: {
			display: 'none',
		},
		inputLabel: {
			display: 'none',
		},
		callbackMobileHeader: {
			display: 'none',
		},
		callWithCallbackEntryContainer: {
			flexWrap: 'nowrap',
		},
		callbackEntryContainer: {
			flexWrap: 'nowrap',
		},
		lastCallbackEntryContainer: {
			flexWrap: 'nowrap',
		},
		shiftDeleteButton: {
			display: 'none',
		},
		callbackDeleteButton: {
            display: 'none',
		},
		shiftActionButton: {
			display: 'none',
		},
		shiftWithChildActionButton: {
			display: 'none',
		},
	};
}

export default TimesheetEntryStylesV1;
