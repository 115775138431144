/* eslint-disable array-callback-return */
import React, { useState, useRef } from 'react';
import {
	Chip,
	CircularProgress,
	Grid,
	Hidden,
	IconButton,
	Menu,
	MenuItem,
	Paper,
	Typography,
	withStyles,
} from '@material-ui/core';
import { COLORS } from '../../utils/Application_Constants';
import { withTranslation } from 'react-i18next';
import { MoreVert } from '@material-ui/icons';
import TimesheetStatusEnum, {
	TimesheetStatusEnumApi,
} from '../../components/ContentComponents/Timesheets/Status.enum';
import { config as ClientTableConfig } from '../../Client/Configurations/TimesheetReviewConfig';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ToolTipComponent from '../../components/GlobalComponents/ToolTipComponent';
import moment from 'moment';
import StatusErrorDialog from './StatusErrorDialog';
import {
	updateTimesheetReview,
	getEmailRecipients,
	sendManualEmailRequest,
	getTimesheets,
	downloadTimesheet
} from '../../Shared/Services/TimesheetsService';
import RejectionDialog from './RejectionDialog';
import EmailDialog from './EmailDialog';
import DidNotWorkDialog from './DidNotWorkDialog';
import StatusWarningDialog from './StatusWarningDialog';
import { useHistory } from 'react-router-dom';
import { saveTimesheet, getTimesheetById } from '../../services/TimeAndPayService';
import { formatErrorMessage } from '../Utils/ErrorMessage';
import { TimesheetUserGroupEnum, TimesheetUserGroupProdEnum } from '../../Admin/Configurations/OktaUserGroup.enum';

const styles = (theme) => ({
	PaperRoot: {
		padding: '1.5rem 2rem',
		display: 'flex',
		gap: '24px',
		flexDirection: 'column',
	},
	headerRow: {
		display: 'flex',
		backgroundColor: COLORS.LT_MIDNIGHTBG1,
		borderRadius: '8px',
	},
	headerCell: {
		padding: '12px 16px',
		display: 'flex',
		alignItems: 'center',
		'&:first-child': {
			paddingLeft: '24px',
		},
		'&:last-child': {
			paddingRight: '24px',
			justifyContent: 'flex-end',
		},
	},
	paragraph3: {
		display: 'flex',
		alignItems: 'center',
		color: COLORS.LT_MIDNIGHT50,
		fontSize: '12px',
		lineHeight: '18px',
		'& span:hover': {
			cursor: 'pointer',
		},
	},
	menuButton: {
		color: COLORS.LT_MIDNIGHT25,
	},
	menuIcon: {
		color: COLORS.LT_MIDNIGHT25,
		display: 'flex',
		alignItems: 'center',
	},
	chipRoot: {
		textAlign: 'center',
		width: '100%',

		[theme.breakpoints.up('lg')]: {
			minWidth: '175px',
		},
		[theme.breakpoints.between(1280, 1530)]: {
			minWidth: '135px',
			marginRight: '8px',
		},
	},
	chipSubmitted: {
		backgroundColor: COLORS.GLITTER,
		color: COLORS.RICH_ELECTRIC_BLUE,
		borderColor: COLORS.RICH_ELECTRIC_BLUE,
	},
	chipApproved: {
		backgroundColor: COLORS.AQUA_SPRING,
		color: COLORS.KELLY_GREEN,
		borderColor: COLORS.KELLY_GREEN,
	},
	chipRejected: {
		backgroundColor: COLORS.LT_PEPPER10,
		color: COLORS.LT_PEPPER,
		borderColor: COLORS.LT_PEPPER,
	},
	chipPaid: {
		color: 'white',
		background: COLORS.LT_EMERALD,
	},
	tableBody: {
		border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
		borderRadius: '8px',
	},
	tableRow: {
		'&:not(:last-child)': {
			borderBottom: `1px solid ${COLORS.LT_MIDNIGHT5}`,
		},
		'&:hover': {
			transition: '.25s',
			cursor: 'pointer',
			background: COLORS.LT_MIDNIGHTBG1,
		},
	},
	tableCell: {
		padding: '16px',
		paddingTop: '24px',
		'&:first-child': {
			paddingLeft: '24px',
		},
		'& .MuiTypography-body2': {
			maxWidth: '100%',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			textWrap: 'nowrap',
		},
	},
	commentsCell: {
		padding: '16px',
		paddingTop: '24px',
		'& .MuiTypography-body2': {
			whiteSpace: 'normal',
			wordWrap: 'break-word',
			maxWidth: '100%',
			overflow: 'hidden',
		},
	},
	bookingName: {
		fontStyle: 'italic',
		color: COLORS.LT_MIDNIGHT50,
	},
	sortIcon: {
		transition: '.15s',
		'&.desc': {
			transform: 'rotate(180deg)',
		},
	},
	sortIcons: {
		'& $sortIcon': {
			margin: '-5px 0',
		},
	},
	actionsCell: {
		padding: '4px 16px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		[theme.breakpoints.between(1280, 1550)]: {
			padding: '8px 12px',
		},
	},
	filterList: {
		minWidth: '250px',
		padding: '24px',
		display: 'flex',
		flexDirection: 'column',
		gap: '16px',
	},
	filterButton: {
		borderRadius: '20px',
		border: `1px solid ${COLORS.LT_MIDNIGHT25}`,
		background: 'white',
		color: COLORS.LT_MIDNIGHT75,
		'&:hover': {
			background: COLORS.LT_JOURNEY10,
			color: COLORS.LT_JOURNEY,
			borderColor: COLORS.LT_JOURNEY,
		},
	},
	nameGridContainer: {
		gap: '8px',
	},
	timesheetName: {
		'&.MuiTypography-body2': {
			maxWidth: 'calc(100% - 22px)',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			textWrap: 'nowrap',
		},
	},
	chatButtonIcon: {
		fontSize: '14px',
		color: COLORS.LT_MIDNIGHT25,
	},
	commentIcon: {
		textAlign: 'center',
		alignContent: 'center',
	},
	commentHeaderIcon: {
		textAlign: 'center',
		alignContent: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '12px 16px',
		display: 'flex',
	},
	emptyText: {
		color: COLORS.LT_MIDNIGHT25,
	},
});

const TimesheetReviewCard = (props) => {
	const {
		classes,
		timesheets,
		weekEnding,
		sortBy,
		sortDirection,
		handleSort,
		isSorting,
		getData,
		setAlert,
		user,
		config,
	} = props;

	const [anchorEl, setAnchorEl] = useState(null);
	const [statusErrorDialogOpen, setStatusErrorDialogOpen] = useState(false);
	const [recallErrorDialogOpen, setRecallErrorDialogOpen] = useState(false);
	const [recallErrorType, setRecallErrorType] = useState();
	const [recallDialogOpen, setRecallDialogOpen] = useState(false);
	const [rejectionDialogOpen, setRejectionDialogOpen] = useState(false);
	const [emailDialogOpen, setEmailDialogOpen] = useState(false);
	const [didNotWorkDialogOpen, setDidNotWorkDialogOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [statusErrorType, setStatusErrorType] = useState('updated');
	const [requestedAction, setRequestedAction] = useState();
	const [statusUpdateModalOpen, setStatusUpdateModalOpen] = useState(false);
	const [selectedTimesheetReview, setSelectedTimesheetReview] = useState(null);
	const [rejectionReason, setRejectionReason] = useState(null);
	const [emailsList, setEmailsList] = useState([]);
	const [dnwTimesheetsList, setDnwTimesheetsList] = useState([]);
	const [dnwTimesheet, setDnwTimesheet] = useState([]);
	const isAdminMode = useRef(false);
	const history = useHistory();
	const completedStatuses = [
		TimesheetStatusEnumApi.Approved,
		TimesheetStatusEnumApi.Rejected,
		TimesheetStatusEnumApi.Paid,
	];
	const isProd = (process.env.REACT_APP_EXECUTION_ENVIRONMENT === "PROD") ? true : false;

	let userIsReadOnly = true;
	let isTSAdmin = false;
	if (isProd) {
		isTSAdmin = user?.groups?.some((group) => group.includes(TimesheetUserGroupProdEnum.SystemAdministratorPROD)) ||
			user?.groups?.some((group) => group.includes(TimesheetUserGroupProdEnum.TimesheetAdminPROD));
	}
	else {
		isTSAdmin = user?.groups?.some((group) => group.includes(TimesheetUserGroupEnum.SystemAdministrator)) ||
			user?.groups?.some((group) => group.includes(TimesheetUserGroupEnum.TimesheetAdmin));
	}
	isAdminMode.current = isTSAdmin;

	if (isTSAdmin || (user && !user.groups)) {
		userIsReadOnly = false;
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDidNotWorkDialog = async (timesheet) => {
		setIsLoading(true);
		try {
			const request = {
				page: 1,
				offset: 0,
				sortBy: 'clinicianLastName',
				sortDirection: 'asc',
				limit: 50,
				statuses: [],
				divisions: [],
				selectedStartDate: timesheet.weekEnding,
				selectedEndDate: timesheet.weekEnding,
				search: timesheet.bookingName,
				searchKey: { name: "Booking ID", value: "bookingName" },
			};
			const response = await getTimesheets(request);
			setDnwTimesheet(timesheet);
			setDnwTimesheetsList(response.data);
			setDidNotWorkDialogOpen(true);
		} catch (error) {
			const message = formatErrorMessage(error);
			setAlert({
				message,
				type: 'error',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const handleDidNotWorkConfirm = async (locationTimesheet) => {
		setIsLoading(true);
		try {
			const aTimesheet = await getTimesheetById(
				locationTimesheet.clinicianId,
				locationTimesheet.id
			);

			aTimesheet.status = TimesheetStatusEnumApi.DidNotWork;
			saveTimesheet(aTimesheet, aTimesheet.id).then(() => {
				setIsLoading(false);
				getData();
			});
		} catch (error) {
			const message = formatErrorMessage(error);
			setAlert({
				message,
				type: 'error',
			});
			setIsLoading(false);
		}
	};

	const handleView = (timesheet) => {
		const url = config.getViewUrl(timesheet);
		history.push(url);
	};

	const getReviewerNames = () => {
		let reviewerFirstName = '';
		let reviewerLastName = '';
		if (user && user?.family_name && user?.given_name) {
			reviewerFirstName = user.given_name;
			reviewerLastName = user.family_name;
		} else if (user && user.name) {
			const names = user.name.split(' ');
			reviewerFirstName = names.slice(0, -1).join(' ');
			reviewerLastName = names[names.length - 1];
		}
		if (user && user.firstName && user.lastName) reviewerFirstName = user.firstName;
		if (user && user.lastName) reviewerLastName = user.lastName;
		return { reviewerFirstName, reviewerLastName };
	};

	const handleStatusUpdate = async (
		requestedActionEnding,
		{ id, reviewId, locationId, name, created, lastModified }
	) => {
		setIsLoading(true);
		const { reviewerFirstName, reviewerLastName } = getReviewerNames();
		const timesheetReview = {
			id: reviewId,
			timesheetId: id,
			locationId: locationId,
			status: requestedActionEnding,
			reviewerFirstName,
			reviewerLastName,
			reviewerEmail: user?.email,
			reviewDate: moment().utc().format(),
			rejectionReason: !rejectionReason || rejectionReason === '' ? null : rejectionReason,
			created: created,
			lastModified: lastModified
		};
		try {
			await updateTimesheetReview(id, reviewId, timesheetReview);
			setAlert({
				message: `${name}'s timesheet has been successfully ${requestedAction.toLowerCase()}!`,
				type: 'success',
			});
			setRequestedAction();
			setIsLoading(false);
			getData();
			setSelectedTimesheetReview(null);
		} catch (error) {
			if (
				error?.errors?.length &&
				error.errors[0].errorCode &&
				ClientTableConfig.errorCodes[error.errors[0].errorCode]
			) {
				setStatusErrorType(ClientTableConfig.errorCodes[error.errors[0].errorCode]);
				setStatusErrorDialogOpen(true);
			} else {
				setAlert({
					message: `Timesheet has failed to be ${requestedAction.toLowerCase()}. Please try again.`,
					type: 'error',
				});
			}
			setSelectedTimesheetReview(null);
			setIsLoading(false);
		}
	};

	const fetchEmailRecipients = async ({ id, reviewId }) => {
		setIsLoading(true);
		try {
			const response = await getEmailRecipients(id, reviewId);
			setEmailsList(response.emailTypes);
			setEmailDialogOpen(true);
		} catch (error) {
			const message = formatErrorMessage(error);
			setAlert({
				message,
				type: 'error',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const getDownloadTimesheet = async ({ clinicianId, id, locationId, }, filename) => {
		setIsLoading(true);
		try {
			const response = await downloadTimesheet(clinicianId, id, locationId, filename);
		} catch (error) {
			const message = formatErrorMessage(error);
			setAlert({
				message,
				type: 'error',
			});
		} finally {
			setIsLoading(false);
		}
	};


	const sendManualEmail = async (timesheet, communicationEventType, nameList) => {
		try {
			await sendManualEmailRequest(timesheet.id, timesheet.reviewId, communicationEventType);
			let names;
			if (nameList.length > 1) {
				names = `${nameList[0]} and ${nameList[1]}`;
			} else {
				names = nameList;
			}
			setAlert({
				message: `A reminder has been sent to ${names}!`,
				type: 'success',
			});
		} catch (error) {
			const message = formatErrorMessage(error);
			setAlert({
				message,
				type: 'error',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const handleManualEmail = async (eventTypes, nameList) => {
		setIsLoading(true);
		try {
			const promises = eventTypes?.map(async (communicationEventType) => {
				return await sendManualEmail(
					selectedTimesheetReview,
					communicationEventType,
					nameList
				);
			});
			await Promise.all(promises);
			setIsLoading(false);
		} catch (error) {
			const message = formatErrorMessage(error);
			setAlert({
				message,
				type: 'error',
			});
			setIsLoading(false);
		}
	};

	const renderTimesheets = () => (
		<>
			{(isLoading || isSorting || !user) && <CircularProgress color='primary' />}
			{!isLoading && !isSorting && timesheets.length === 0 && (
				<Typography variant='body2'>No results found</Typography>
			)}
			{!isLoading && !isSorting && timesheets.length !== 0 && renderTimesheetListGrid()}
		</>
	);

	const renderTimesheetHeader = () => (
		<Grid container justifyContent='space-between'>
			<Grid item>
				<Typography variant='h5' component='h5'>
					Week Ending {moment(weekEnding).format('M/D/YY')}
				</Typography>
			</Grid>
		</Grid>
	);

	const renderTimesheetListHeader = () => {
		return (
			<Hidden only={['xs', 'sm']}>
				<Grid container className={classes.headerRow}>
					{config.tableColumns.map(({ name, sortKey, size }) => {
						const sorted = sortBy === sortKey;
						return (
							<Grid item xs={size} key={name} className={classes.headerCell}>
								<div
									className={classes.paragraph3}
									style={{ fontWeight: sorted ? 700 : 'initial' }}
								>
									{sortKey ? (
										<span onClick={() => handleSort(sortKey)}>{name}</span>
									) :
										name
									}
									{sorted && (
										<ArrowDropUpIcon
											className={`${classes.sortIcon} ${sortDirection}`}
											fontSize='small'
										/>
									)}
									{!sorted && sortKey && renderSortableArrow()}
								</div>
							</Grid>
						);
					})}
				</Grid>
			</Hidden>
		);
	};

	const renderTimesheetListGrid = () => {
		return (
			<div className={classes.tableBody}>
				{timesheets.map((timesheet) => renderTimesheetListGridRow(timesheet))}
			</div>
		);
	};

	const renderTimesheetListGridRow = (timesheet) => {
		const uniqueKey = `${timesheet.id}-${timesheet.reviewId}-${timesheet.locationId}`;
		return (
			<Grid container className={classes.tableRow} key={uniqueKey}>
				{config.tableColumns.map(({ key, size }) => {
					const isApprover = key === 'approver';
					if (isApprover) {
						const useActual = completedStatuses.includes(timesheet.status);
						key = useActual ? 'actualApprover' : 'assignedApprover';
					}
					const isEmpty = timesheet[key] === 0 || !timesheet[key];
					const isStatus = key === 'status';
					const isName = key === 'name';
					const isClincianComments = key === 'clinicianComments';
					const isHasComments = key === 'comments';
					const isActions = key === 'actions';
					if (isClincianComments) {
						const comment = timesheet.clinicianComments || '';
						const truncatedComment = comment.length > 35 ? `${comment.substring(0, 35)}...` : comment;

						return (
							<Grid key={`${uniqueKey}-${key}`} item xs={size} className={classes.commentsCell}
								onClick={() => handleView(timesheet)}>
								{comment && (
									<ToolTipComponent
										disableFocusListener
										enterTouchDelay={150}
										interactive
										placement='right'
										title={<Typography variant='string'>{comment}</Typography>}
									>
										<Typography
											variant='body2'
											title={comment}
										>
											{truncatedComment}
										</Typography>
									</ToolTipComponent>
								)}
							</Grid>
						);
					}
					if (isHasComments) {
						if (!timesheet.comments) return (<Grid item xs={1}></Grid>);
						return (<Grid item xs={1} className={classes.commentIcon}>{renderCommentToolTip(timesheet.comments)} </Grid>);
					}
					if (isName) return renderTimesheetName(timesheet, size, uniqueKey);
					if (isStatus) return renderStatusChip(timesheet, size, uniqueKey);
					if (isActions) return renderActions(timesheet, uniqueKey);
					return (
						<Grid
							key={`${uniqueKey}-${key}`}
							item
							xs={size}
							className={classes.tableCell}
							onClick={() => handleView(timesheet)}
						>
							<Typography
								variant='body2'
								title={timesheet[key]}
								className={isEmpty ? classes.emptyText : null}
							>
								{isEmpty ? '-' : timesheet[key]}
							</Typography>
						</Grid>
					);
				})}
			</Grid>
		);
	};

	const renderStatusChip = (timesheet, size, uniqueKey) => (
		<Grid
			item
			xs={size}
			className={classes.tableCell}
			key={`${uniqueKey}-status`}
			onClick={() => handleView(timesheet)}
		>
			<Chip
				label={
					timesheet.status
						? TimesheetStatusEnum[timesheet.status]
						: TimesheetStatusEnum.NotStarted
				}
				className={classes[`chip${timesheet.status}`]}
				classes={{ root: classes.chipRoot }}
				title={timesheet.status}
			/>
		</Grid>
	);

	const renderCommentToolTip = (comments) => {
		if (!comments) return null;
		return (
			<ToolTipComponent
				disableFocusListener
				enterTouchDelay={150}
				interactive
				placement='right'
				title={'This timesheet contains one or more comments.'}
			>
				<ChatBubbleIcon fontSize='small' className={classes.chatButtonIcon} />
			</ToolTipComponent>
		);
	}

	const renderTimesheetName = (timesheet, size, uniqueKey) => {
		return (
			<>
				<Grid item xs={size} className={classes.tableCell} key={`${uniqueKey}-name`}>
					<Grid
						container
						alignItems='center'
						className={classes.nameGridContainer}
						onClick={() => handleView(timesheet)}
					>
						<Typography
							variant='body2'
							className={classes.timesheetName}
							title={timesheet.name}
						>
							{timesheet.name}
						</Typography>
						{renderCommentToolTip(timesheet.clinicianComments, false)}
					</Grid>
					<Typography variant='body2' className={classes.bookingName}>
						{timesheet.bookingName}
					</Typography>
				</Grid>
			</>
		);
	}

	const renderActions = (timesheet, uniqueKey) => (
		<Grid item xs={1} className={classes.actionsCell} key={`${uniqueKey}-actions`}>
			<IconButton
				className={classes.menuButton}
				color='default'
				aria-label='More options'
				onClick={handleClick}
				id={`${uniqueKey}-btn`}
			>
				<MoreVert />
			</IconButton>
			<Menu
				id={`${uniqueKey}-menu`}
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl?.id === `${uniqueKey}-btn`)}
				onClose={handleClose}
			>
				{config.actions.map(({ name, requestedStatus, icon }) => {
					const externalAccount =
						timesheet.locationAccountLevel?.toLowerCase().includes('external') &&
						timesheet.locationAccountLevel !== 'External (Client Portal - No Time)' &&
						timesheet.locationAccountLevel !== 'External (Client Portal + Scheduler + No Time)';
					const readOnly =
						(timesheet.status !== TimesheetStatusEnumApi.Submitted &&
							(name === 'Reject' || name === 'Approve')) ||
						(userIsReadOnly && name !== 'View');
					const hideRecall =
						((timesheet.status !== TimesheetStatusEnumApi.Submitted &&
							timesheet.status !== TimesheetStatusEnumApi.TimeNotEntered) ||
							timesheet.timesheetStatus === TimesheetStatusEnumApi.Paid ||
							timesheet.timesheetStatus === TimesheetStatusEnumApi.Approved) &&
						name === 'Recall';
					const hideEmailClient =
						(timesheet.status !== TimesheetStatusEnumApi.Submitted ||
							externalAccount) &&
						name === 'Email Client';
					const hideEmailClinician =
						((timesheet.status !== TimesheetStatusEnumApi.Rejected &&
							timesheet.status !== TimesheetStatusEnumApi.PendingSubmission &&
							timesheet.status !== TimesheetStatusEnumApi.NotStarted &&
							timesheet.status !== TimesheetStatusEnumApi.Approved) ||
							externalAccount) &&
						name === 'Email Clinician';
					const hideDidNotWork =
						(timesheet.status !== TimesheetStatusEnumApi.PendingSubmission &&
							timesheet.status !== TimesheetStatusEnumApi.NotStarted) &&
						name === 'Did Not Work';
					if (readOnly || hideEmailClient || hideEmailClinician || hideRecall || hideDidNotWork) return;
					return (
						<MenuItem
							onClick={() => {
								handleClose();
								setRequestedAction(requestedStatus);
								setSelectedTimesheetReview(timesheet);
								if (requestedStatus === TimesheetStatusEnumApi.Rejected) {
									setRejectionDialogOpen(true);
								} else if (requestedStatus === 'DidNotWork') {
									handleDidNotWorkDialog(timesheet);
								} else if (requestedStatus === 'View') {
									handleView(timesheet);
								} else if (
									requestedStatus === 'EmailClinician' ||
									requestedStatus === 'EmailClient'
								) {
									fetchEmailRecipients(timesheet);
								} else if (
									requestedStatus === 'DownloadTimesheet'
								) {
									let filename = timesheet.name + '-' + timesheet.location + ' ' + moment(timesheet.weekEnding).format('MM-DD-YYYY') + '.pdf';
									getDownloadTimesheet(timesheet, filename);
								} else if (
									requestedStatus === TimesheetStatusEnumApi.PendingSubmission
								) {
									setRecallDialogOpen(true);
								} else if (requestedStatus === TimesheetStatusEnumApi.Approved) {
									setStatusUpdateModalOpen(true);
								} else {
									handleStatusUpdate(requestedStatus, timesheet);
								}
							}}
							key={name}
						>
							<Grid container alignItems='center' spacing={2}>
								<Grid item className={classes.menuIcon}>
									{icon}
								</Grid>
								<Grid item>
									<Typography variant='body2'>{name}</Typography>
								</Grid>
							</Grid>
						</MenuItem>
					);
				})}
			</Menu>
		</Grid>
	);

	const renderSortableArrow = () => (
		<Grid container direction='column' className={classes.sortIcons}>
			<ArrowDropUpIcon className={`${classes.sortIcon} asc`} fontSize='small' />
			<ArrowDropUpIcon className={`${classes.sortIcon} desc`} fontSize='small' />
		</Grid>
	);

	const renderStatusErrorDialog = () => {
		const displayAction = statusErrorType === requestedAction ? 'Updated' : requestedAction;

		const props = {
			name: 'status-error',
			message: `This timesheet has been recently ${statusErrorType} and is not available
								to be ${displayAction}.`,
			onClose: () => {
				setStatusErrorDialogOpen(false);
			},
			onExited: () => {
				setRequestedAction();
				getData();
			},
			open: statusErrorDialogOpen,
		};
		return <StatusErrorDialog {...props} />;
	};

	const renderDidNotWorkDialog = () => {
		const props = {
			name: 'status-didnotwork',
			timesheetsList: dnwTimesheetsList,
			timesheet: dnwTimesheet,
			isLoading,
			onClose: () => {
				setDidNotWorkDialogOpen(false);
			},
			handleAction: (aLocationTimesheet) => {
				handleDidNotWorkConfirm(aLocationTimesheet);
				setDidNotWorkDialogOpen(false);
			},
			open: didNotWorkDialogOpen,
		};
		return <DidNotWorkDialog {...props} />;
	};

	const renderEmailDialog = () => {
		const props = {
			name: 'status-email',
			emailsList,
			isLoading,
			onClose: () => {
				setEmailDialogOpen(false);
			},
			handleAction: (eventTypes, nameList) => {
				handleManualEmail(eventTypes, nameList);
				setEmailDialogOpen(false);
			},
			open: emailDialogOpen,
		};
		return <EmailDialog {...props} />;
	};

	const renderRejectionDialog = () => {
		const props = {
			name: 'status-error',
			message: '*Selecting cancel will NOT reject the timesheet',
			title: 'Reason for rejecting',
			onClose: () => {
				setRejectionDialogOpen(false);
			},
			handleAction: () => {
				handleStatusUpdate(TimesheetStatusEnumApi.Rejected, selectedTimesheetReview);
				setRejectionDialogOpen(false);
			},
			open: rejectionDialogOpen,
			setRejectionReason: (value) => {
				setRejectionReason(value);
			},
		};
		return <RejectionDialog {...props} />;
	};

	const renderStatusUpdateConfirmationDialog = () => {
		const locationName = selectedTimesheetReview?.location;
		const props = {
			name: 'status-update-confirmation',
			message: `Are you sure you would like to mark ${moment(
				selectedTimesheetReview?.weekEnding
			).format('MMMM Do')} at ${locationName} as ${requestedAction?.toLowerCase()}?`,
			open: statusUpdateModalOpen,
			onClose: () => {
				setStatusUpdateModalOpen(false);
				setIsLoading(false);
			},
			handleAction: () => {
				try {
					setStatusUpdateModalOpen(false);
					setIsLoading(true);
					handleStatusUpdate(requestedAction, selectedTimesheetReview);
				} catch (error) {
					setAlert({
						message: `Timesheet has failed to be ${selectedTimesheetReview.toLowerCase()}. Please try again.`,
						type: 'error',
					});
				}
			},
			primaryActionName: 'Confirm',
			secondaryActionName: 'Cancel',
		};
		return <StatusWarningDialog {...props} />;
	};

	const renderRecallDialog = () => {
		const props = {
			name: 'recall-warning',
			message:
				'Recalling a timesheet will recall all submitted locations, stop the approval process, and will require the recalled timesheets to be re-submitted.',
			onClose: () => {
				setRecallDialogOpen(false);
			},
			handleAction: async () => {
				try {
					setRecallDialogOpen(false);
					setIsLoading(true);
					const timesheet = await getTimesheetById(
						selectedTimesheetReview?.clinicianId,
						selectedTimesheetReview?.id
					);
					setRecallErrorType(timesheet.status.toLowerCase());
					const currentTimsheetHasEntryInRecallableStatus =
						timesheet?.timesheetReviews?.some(
							(review) =>
								review.status === TimesheetStatusEnumApi.Submitted ||
								review.status === TimesheetStatusEnumApi.TimeNotEntered
						);
					if (!currentTimsheetHasEntryInRecallableStatus) {
						setRecallErrorDialogOpen(true);
						setIsLoading(false);
					} else {
						timesheet.status = TimesheetStatusEnumApi.PendingSubmission;
						await saveTimesheet(timesheet, selectedTimesheetReview?.id, true);
						selectedTimesheetReview.status = TimesheetStatusEnumApi.PendingSubmission;
						handleView(selectedTimesheetReview);
					}
				} catch (error) {
					setIsLoading(false);
					console.error(error);
				}
			},
			open: recallDialogOpen,
			primaryActionName: 'Recall',
			secondaryActionName: 'Cancel',
		};
		return <StatusWarningDialog {...props} />;
	};

	const renderRecallErrorDialog = () => {
		const props = {
			name: 'recall-error',
			message: `This timesheet has been recently ${recallErrorType} and is not available
								to be recalled. Please reach out to your recruiter with any
								questions.`,
			onClose: () => {
				setRecallErrorDialogOpen(false);
				setIsLoading(false);
			},
			onExited: () => {
				setRequestedAction();
				history.go(0);
			},
			open: recallErrorDialogOpen,
		};
		return <StatusErrorDialog {...props} />;
	};

	return (
		<Paper classes={{ root: classes.PaperRoot }}>
			{renderTimesheetHeader()}
			{renderTimesheetListHeader()}
			{renderTimesheets()}
			{renderStatusErrorDialog()}
			{renderRejectionDialog()}
			{renderEmailDialog()}
			{renderDidNotWorkDialog()}
			{renderRecallDialog()}
			{renderRecallErrorDialog()}
			{renderStatusUpdateConfirmationDialog()}
		</Paper>
	);
};

export default withTranslation()(withStyles(styles)(TimesheetReviewCard));
