import React, { Component } from 'react';

import IdleTimer from 'react-idle-timer';
import Layout from './components/Layout/Layout';
import PlainLayout from './components/PlainLayout/PlainLayout';
import { COLORS } from './utils/Application_Constants';
import msSalService from './services/msSalService';
import UserContext from './contexts/userContext';
import InvalidUserPage from './pages/InvalidUser/InvalidUserPage';
import { getItemCookie } from './utils/helpers';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { appInsights, reactPlugin } from './AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import moment from 'moment';
import TimeoutWarningModal from './pages/LogOutPage/TimeoutWarningModal';
import { getAssignments } from './services/AssignmentsService';

const styles = (theme) => ({
	root: {
		padding: '80px 0',
		minHeight: '800px',
		backgroundColor: COLORS.BLACK_HAZE,
	},
	progressButton: {
		whiteSpace: 'nowrap',
	},
	'::placeholder': {
		color: COLORS.LT_MIDNIGHT33,
	},
});

class ClinicianApp extends Component {
	constructor(props, context) {
		super(props);

		this.state = {
			showModal: false,
			simpleLayout: false,
			displayModal: false,
			displayLogoutModal: false,
			timeoutMessageWasShown: false,
			timeoutForMessage: 1000 * 60 * 5,
			timeoutFeatureOn: 1000 * 60 * 10,
			timeoutFeatureOff: 1000 * 60 * 14,
			warningTime: 1000 * 60 * 10,
			formattedMinutes: null,
			remainingTime: null,
		};
		this.idleTimer = null;
		this.context = context;
		this.logoutTimer = null;
		this.onIdle = this._onIdle.bind(this);
		this.onIdleWithoutWarning = this._onIdleWithoutWarning.bind(this);
		this.countdownInterval = null;
		this.timeout = null;
	}

	togglePopup = (value) => {
		if (value === false) {
			localStorage.removeItem('LocalStorgae_startTimer');
			clearTimeout(this.timeout);
			clearInterval(this.countdownInterval);
			clearInterval(this.logoutTimer);
			//this.logoutTimer = null;
			//}
			if (this.idleTimer) {
				this.idleTimer.reset();
			}
		}
		this.setState({ displayModal: value });
	};

	_onIdle(e) {
		//this.togglePopup(true);
		this.setState({ displayModal: true, timeoutMessageWasShown: true });
		var timeLeft = null;
		var countdown = null;
		var formatted = null;
		var startTime = localStorage.getItem('LocalStorgae_startTimer');
		if (!startTime) {
			startTime = Date.now();
			localStorage.setItem('LocalStorgae_startTimer', startTime);
		}
		console.log('Adding start time to local ' + startTime);
		timeLeft = this.state.timeoutForMessage - 1000;
		this.logoutTimer = setInterval(() => {
			startTime = localStorage.getItem('LocalStorgae_startTimer');
			formatted = moment.utc(timeLeft).format('mm:ss');
			// this.setState({timeoutForMessage: timeLeft,
			//         formattedMinutes: formatted
			//       });
			countdown = Date.now() - startTime;
			if (countdown < this.state.timeoutForMessage) {
				timeLeft = this.state.timeoutForMessage - countdown;
				formatted = moment.utc(timeLeft).format('mm:ss');
				console.log(' Count down value is Formatted' + formatted);
				localStorage.setItem('LocalStorgae_startTimer', Date.now()); // update this value to stop exponential differecne.
				this.setState({
					timeoutForMessage: timeLeft,
					formattedMinutes: formatted,
				});
			} else {
				localStorage.removeItem('LocalStorgae_startTimer');
				localStorage.setItem('LocalStorage_Reason', 'timesup');
				this.handleLogout();
			}
		}, 1000);
	}

	_onIdleWithoutWarning(e) {
		if (msSalService.getAccount) {
			localStorage.removeItem('LocalStorage_UserSignedIN');
			localStorage.removeItem('LocalStorage_FirstTimeUserLogin');
			localStorage.removeItem('LocalStorage_PersonalInfoDisclaimerAlert');
			localStorage.removeItem('snoozeNotification');
			msSalService.setNewRedirectUri();
			msSalService.logOut();
			appInsights.trackTrace({
				message: 'User Timed Out',
				severityLevel: SeverityLevel.Information,
			});
		}
	}

	toggleLogoutDialog = (value) => {
		this.setState({ displayLogoutModal: value });
	};

	renderTimeRemainingDialog = () => {
		const { formattedMinutes, displayModal, remainingTime } = this.state;
		const classes = new styles();
		return (
			<TimeoutWarningModal
				showModal={displayModal}
				togglePopup={this.togglePopup}
				formattedMinutes={formattedMinutes}
				handleRemainLogin={this.handleStayLoggedIn}
				handleLogout={this.handleLogout}
				classes={classes}
				remainingTime={remainingTime}
			/>
		);
	};

	handleLogout = () => {
		this.togglePopup(false);
		//this.setState({ displayModal: false });
		localStorage.removeItem('LocalStorgae_startTimer');
		if (msSalService.getAccount) {
			localStorage.removeItem('LocalStorage_UserSignedIN');
			localStorage.removeItem('LocalStorage_FirstTimeUserLogin');
			localStorage.removeItem('LocalStorage_PersonalInfoDisclaimerAlert');
			localStorage.removeItem('snoozeNotification');
			msSalService.setNewRedirectUri();
			msSalService.logOut();
			appInsights.trackTrace({
				message: 'User Timed Out',
				severityLevel: SeverityLevel.Information,
			});
		}
	};

	handleStayLoggedIn = () => {
		this.togglePopup(false);
		localStorage.removeItem('LocalStorgae_startTimer');
		clearTimeout(this.timeout);
		clearInterval(this.countdownInterval);
		clearInterval(this.logoutTimer);
		if (this.idleTimer) {
			this.idleTimer.reset();
		}
	};

	componentWillMount() {
		var route = msSalService.getRoute;
		msSalService.removeRoute();

		var path = window.location.hash;

		console.log('Path=' + path);

		var simple = false;
		if (path.includes('registration') || path.includes('logout') || path.includes('worknsso')) {
			simple = true;
		}

		if (path.includes('jobboard')) {
			if (getItemCookie('msal.idtoken') === null) {
				simple = true;
			}
		}

		var aWorkNFlow = localStorage.getItem('LocalStorage_WorkNFlow');
		if (aWorkNFlow === 'true') {
			simple = true;
		}

		this.setState({
			simpleLayout: simple,
			loginRoute: route,
		});
	}

	async componentDidMount() {
		window.onbeforeunload = () => {
			this.clearSession();
			return undefined;
		};
		if (this.context.account) {
			let bookingDivision = null;
			let isOnActiveAssignment = false;
			let newAssignment = false;
			await Promise.all([
				getAssignments(this.context.externalId, 'active'),
				getAssignments(this.context.externalId, 'upcoming'),
			])
				.then(([active, upcoming]) => {
					if ((active && active.length > 0) || (upcoming && upcoming.length > 0)) {
						isOnActiveAssignment = true;
					}
					if (active && active.length > 0) {
						active.forEach(assignment => {
							if (bookingDivision == null) {
								bookingDivision = assignment.division;
							}
							else {
								if (!bookingDivision.includes(assignment.division)) {
									bookingDivision += `,${assignment.division}`;
								}
							}
							if (assignment.bookingType === 'New' && moment(assignment.startDate).add(2, 'days') < moment()) {
								newAssignment = true;
							}
						});
					}
				})
				.catch((e) => {
					console.log(e);
				});
			this.context.userLevel.then(userLevel =>
				window.Appcues.identify(this.context.account?.accountIdentifier, {
					Provider_Name: this.context.account?.idToken.name,
					Provider_First_Name: this.context.account?.idToken.given_name,
					Provider_Last_Name: this.context.account?.idToken.family_name,
					Provider_Email: this.context.account?.idToken.emails[0],
					Provider_External_ID: this.context.externalId,
					LTOE_User_Level: userLevel,
					B2CID: this.context.account?.accountIdentifier,
					Environment: process.env.REACT_APP_EXECUTION_ENVIRONMENT,
					Is_On_Active_Assignment: isOnActiveAssignment ? "Y" : "N",
					New_Assignment_Started: newAssignment ? "Yes" : "No",
					Division: bookingDivision
				})
			);
		}
	}

	componentWillUnmount() {
		//this.clearSession();
		window.onbeforeunload = null;
	}

	clearSession() {
		if (sessionStorage.length > 0 && sessionStorage.getItem('copiedSession') === null) {
			console.log('clearing session');
			sessionStorage.removeItem('msal-id');
			sessionStorage.removeItem('msal.client.info');
			sessionStorage.removeItem('msal.idtoken');
		}
		sessionStorage.removeItem('copiedSession'); // don't block again
	}

	render() {
		const { account } = this.context;
		const { verifiedDate } = this.context;
		const { externalId } = this.context;
		const { timeoutMessageWasShown } = this.state;
		const JobboardSignedIN = localStorage.getItem('LocalStorage_JobboardSignedIN');
		const EmailInvitation = localStorage.getItem('LocalStorage_EmailInvitation');
		//const EmailInvitation = getLocalStorageWithExpiry('LocalStorage_EmailInvitation');
		const aWorkNSignedIN = localStorage.getItem('LocalStorage_WorkNSignedIN');
		const aWorkNFlow = localStorage.getItem('LocalStorage_WorkNFlow');

		if (
			this.state.loginRoute !== null &&
			(JobboardSignedIN === 'false' || JobboardSignedIN === null) &&
			!timeoutMessageWasShown &&
			EmailInvitation === null &&
			aWorkNSignedIN === null &&
			aWorkNFlow === null
		) {
			const location = window.location.href;
			window.location.replace(
				location.substring(0, location.length - 1) + this.state.loginRoute
			);
			window.location.reload();
			return null;
		}

		if (this.state.simpleLayout || account === undefined || account === null) {
			return (
				<div className='App'>
					<PlainLayout />
				</div>
			);
		} else if (
			account !== undefined &&
			externalId === undefined &&
			verifiedDate !== undefined
		) {
			return <InvalidUserPage />;
		} else if (account !== undefined && verifiedDate === undefined) {
			return (
				<div>
					<div className='App'>
						<IdleTimer
							crossTab={{
								emitOnAllTabs: true,
							}}
							startOnMount
							events={[
								'keydown',
								'wheel',
								'DOMMouseScroll',
								'mousewheel',
								'mousedown',
								'touchstart',
								'touchmove',
								'MSPointerDown',
								'visibilitychange',
							]}
							ref={(ref) => {
								this.idleTimer = ref;
							}}
							element={document}
							onIdle={this.onIdle}
							debounce={250}
							timeout={this.state.timeoutFeatureOn}
						/>
						<PlainLayout />
						{this.renderTimeRemainingDialog()}
					</div>
				</div>
			);
		} else {
			return (
				<>
					<div className='App'>
						<IdleTimer
							crossTab={{
								emitOnAllTabs: true,
							}}
							startOnMount
							events={[
								'keydown',
								'wheel',
								'DOMMouseScroll',
								'mousewheel',
								'mousedown',
								'touchstart',
								'touchmove',
								'MSPointerDown',
								'visibilitychange',
							]}
							ref={(ref) => {
								this.idleTimer = ref;
							}}
							element={document}
							onIdle={this.onIdle}
							debounce={250}
							timeout={this.state.timeoutFeatureOn}
						/>
						<Layout />
						{this.renderTimeRemainingDialog()}
					</div>
				</>
			);
		}
	}
}
ClinicianApp.contextType = UserContext;
export default withAITracking(reactPlugin, ClinicianApp);