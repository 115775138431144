import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { makeStyles, Button, Paper, Grid } from '@material-ui/core';
import { COLORS } from '../../../utils/Application_Constants';
import { ltdomain, stateFullNameToAcronym, cityIfNotNull } from '../../../utils/helpers';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const styles = makeStyles((theme) => ({
	slide: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		opacity: '1',
		transition: 'opacity ease-in-out 0.4s',
		backgroundColor: COLORS.AQUA_SPRING,
	},
	activeanim: {
		opacity: '1',
	},
	containerslider: {
		maxWidth: '600px',
		height: '100px',
		margin: '100px auto 0',
		position: 'relative',
		overflow: 'hidden',
		boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
		background: COLORS.LIGHT_BLUE,
	},
	itemPaper: {
		padding: '5px',
		margin: '0px 1rem',
		height: '35vh',
	},
	itemPaperSingle: {
		padding: '5px',
		height: '35vh',
		marginTop: '2rem',
	},
	itemPaperSingleRecruiter: {
		padding: '5px',
		marginLeft: '15px',
		height: '40vh',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0px',
		},
	},
	itemPaperTile: {
		padding: '5px',
		margin: '15px',
		height: '35vh',
	},
	itemPaperTileExtra: {
		padding: '5px',
		marginLeft: '1rem',
		marginRight: '1rem',
		height: '35vh',
	},
	helpPanelButton: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'left',
		textAlign: 'center',
		borderLeft: `1px solid ${COLORS.WHITE_LILAC}`,
		//width: '35%',
		width: 'fit-content',
		borderTop: `1px solid ${COLORS.WHITE_LILAC}`,
		paddingBottom: '5px',
		textTransform: 'inherit',
		marginTop: '20%',
		marginLeft: '5%',
		[theme.breakpoints.down('sm')]: {
			width: 'fit-content',
			marginTop: '20%',
		},
	},
	// moreJobsTile: {
	//   display: 'flex',
	//   justifyContent: 'center',
	//   flexDirection: 'column',
	//   alignItems: 'center',
	//   width: '70%',
	//   backgroundColor:"#2a3953",
	//   marginLeft:'15%',
	//   color:'#FDFDFD',
	//   fontSize:'14px',
	//   [theme.breakpoints.down('sm')]: {
	//     width: 'fit-content',
	//     marginLeft:'20%'
	//   }
	// },
	licensingTile: {
		//   display: 'flex',
		//   justifyContent: 'center',
		//   flexDirection: 'column',
		//   alignItems: 'center',
		//   width: '80%',
		//   marginLeft:'30px',
		//   paddingBottom: '5px',
		//   textTransform: 'inherit',
		//   backgroundColor:"#2a3953",
		//   color:'#FDFDFD',
		//   fontSize:'14px',
		//   [theme.breakpoints.down('sm')]: {
		//     width: 'fit-content',
		//     fontSize: 'small',
		// },
	},
	arrowNext: {
		top: '50%',
		right: '20px',
		cursor: 'pointer',
		zIndex: '1',
		position: 'absolute',
		transform: 'translateY(-60%)',
		lineHeight: '0',
		background: COLORS.LT_MIDNIGHT,
		width: '32px',
		height: '32px',
		textAlign: 'center',
		borderRadius: '50%',
		opacity: '0.7',
	},

	arrowPrev: {
		top: '50%',
		left: '20px',
		cursor: 'pointer',
		zIndex: '1',
		position: 'absolute',
		transform: 'translateY(-60%)',
		lineHeight: '0',
		background: COLORS.LT_MIDNIGHT,
		width: '32px',
		height: '32px',
		textAlign: 'center',
		borderRadius: '50%',
		opacity: '0.7',
	},

	// arrowNext:{
	//   //width: '47px',
	//   height: '104px',
	//   display: 'flex',
	//   alignItems: 'center',
	//   justifyContent: 'center',
	//   zIndex: 1,
	//   right: 0,
	//   borderBottomLeftRadius: '7px',
	//   borderTopLeftRadius: '7px',
	//   top: '50%',
	//   right: '20px',
	//   transform: 'translateY(-60%)',
	//   fontSize:'0px',
	//   lineHeight:'0',
	//   position:'absolute',
	//   height:'20px',
	//   cursor:'pointer'
	// },

	// arrowPrev:{
	//   //width: '47px',
	//   height: '104px',
	//   display: 'flex',
	//   alignItems: 'center',
	//   justifyContent: 'center',
	//   zIndex: 1,
	//   left: 0,
	//   borderBottomLeftRadius: '7px',
	//   borderTopLeftRadius: '7px',
	//   top: '50%',
	//   left: '20px',
	//   transform: 'translateY(-60%)',
	//   fontSize:'0px',
	//   lineHeight:'0',
	//   position:'absolute',
	//   height:'20px',
	//   cursor:'pointer'
	// },

	jobDescStyleTile: {
		fontSize: '18px',
		padding: '1px',
		fontWeight: 400,
		color: '#CBE3F4',
		textAlign: 'center',
		marginTop: '10%',
	},

	rjcMoreJobsCard: {
		background: COLORS.LT_JOURNEY,
		color: 'white',
	},
	rjcLicenseCard: {
		background: COLORS.LT_POMEGRANATE,
		color: 'white',
	},
	jobDescStyleTileSearch: {
		//   fontSize: "18px",
		//   padding: "1px",
		//   fontWeight: 400,
		//   color: "#CBE3F4",
		//   textAlign:'center',
		//   marginTop:'10%'
	},
	jobDescStyleTileLicense: {
		fontSize: '18px',
		padding: '1px',
		fontWeight: 400,
		color: '#CBE3F4',
		textAlign: 'center',
		marginTop: '5%',
	},

	rjcCard: {
		margin: '0px 1rem',
		// height: '25vh',
		padding: '1.5rem',
	},
	rjcJobHeader: {
		color: COLORS.LT_JOURNEY,
		textAlign: 'left',
		marginBottom: '.5rem',
	},
	rjcJobCity: {
		color: COLORS.LT_STEEL,
		// marginTop:'2rem',
	},

	jobDescStyleTileLicenseSingle: {
		color: '#CBE3F4',
		textAlign: 'center',
		marginTop: '3rem',
		[theme.breakpoints.down('sm')]: {
			marginTop: '3.75rem',
		},
	},
	// jobDescriptionEllipsis : {
	//   color: "#596674",
	//   fontSize:'16px',
	//   fontWeight:'400',
	//   [theme.breakpoints.down('md')]: {
	//     fontSize: '14px',
	//     fontWeight: '300',
	//     marginLeft: '0.5rem',
	//   },

	// },
	jobDescription: {
		color: '#596674',
		textAlign: 'left',
		fontSize: '16px',
		fontWeight: '500',
		display: 'inline-block',
		whiteSpace: 'nowrap',
		marginLeft: '1rem',
		width: '250px',
		[theme.breakpoints.down('md')]: {
			width: '200px',
			fontSize: '14px',
			fontWeight: '300',
			marginLeft: '0.5rem',
			justifyContent: 'left',
		},
	},

	cityStyle: {
		fontSize: '16px',
		color: '#757B91',
		fontWeight: '500',
		padding: '5px 0',
		textAlign: 'left',
		fontStyle: 'italic',
		[theme.breakpoints.down('sm')]: {
			marginTop: '1.5rem',
		},
	},
	rjcExtraCard: {
		'& h3': {
			color: COLORS.LT_JOURNEY25,
		},
		'& p': {
			color: 'white',
		},
	},

	// detailsStyle :{
	//   fontSize: "14px",
	//   padding: "5px 0",
	//   color: "#757B91",
	//   fontWeight: 300,
	// },
	// detailsStyleTile :{
	//   fontSize: "14px",
	//   padding: "5px 0",
	//   color: "#FFFFFF",
	//   fontWeight: 300,
	//   display: 'flex',
	//   alignItems: 'center',
	//   textAlign:'center'
	// }
	detailsStyle: {
		fontSize: '14px',
		padding: '5px 0',
		color: '#757B91',
		fontWeight: 300,
	},
	detailsStyleTile: {
		fontSize: '14px',
		padding: '5px 0',
		color: '#FFFFFF',
		fontWeight: 300,
		display: 'flex',
		alignItems: 'center',
		textAlign: 'center',
	},
	jobDescriptionStyle: {
		fontSize: '14px',
		padding: '5px 0',
		color: '#596674',
		fontWeight: 300,
		display: 'flex',
		alignItems: 'center',
		textAlign: 'center',
	},
}));

function CarouselComponent({ recommendedJobList, optimizeVariant, t }) {
	var settings = {
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
				},
			},
			{
				breakpoint: 960,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	const classes = styles();

	const handleExtraTileclick = (name) => {
		let baseURL = ltdomain();
		let url = null;
		if (name === 'search') {
			url = baseURL + `/Resources/JobSearch/QuickSearch`;
			window.open(url, '_Self');
		} else {
			url = baseURL + `/contact-a-recruiter/`;
			window.open(url, '_Self');
		}
	};

	const cleanUrl = (url) => {
		if (!!url && !url.startsWith('https://') && !url.startsWith('http://')) {
			url = 'https://' + url;
		}
		return url;
	};

	// This list will be constant all the time and used when only one job is returned from SF.
	const additionalTiles = [
		{
			id: 6,
			Headline: t(`dashboard:INFO.MORE_JOBS_HEAD`),
			secondaryHeader: t(`dashboard:INFO.MORE_JOBS_SUBHEAD`),
			details: t(`dashboard:INFO.MORE_JOBS_TEXT`),
			buttonText: t(`dashboard:BUTTONS.MORE_JOBS_BTN_TEXT`),
			name: 'search',
			Url: ltdomain() + '/Resources/JobSearch/QuickSearch',
		},
		{
			id: 7,
			Headline: t(`dashboard:INFO.LICENSING_HEAD`),
			secondaryHeader: t(`dashboard:INFO.LICENSING_SUBHEAD`),
			details: t(`dashboard:INFO.LICENSING_TEXT`),
			buttonText: t(`dashboard:BUTTONS.LICENSING_BTN_TEXT`),
			name: 'contact',
			Url: ltdomain() + '/contact-a-recruiter/',
		},
	];

	const Card = ({ item }) => {
		if (!item.url) return null;
		var strippedHtml = '';
		var displayHeadline = '';
		if (!!item.jobDescription) {
			strippedHtml = item.jobDescription.replace(/<[^>]+>/g, '').toString();
		}
		if (!!item.headline) {
			displayHeadline =
				item.headline.length > 75
					? item.headline.substring(0, 75 - 3) + '...'
					: item.headline;
		}

		const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

		return (
			<Grid item xs={12}>
				<Paper className={classes.rjcCard}>
					<h4 className={classes.rjcJobHeader}>
						<a
							href={cleanUrl(item.url)}
							data-tag='jobLink'
							data-variant={optimizeVariant}
							data-url={cleanUrl(item.url)}
						>
							{displayHeadline}
						</a>
					</h4>
					<p className={classes.rjcJobCity}>
						<em>
							{cityIfNotNull(item.city)}{stateFullNameToAcronym(item.state)}
						</em>
					</p>

					<p>
						<ResponsiveEllipsis
							text={strippedHtml}
							maxLine='3'
							ellipsis='...'
							basedOn='letters'
							className={classes.jobDescriptionEllipsis}
						/>
					</p>
					<Button
						variant='contained'
						color='primary'
						data-tag='jobLink'
						data-variant={optimizeVariant}
						data-url={cleanUrl(item.url)}
						href={cleanUrl(item.url)}
					>
						Job details
					</Button>
				</Paper>
			</Grid>
		);
	};

	const rendersingleJob = () => {
		const job = recommendedJobList;

		const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);
		console.log(' rendersingleJob ->  ' + JSON.stringify(job));

		return (
			<Grid container direction='row'>
				{recommendedJobList.length === 1
					? recommendedJobList.map((item) => {
							var strippedHtml = item.jobDescription
								.replace(/<[^>]+>/g, '')
								.toString();
							var displayHeadline = '';

							if (!!item.headline) {
								displayHeadline =
									item.headline.length > 75
										? item.headline.substring(0, 75 - 3) + '...'
										: item.headline;
							}

							return (
								<Grid item xs={12} md={8}>
									<Paper className={classes.rjcCard}>
										<h4 className={classes.rjcJobHeader}>
											<a
												href={item.url}
												data-tag='jobLink'
												data-variant={optimizeVariant}
												data-url={item.url}
											>
												{displayHeadline}
											</a>
										</h4>
										<p className={classes.rjcJobCity}>
											<em>
												{cityIfNotNull(item.city)}{stateFullNameToAcronym(item.state)}
											</em>
										</p>

										<p>
											<ResponsiveEllipsis
												text={strippedHtml}
												maxLine='3'
												ellipsis='...'
												basedOn='letters'
												className={classes.jobDescriptionEllipsis}
											/>
										</p>

										<Button
											variant='contained'
											color='primary'
											data-tag='jobLink'
											data-variant={optimizeVariant}
											data-url={item.url}
											href={cleanUrl(item.url)}
										>
											Job details
										</Button>
									</Paper>
								</Grid>
							);
					  })
					: null}

				<Grid item xs={12} md={4}>
					<Paper
						className={`${classes.rjcCard} ${classes.rjcExtraCard} ${classes.rjcLicenseCard}`}
					>
						<h3 className={classes.rjcExtraCardHeader}>
							{t(`dashboard:INFO.LICENSING_HEAD`)}
						</h3>
						<h5 className='cr-white'>{t(`dashboard:INFO.LICENSING_SUBHEAD`)}</h5>
						<p>{t(`dashboard:INFO.LICENSING_TEXT`)}</p>
						<Button
							className='button--midnight'
							onClick={() => handleExtraTileclick('contact')}
							variant='contained'
						>
							{t(`dashboard:BUTTONS.LICENSING_BTN_TEXT`)}
						</Button>
					</Paper>
				</Grid>
			</Grid>
		);
	};
	// style={{ marginTop: '5%', marginRight: '10%', width: '80%' }}

	const addExtraTiles = (item, index) => {
		var searchTile = false;
		if (item && item.name === 'search') {
			searchTile = true;
		}
		var displayHeadline = '';

		if (!!item.headline) {
			displayHeadline =
				item.headline.length > 75
					? item.headline.substring(0, 75 - 3) + '...'
					: item.headline;
		}

		return (
			<Grid key={`grid-${index}`} item xs={12}>
				<Paper key={`paper-${index}`}
					className={`${classes.rjcCard} ${classes.rjcExtraCard} ${
						searchTile ? classes.rjcMoreJobsCard : classes.rjcLicenseCard
					}`}
				>
					<h3 className={classes.rjcExtraCardHeader}>{displayHeadline}</h3>
					<h5 className='cr-white'>{item.secondaryHeader}</h5>
					<p>{item.details}</p>

					<Button key={`button-${index}`}
						className='button--midnight'
						onClick={() => handleExtraTileclick(item.name)}
						variant='contained'
					>
						{item.buttonText}
					</Button>
				</Paper>
			</Grid>
		);
	};

	const PreviousBtn = (props) => {
		const { onClick } = props;
		return (
			<div className={classes.arrowPrev} onClick={onClick}>
				<ArrowBackIos
					style={{
						fontSize: '16px',
						position: 'absolute',
						top: '25%',
						right: '18%',
						color: 'white',
					}}
				/>
			</div>
		);
	};

	const NextBtn = (props) => {
		const { onClick } = props;
		return (
			<div className={classes.arrowNext} onClick={onClick}>
				<ArrowForwardIos
					style={{
						fontSize: '16px',
						position: 'absolute',
						top: '25%',
						right: '22%',
						color: 'white',
					}}
				/>
			</div>
		);
	};

	if (recommendedJobList.length === 1) {
		return rendersingleJob();
	} else {
		return (
			<>
				<Slider
					{...settings}
					prevArrow={<PreviousBtn />}
					nextArrow={<NextBtn />}
					infinite={false}
					slidesToScroll={1}
				>
					{recommendedJobList.map((item, index) => (
						<Card key={index} item={item} />
					))}
					{recommendedJobList.length > 1
						? additionalTiles.map((item, index) => {
								return addExtraTiles(item, index);
						  })
						: null}
				</Slider>
			</>
		);
	}
}

export default CarouselComponent;
