import axios from 'axios';
import { appInsights } from '../../AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import mySalService from '../../services/msSalService';
import axiosRetry from 'axios-retry';
import { environment } from '../../utils/helpers';
import msSalService from '../../services/msSalService';
import { getConfigurationSetting } from '../../services/AppConfigService';

const INVALID_TOKEN = 'Invalid CpToken';
const useNewAPIs = await getConfigurationSetting('UseNewAPIs', true);
const aCredentialInstanceBaseURL = useNewAPIs
	? process.env.REACT_APP_WC_API_BASE_URI
	: process.env.REACT_APP_CLINICIAN_MANAGEMENT_BASE_API_URI
	+ process.env.REACT_APP_CLINICIAN_MANAGEMENT_BASE_API_VERSION;


function getItemCookie(cName) {
	const name = cName + '=';
	const ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

//const bearer = `Bearer ${getItemCookie('msal.idtoken')}`;

const axiosInstance = axios.create({
	baseURL: useNewAPIs ? process.env.REACT_APP_WC_API_BASE_URI : process.env.REACT_APP_BASE_API_URI,
});

axiosRetry(axiosInstance, {
	retries: 3, // number of retries
	retryDelay: (retryCount) => {
		console.log(`axiosInstance retry attempt: ${retryCount}`);
		return [retryCount * 2 + 1] * 1000; // time interval between retries
	},
	retryCondition: (error) => {
		return (
			axiosInstance &&
			error &&
			error.response &&
			(error.response.status === 404 ||
				error.response.status === 408 ||
				error.response.status === 500)
		);
	},
});

axiosInstance.interceptors.request.use(
	async (config) => {
		const idTokenValue = await getItemCookie('msal.idtoken');

		config.headers = {
			Authorization: `Bearer ${idTokenValue}`,
		};
		return config;
	},
	(error) => {
		if (error) {
			mySalService.logOut();
			appInsights.trackException({
				exception: error,
				properties: {
					method: 'axiosInstance.interceptors.request.use',
					severityLevel: SeverityLevel.Error,
				},
			});
		}
		Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error && error.response && error.response.status === 403) {
			mySalService.logOut();
			appInsights.trackException({
				exception: error,
				properties: {
					method: 'axiosInstance.interceptors.response.use',
					severityLevel: SeverityLevel.Error,
				},
			});
		}
		return Promise.reject(error);
	}
);

axiosInstance.interceptors.request.use(
	async (request) => {
		await mySalService.silentReferesh();
		return request;
	},
	(error) => Promise.reject(error)
);

//*** Document ***
const axiosDocumentInstance = axios.create({
	baseURL: useNewAPIs ? process.env.REACT_APP_WC_API_BASE_URI : process.env.REACT_APP_DOCUMENT_BASE_API_URI,
});

axiosRetry(axiosDocumentInstance, {
	retries: 3, // number of retries
	retryDelay: (retryCount) => {
		console.log(`axiosDocumentInstance retry attempt: ${retryCount}`);
		return [retryCount * 2 + 1] * 1000; // time interval between retries
	},
	retryCondition: (error) => {
		return (
			axiosDocumentInstance &&
			error &&
			error.response &&
			(error.response.status === 404 ||
				error.response.status === 408 ||
				error.response.status === 500)
		);
	},
});

axiosDocumentInstance.interceptors.request.use(
	async (config) => {
		const idTokenValue = await getItemCookie('msal.idtoken');

		config.headers = {
			Authorization: `Bearer ${idTokenValue}`,
		};
		return config;
	},
	(error) => {
		if (error) {
			mySalService.logOut();
			appInsights.trackException({
				exception: error,
				properties: {
					method: 'axiosDocumentInstance.interceptors.request.use',
					severityLevel: SeverityLevel.Error,
				},
			});
		}
		Promise.reject(error);
	}
);

axiosDocumentInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error && error.response && error.response.status === 403) {
			mySalService.logOut();
			appInsights.trackException({
				exception: error,
				properties: {
					method: 'axiosDocumentInstance.interceptors.response.use',
					severityLevel: SeverityLevel.Error,
				},
			});
		}
		return Promise.reject(error);
	}
);

axiosDocumentInstance.interceptors.request.use(
	async (request) => {
		await mySalService.silentReferesh();
		return request;
	},
	(error) => Promise.reject(error)
);

//*** Salesforce ***
const axiosSalesforceInstance = axios.create({
	baseURL: useNewAPIs ? process.env.REACT_APP_WC_API_BASE_URI : process.env.REACT_APP_SALESFORCE_BASE_API_URI,
});

axiosRetry(axiosSalesforceInstance, {
	retries: 3, // number of retries
	retryDelay: (retryCount) => {
		console.log(`axiosSalesforceInstance retry attempt: ${retryCount}`);
		return [retryCount * 2 + 1] * 1000; // time interval between retries
	},
	retryCondition: (error) => {
		return (
			axiosSalesforceInstance &&
			error &&
			error.response &&
			(error.response.status === 404 ||
				error.response.status === 408 ||
				error.response.status === 500)
		);
	},
});

axiosSalesforceInstance.interceptors.request.use(
	async (config) => {
		const idTokenValue = await getItemCookie('msal.idtoken');

		config.headers = {
			Authorization: `Bearer ${idTokenValue}`,
		};
		return config;
	},
	(error) => {
		if (error) {
			mySalService.logOut();
			appInsights.trackException({
				exception: error,
				properties: {
					method: 'axiosSalesforceInstance.interceptors.request.use',
					severityLevel: SeverityLevel.Error,
				},
			});
		}
		Promise.reject(error);
	}
);

axiosSalesforceInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error && error.response && error.response.status === 403) {
			mySalService.logOut();
			appInsights.trackException({
				exception: error,
				properties: {
					method: 'axiosSalesforceInstance.interceptors.response.use',
					severityLevel: SeverityLevel.Error,
				},
			});
		}
		return Promise.reject(error);
	}
);

axiosSalesforceInstance.interceptors.request.use(
	async (request) => {
		await mySalService.silentReferesh();
		return request;
	},
	(error) => Promise.reject(error)
);

//*** Travel ***
const axiosTravelInstance = axios.create({
	baseURL: useNewAPIs ? process.env.REACT_APP_WC_API_BASE_URI : process.env.REACT_APP_TRAVEL_BASE_API_URI,
});

axiosRetry(axiosTravelInstance, {
	retries: 3, // number of retries
	retryDelay: (retryCount) => {
		console.log(`axiosTravelInstance retry attempt: ${retryCount}`);
		return [retryCount * 2 + 1] * 1000; // time interval between retries
	},
	retryCondition: (error) => {
		return (
			axiosTravelInstance &&
			error &&
			error.response &&
			error.response.status &&
			(error.response.status === 404 ||
				error.response.status === 408 ||
				error.response.status === 500)
		);
	},
});

axiosTravelInstance.interceptors.request.use(
	async (config) => {
		const idTokenValue = await getItemCookie('msal.idtoken');

		config.headers = {
			Authorization: `Bearer ${idTokenValue}`,
		};
		return config;
	},
	(error) => {
		if (error) {
			mySalService.logOut();
			appInsights.trackException({
				exception: error,
				properties: {
					method: 'axiosTravelInstance.interceptors.request.use',
					severityLevel: SeverityLevel.Error,
				},
			});
		}
		Promise.reject(error);
	}
);

axiosTravelInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error && error.response && error.response.status === 403) {
			mySalService.logOut();
			appInsights.trackException({
				exception: error,
				properties: {
					method: 'axiosTravelInstance.interceptors.response.use',
					severityLevel: SeverityLevel.Error,
				},
			});
		}
		return Promise.reject(error);
	}
);

axiosTravelInstance.interceptors.request.use(
	async (request) => {
		await mySalService.silentReferesh();
		return request;
	},
	(error) => {
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'axiosTravelInstance.interceptors.request.use',
				severityLevel: SeverityLevel.Error,
			},
		});
		Promise.reject(error);
	}
);

//*** Job Search ****
const axiosJobSeachInstance = axios.create({
	baseURL: useNewAPIs ? process.env.REACT_APP_WC_API_BASE_URI : process.env.REACT_APP_LT_API_URI,
});

//*** Notification ***
const axiosNotificationInstance = axios.create({
	baseURL: useNewAPIs ? process.env.REACT_APP_WC_API_BASE_URI : process.env.REACT_APP_NOTIFICATION_BASE_API_URI,
});

axiosNotificationInstance.interceptors.request.use(
	async (config) => {
		const idTokenValue = await getItemCookie('msal.idtoken');

		config.headers = {
			Authorization: `Bearer ${idTokenValue}`,
		};
		return config;
	},
	(error) => {
		if (error) {
			mySalService.logOut();
			appInsights.trackException({
				exception: error,
				properties: {
					method: 'axiosNotificationInstance.interceptors.request.use',
					severityLevel: SeverityLevel.Error,
				},
			});
		}
		Promise.reject(error);
	}
);

axiosNotificationInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error && error.response && error.response.status === 403) {
			mySalService.logOut();
			appInsights.trackException({
				exception: error,
				properties: {
					method: 'axiosNotificationInstance.interceptors.response.use',
					severityLevel: SeverityLevel.Error,
				},
			});
		}
		return Promise.reject(error);
	}
);

axiosNotificationInstance.interceptors.request.use(
	async (request) => {
		await mySalService.silentReferesh();
		return request;
	},
	(error) => {
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'axiosNotificationInstance.interceptors.request.use',
				severityLevel: SeverityLevel.Error,
			},
		});
		Promise.reject(error);
	}
);

//**** Credentials */

const axiosCredentialInstance = axios.create({
	baseURL: useNewAPIs ? process.env.REACT_APP_WC_API_BASE_URI : aCredentialInstanceBaseURL,
});

axiosRetry(axiosCredentialInstance, {
	retries: 3, // number of retries
	retryDelay: (retryCount) => {
		console.log(`axiosCredentialInstance retry attempt: ${retryCount}`);
		return [retryCount * 2 + 1] * 1000; // time interval between retries
	},
	retryCondition: (error) => {
		return (
			axiosCredentialInstance &&
			error &&
			error.response &&
			(error.response.status === 404 ||
				error.response.status === 408 ||
				error.response.status === 500)
		);
	},
});

axiosCredentialInstance.interceptors.request.use(
	async (config) => {
		const idTokenValue = await getItemCookie('msal.idtoken');

		config.headers = {
			Authorization: `Bearer ${idTokenValue}`,
		};

		//Catch All Check to ensure that clinician is clinicians for the New API calls
		//this can happen (like in the examples below) when the axiosCredentialInstance without
		//going through the credentialingMethodWrapper which also ensures the s
		//promises.push(axiosCredentialInstance.get(`clinician/${clinicianId}/photoInfo`));
		//axiosCredentialInstance.post(`clinician/${externalId}/photo`,
		if (useNewAPIs && config.url?.substring(0, 10) === 'clinician/') {
			config.url = config.url.replace('clinician/', 'clinicians/');
		}

		return config;
	},
	(error) => {
		if (error) {
			mySalService.logOut();
			appInsights.trackException({
				exception: error,
				properties: {
					method: 'axiosCredentialInstance.interceptors.request.use',
					severityLevel: SeverityLevel.Error,
				},
			});
		}
		Promise.reject(error);
	}
);

axiosCredentialInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error && error.response && error.response.status === 403) {
			mySalService.logOut();
			appInsights.trackException({
				exception: error,
				properties: {
					method: 'axiosCredentialInstance.interceptors.response.use',
					severityLevel: SeverityLevel.Error,
				},
			});
		}
		return Promise.reject(error);
	}
);

axiosCredentialInstance.interceptors.request.use(
	async (request) => {
		await mySalService.silentReferesh();
		return request;
	},
	(error) => {
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'axiosCredentialInstance.interceptors.request.use',
				severityLevel: SeverityLevel.Error,
			},
		});
		Promise.reject(error);
	}
);

//**** Credentials Documents*/

const axiosCredentialDocumentsInstance = axios.create({
	baseURL:
		process.env.REACT_APP_CLINICIAN_MANAGEMENT_DOCUMENTS_BASE_API_URI +
		process.env.REACT_APP_CLINICIAN_MANAGEMENT_DOCUMENTS_BASE_API_VERSION,
});

axiosRetry(axiosCredentialDocumentsInstance, {
	retries: 3, // number of retries
	retryDelay: (retryCount) => {
		console.log(`axiosCredentialDocumentsInstance retry attempt: ${retryCount}`);
		return [retryCount * 2 + 1] * 1000; // time interval between retries
	},
	retryCondition: (error) => {
		return (
			axiosCredentialDocumentsInstance &&
			error &&
			error.response &&
			(error.response.status === 404 ||
				error.response.status === 408 ||
				error.response.status === 500)
		);
	},
});

axiosCredentialDocumentsInstance.interceptors.request.use(
	async (config) => {
		const idTokenValue = await getItemCookie('msal.idtoken');

		config.headers = {
			Authorization: `Bearer ${idTokenValue}`,
		};
		return config;
	},
	(error) => {
		if (error) {
			mySalService.logOut();
			appInsights.trackException({
				exception: error,
				properties: {
					method: 'axiosCredentialDocumentsInstance.interceptors.request.use',
					severityLevel: SeverityLevel.Error,
				},
			});
		}
		Promise.reject(error);
	}
);

axiosCredentialDocumentsInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error && error.response && error.response.status === 403) {
			mySalService.logOut();
			appInsights.trackException({
				exception: error,
				properties: {
					method: 'axiosCredentialDocumentsInstance.interceptors.response.use',
					severityLevel: SeverityLevel.Error,
				},
			});
		}
		return Promise.reject(error);
	}
);

axiosCredentialDocumentsInstance.interceptors.request.use(
	async (request) => {
		await mySalService.silentReferesh();
		return request;
	},
	(error) => {
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'axiosCredentialDocumentsInstance.interceptors.request.use',
				severityLevel: SeverityLevel.Error,
			},
		});
		Promise.reject(error);
	}
);

//**** Ltonline */
const axiosLtonlineInstance = axios.create({
	baseURL: useNewAPIs ? process.env.REACT_APP_WC_API_BASE_URI : process.env.REACT_APP_LTONLINE_BASE_API_URI + process.env.REACT_APP_LTONLINE_BASE_API_VERSION
});

axiosLtonlineInstance.interceptors.response.use(
	async (response) => {
		return response;
	},
	(error) => {
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'axiosLtonlineInstance = axios.create',
				severityLevel: SeverityLevel.Error,
			},
		});
		Promise.reject(error);
	}
);

axiosLtonlineInstance.interceptors.request.use(
	async (request) => {
		return request;
	},
	(error) => {
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'axiosLtonlineInstance.interceptors.request.use',
				severityLevel: SeverityLevel.Error,
			},
		});
		Promise.reject(error);
	}
);

//*** WC API ***
const axiosWCApiInstance = axios.create({
	baseURL: process.env.REACT_APP_WC_API_BASE_URI,
});

axiosRetry(axiosWCApiInstance, {
	retries: 3, // number of retries
	retryDelay: (retryCount) => {
		console.log(`axiosWCApiInstance retry attempt: ${retryCount}`);
		return [retryCount * 2 + 1] * 1000; // time interval between retries
	},
	retryCondition: (error) => {
		return (
			axiosWCApiInstance &&
			error &&
			error.response &&
			(error.response.status === 408 || error.response.status === 500)
		);
	},
});

axiosWCApiInstance.interceptors.request.use(
	async (config) => {
		const isAdminApp = process.env.REACT_APP_TYPE === 'Admin';
		const isClinicianApp = process.env.REACT_APP_TYPE === 'Clinician';
		const isClientApp = process.env.REACT_APP_TYPE === 'Client';

		if (environment() === 'LOCAL') {
			if (isClinicianApp) {
				const account = msSalService.myMSALObj.getAccount();
				if (account !== null && account.idToken !== null) {
					const { extension_SfExternalId, sub, emails } = account.idToken;
					const email = !!emails ? emails[0] : 'local@fake.com';
					config.headers = {
						b2cId: sub,
						email: email,
						externalId: extension_SfExternalId.trim(),
						'LT-Roles': 'Clinician',
					};
				}
			} else if (isClientApp) {
				const CpToken = localStorage.getItem('token')
					? localStorage.getItem('token')
					: null;
				config.headers = {
					CpToken: CpToken,
					'LT-Roles': 'ClientApprover',
					'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_WC_API_SUBSCRIPTION_KEY}`,
				};
			} else if (isAdminApp) {
				const { oktaAuthConfig } = require('../../Admin/Configurations/OktaConfig.js');
				const OktaAuth = require('@okta/okta-auth-js').OktaAuth;
				const authClient = new OktaAuth(oktaAuthConfig);
				const idToken = authClient?.getIdToken();
				const Authorization = idToken ? `Bearer ${idToken}` : null;
				config.headers = {
					Authorization,
					'LT-Roles': 'TimesheetAdmin',
					email: 'local@fake.com',
					'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_WC_API_SUBSCRIPTION_KEY}`,
				};
			}
		} else {
			if (isClinicianApp) {
				const idTokenValue = await getItemCookie('msal.idtoken');
				config.headers = {
					Authorization: `Bearer ${idTokenValue}`,
					'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_WC_API_SUBSCRIPTION_KEY}`,
				};
			} else if (isClientApp) {
				const CpToken = localStorage.getItem('token')
					? localStorage.getItem('token')
					: null;
				config.headers = {
					CpToken,
					'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_WC_API_SUBSCRIPTION_KEY}`,
				};
			} else if (isAdminApp) {
				const { oktaAuthConfig } = require('../../Admin/Configurations/OktaConfig.js');
				const OktaAuth = require('@okta/okta-auth-js').OktaAuth;
				const authClient = new OktaAuth(oktaAuthConfig);
				const idToken = authClient?.getIdToken();
				const Authorization = idToken ? `Bearer ${idToken}` : null;
				config.headers = {
					Authorization,
					'LT-Roles': 'LTAssociate',
					'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_WC_API_SUBSCRIPTION_KEY}`,
				};
			}
		}
		return config;
	},
	(error) => {
		if (error) {
			const isClinicianApp = process.env.REACT_APP_TYPE === 'Clinician';
			if (isClinicianApp) {
				mySalService.logOut();
				appInsights.trackException({
					exception: error,
					properties: {
						method: 'axiosWCApiInstance.interceptors.request.use',
						severityLevel: SeverityLevel.Error,
					},
				});
			}
		}
		Promise.reject(error);
	}
);

axiosWCApiInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error && error.response && error.response.status === 403) {
			const isClinicianApp = process.env.REACT_APP_TYPE === 'Clinician';
			if (isClinicianApp) {
				mySalService.logOut();
				appInsights.trackException({
					exception: error,
					properties: {
						method: 'axiosWCApiInstance.interceptors.response.use',
						severityLevel: SeverityLevel.Error,
					},
				});
			}
		}
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.error &&
			error.response.data.error === INVALID_TOKEN
		) {
			window.location.reload();
			return new Promise(() => {});
		}
		return Promise.reject(error);
	}
);

axiosWCApiInstance.interceptors.request.use(
	async (request) => {
		const isClinicianApp = process.env.REACT_APP_TYPE === 'Clinician';
		if (isClinicianApp) {
			await mySalService.silentReferesh();
		}
		return request;
	},
	(error) => Promise.reject(error)
);

//*** Credential Verification ***
const axiosCredentialVerificationInstance = axios.create({
	baseURL:
		process.env.REACT_APP_CREDENTIAL_VERIFICATION_BASE_API_URI +
		process.env.REACT_APP_CREDENTIAL_VERIFICATION_BASE_API_VERSION,
});

axiosCredentialVerificationInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'axiosCredentialVerificationInstance.interceptors.response.use',
				severityLevel: SeverityLevel.Error,
			},
		});

		return Promise.reject(error);
	}
);

axiosCredentialVerificationInstance.interceptors.request.use(
	(config) => {
		config.headers = {
			'x-functions-key': `${process.env.REACT_APP_CREDENTIAL_VERIFICATION_HOST_KEY}`,
		};

		return config;
	},
	(request) => request,
	(error) => {
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'axiosCredentialVerificationInstance.interceptors.request.use',
				severityLevel: SeverityLevel.Error,
			},
		});

		return Promise.reject(error);
	}
);

export {
	axiosInstance,
	axiosSalesforceInstance,
	axiosDocumentInstance,
	axiosTravelInstance,
	axiosJobSeachInstance,
	axiosNotificationInstance,
	axiosCredentialInstance,
	axiosLtonlineInstance,
	axiosCredentialDocumentsInstance,
	axiosWCApiInstance,
	axiosCredentialVerificationInstance,
};
