/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
	withStyles,
	Menu,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import WithContexts from '../../../contexts/withContexts';
import { FormGroup, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import RateDisplayNameEnum from "./RateDisplayName.enum";

const styles = () => ({
	copyADayMenu: {
		"& .MuiList-root": {
			padding: "0"
		},
	},
	menuFormGroup: {
		alignItems: 'left',
		margin: '20px',
	},
	menuFormGroupButton: {
		marginTop: '9px'
	},
});

function CopyADayMenu(props) {
	const { classes, index, timesheet, dateLookup, entryType, anchorEl, setAnchorEl, showCopyADayMenu, setShowCopyADayMenu, isMobile, isMobileUiV2Enabled, generateNextId } =
		props;

	const determineEntryType = () => {
		if (entryType === 'mileage-entry') {
			return 'mileage';
		} else if (entryType === 'timesheet-entry') {
			return 'timesheet';
		}
	};

	const getEntries = () => {
		const determinedEntryType = determineEntryType();
		if (determinedEntryType === 'mileage') {
			return getValues().mileageEntries || [];
		} 
		
		return getValues().timesheetEntries || [];
	};

	const { getValues, setValue, watch } = useFormContext();

	const [selectedCopyADayDates, setSelectedCopyADayDates] = useState([]);

	const watchTransactionDate = watch(`${determineEntryType()}Entries[${index}].transactionDate`);

	const groupEntriesByDay = (entries) => {
		entries = entries ?? getEntries();
		if (!entries) {
			return;
		}

		let entriesByDay = {};

		entries.forEach((entry, index) => {
			const day = moment(entry.transactionDate).format('MM/DD');

			entriesByDay[day] = entriesByDay[day] ?? []

			entry.index = index;
			entriesByDay[day].push(entry);
		});

		return entriesByDay;
	}

	const renderCopyADayCheckbox = (day, entriesByDay) => {
		const formattedDay = day.format('MM/DD');
		const entries = entriesByDay[formattedDay];
		const isDisabled = moment(watchTransactionDate).format('MM/DD') === formattedDay
			|| entries?.some((entry) => entry.rateTypeId || entry.miles > 0) === true;

		return (<FormControlLabel control={<Checkbox color='primary' disabled={isDisabled} onClick={e => copyADayCheckboxChecked(e, day)} />} label={day.format('dddd')} />);
	};

	const copyADayCheckboxChecked = (e, day) => {
		if (e.target.checked) {
			selectedCopyADayDates.push(day);
			selectedCopyADayDates.sort((a, b) => moment(a).format('MMDD') - moment(b).format('MMDD'));
			setSelectedCopyADayDates(selectedCopyADayDates);
		} else {
			setSelectedCopyADayDates(selectedCopyADayDates.filter((selectedDay) => selectedDay.format('MM/DD') != day.format('MM/DD')));
		}
	};

	const handleCopyADayClose = () => {
		setShowCopyADayMenu(false);
		setAnchorEl(null);
		setSelectedCopyADayDates([]);
	};

	const getDays = () => {
		const dayEntries = getEntries();
		if (dayEntries.length <= 0) {
			return []; // If there are no entries, do not do anything.
		}

		let startDate = moment(dateLookup[0].value);
		const weekEnd = moment(timesheet.weekEnding);
		const bookingEnd = moment(timesheet.booking.endDate);
		const checkedEnd = Math.min(bookingEnd, weekEnd);
		
		const days = [moment(startDate)];

		while (startDate < checkedEnd) {
			startDate = startDate.add(1, 'd');
			days.push(moment(startDate));
		}
		
		return days;
	};

	const copyDays = () => {
		const entries = getEntries();
		const sourceDay = moment(entries[index].transactionDate).format('MM/DD');

		let clonedEntries = JSON.parse(JSON.stringify(entries));
		clonedEntries = clonedEntries.sort((a, b) => new Date(a.transactionDate) - new Date(b.transactionDate));

		const entriesByDay = groupEntriesByDay(clonedEntries);
		const sourceDayEntries = entriesByDay[sourceDay];
		const holidayRate = timesheet.booking.availableRates.find(
			(rate) => rate.displayName === RateDisplayNameEnum.HolidayShift
		);
		const readOnlyLocations =
			timesheet.timesheetReviews?.map((review) =>
				review.status !== 'NotStarted' &&
				review.status !== 'PendingSubmission' &&
				review.status !== 'Rejected' &&
				review.status !== 'DidNotWork'
					? review.locationId
					: null
			) || [];
		//Always use 0 for Mileage as generateNextId={generateNextId} is not a property passed into the MileageEntry component
		var idObj = { id: generateNextId ? generateNextId() : 0 };
		selectedCopyADayDates.forEach((day) => {
			const targetDayEntries = entriesByDay[day.format('MM/DD')];
			copyDay(day, sourceDayEntries, targetDayEntries, clonedEntries, holidayRate, readOnlyLocations, idObj);
		});

		clonedEntries = clonedEntries.sort((a, b) => new Date(a.transactionDate) - new Date(b.transactionDate));
		setValue(`${determineEntryType()}Entries`, clonedEntries);

		handleCopyADayClose();
	};

	const copyDay = (day, sourceDayEntries, targetDayEntries, clonedEntries, holidayRate, readOnlyLocations, aIdObj) => {
		sourceDayEntries.forEach((sourceDayEntry, sourceDayEntryIndex) => {
			if (sourceDayEntry.relatedEntryId
				|| (holidayRate?.id && sourceDayEntry.rateTypeId === holidayRate.id)
				|| readOnlyLocations.indexOf(sourceDayEntry.locationId) > -1) {
				return;
			}

			const newEntry = JSON.parse(JSON.stringify(sourceDayEntry));
			newEntry.transactionDate = day.format('YYYY-MM-DDTHH:mm:ss');
			if (newEntry.timesheetBreaks && newEntry.timesheetBreaks.length > 0) {
				newEntry.timesheetBreaks.forEach(element => {
					element.id = 0;
					element.timesheetEntryId = 0;
				});
			}

			if (targetDayEntries && targetDayEntries[sourceDayEntryIndex]) {
				const entriesToRemove = Math.max(targetDayEntries.length - sourceDayEntries.length, 0) + 1;
				newEntry.id = targetDayEntries[sourceDayEntryIndex].id;
				clonedEntries.splice(targetDayEntries[sourceDayEntryIndex].index, entriesToRemove, newEntry);
			} else {
				newEntry.id = aIdObj.id;
				clonedEntries.splice(clonedEntries.length, 0, newEntry);
				//Always use 0 for Mileage as generateNextId={generateNextId} is not a property passed into the MileageEntry component
				if (generateNextId){
					let aNewId = aIdObj.id - 1;
					aIdObj.id = aNewId;
				}
			}
		});
	};

	return (
		<Menu
			className={classes.copyADayMenu}
			id={entryType + '-copy-a-day-menu'}
			anchorEl={anchorEl}
			anchorOrigin={isMobile && isMobileUiV2Enabled ? { vertical: 'center', horizontal: 'center', } : undefined}
			transformOrigin={isMobile && isMobileUiV2Enabled ? { vertical: 'top', horizontal: 'center', } : undefined}
			open={showCopyADayMenu}
			onClose={handleCopyADayClose}
		>
			<FormGroup className={classes.menuFormGroup}>
				{getDays().map((day) => renderCopyADayCheckbox(day, groupEntriesByDay()))}
				<Button
					variant='contained'
					color='primary'
					size='small'
					className={classes.menuFormGroupButton}
					onClick={copyDays}
				>
					Apply
				</Button>
			</FormGroup>
		</Menu>
	);
}

export default WithContexts(withRouter(withTranslation()(withStyles(styles)(CopyADayMenu))));
