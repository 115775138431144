import {
	axiosDocumentInstance,
	axiosCredentialInstance,
	axiosCredentialDocumentsInstance,
	axiosWCApiInstance,
} from '../configuration/axios/axiosConfiguration';
import msalService from '../services/msSalService';
import { ApiError } from '../utils/ApiError';
import { appInsights } from '../AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

// NOTE: All Document API endpoints rely on the identifying info in the token,
// so they don't need to be passed in as params

async function uploadPhotoToSalesforce(externalId, file, metadata) {
	const form = new FormData();
	form.append('clinicianId', externalId);
	form.append('file', file, file.name);

	if (metadata) {
		// NOTE: metadata is optional, but must match sharePoint structure
		form.append('metadata', JSON.stringify(metadata));
	}

	return axiosCredentialInstance.post(
		`clinician/${externalId}/photo`,
		form,
		{
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		}
	);
}

async function uploadDocumentToSharePoint(externalId, documentType, file, metadata) {
	metadata = cleanMetadata(metadata);
	if (!file) return null;

	const form = new FormData();
	form.append('documentType', documentType);
	form.append('file', file, file.name);
	if (metadata) {
		// NOTE: metadata is optional, but must match sharePoint structure
		form.append('metadata', JSON.stringify(metadata));
	}
	const response = await axiosWCApiInstance.post(
		`clinicians/${externalId}/Documents`,
		form,
		{
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		}
	);

	return response;
}

/**
 * Uploads file to document library with given metadata
 * @param documentType must match the enumeration under Model/Documents/DocumentType.cs
 * @param metadata keys map to the columns of the document library
 */
async function uploadDocumentWCAPI(documentType, file, metadata) {
	try {
		const externalId = getExternalId();
		if (documentType === 'Photo') {
			const salesforceResponse = await uploadPhotoToSalesforce(externalId, file, metadata);

			if (salesforceResponse?.status !== 200)
				return salesforceResponse?.status;
		}

		const sharePointResponse = await uploadDocumentToSharePoint(externalId, documentType, file, metadata);

		return sharePointResponse.status;
	} catch (error) {
		console.log('uploadDocumentWCAPI error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'uploadDocumentWCAPI', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'uploadDocumentWCAPI'
			);
		}
	}
}

/**
 * Uploads file to document library with given metadata
 * @param documentType must match the enumeration under Model/Documents/DocumentType.cs
 * @param metadata keys map to the columns of the document library
 */
async function uploadDocument(documentType, file, metadata) {
	try {
		if (documentType === 'Photo') {
			const externalId = getExternalId();

			const form = new FormData();
			form.append('clinicianId', externalId);
			form.append('file', file, file.name);
			if (metadata) {
				// NOTE: metadata is optional, but must match sharePoint structure
				form.append('metadata', JSON.stringify(metadata));
			}
			const response = await axiosCredentialInstance.post(
				`clinician/${externalId}/photo`,
				form,
				{
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				}
			);
			return response?.status;
		} else {
			metadata = cleanMetadata(metadata);
			if (!file) return null;

			const form = new FormData();
			form.append('documentType', documentType);
			form.append('file', file, file.name);
			if (metadata) {
				// NOTE: metadata is optional, but must match sharePoint structure
				form.append('metadata', JSON.stringify(metadata));
			}
			const response = await axiosDocumentInstance.post('UploadDocument', form, {
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			});
			return response.status;
		}
	} catch (error) {
		console.log('uploadDocument error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'uploadDocument', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'uploadDocument'
			);
		}
	}
	// catch (error) {
	//     Error(error);
	//     return 'failed';
	// }
}

function getExternalId() {
	const account = msalService.getAccount || null;
	const id = account?.idTokenClaims?.extension_SfExternalId;
	if (!id) return null;
	return id.trim();
}

/**
 * Downloads matching document
 */
async function downloadDocument(item, documentType) {
	try {
		if (item.metaData.downloadUrl === '') {
			const externalId = getExternalId();
			const response = await axiosCredentialInstance.get(`clinician/${externalId}/photo`);
			if(!!response?.data?.image) {
				const byteChars = atob(response.data.image);
				const byteNumbers = new Array(byteChars.length);
				for (let i = 0; i < byteChars.length; i++) {
					byteNumbers[i] = byteChars.charCodeAt(i);
				}
				const byteArray = new Uint8Array(byteNumbers);
				const blob = new Blob([byteArray], {
					type: 'application/' + response.data.fileExtension,
				});
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = response.data.fileName;
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
			}
		} else {
			const documentId = item.metaData.name;
			const params = {
				documentId,
				documentType,
			};
			await axiosDocumentInstance
				.get('DownloadDocument', {
					params,
					responseType: 'blob',
				})
				.then((response) => {
					const blob = new Blob([response.data], { type: item.metaData.mimeType });
					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = documentId;
					document.body.appendChild(link);
					link.click();
					link.parentNode.removeChild(link);
				});
		}
	} catch (error) {
		console.log('downloadDocument error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'downloadDocument', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'downloadDocument'
			);
		}
	}
}

/**
 * Downloads matching document
 */
async function downloadDocumentWCAPI(item, documentType) {
	try {
		const externalId = getExternalId();
		if (documentType === 'Photo') {
			const response = await axiosCredentialInstance.get(`clinician/${externalId}/photo`);
			if (!!response && !!response.data && !!response.data.image) {
				const byteChars = atob(response.data.image);
				const byteNumbers = new Array(byteChars.length);
				for (let i = 0; i < byteChars.length; i++) {
					byteNumbers[i] = byteChars.charCodeAt(i);
				}
				const byteArray = new Uint8Array(byteNumbers);
				const blob = new Blob([byteArray], {
					type: 'application/' + response.data.fileExtension,
				});
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = response.data.fileName;
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
			}
		} else {
			const documentName = item.metaData.name;
			const params = {
				documentName,
				documentType,
			};
			await axiosWCApiInstance
				.get(`clinicians/${externalId}/DocumentContent`, {
					params,
					responseType: 'blob',
				})
				.then((response) => {
					const blob = new Blob([response.data], { type: item.metaData.mimeType });
					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = documentName;
					document.body.appendChild(link);
					link.click();
					link.parentNode.removeChild(link);
				});
		}
	} catch (error) {
		console.log('downloadDocumentWCAPI error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'downloadDocumentWCAPI', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'downloadDocumentWCAPI'
			);
		}
	}
}

async function deleteDocument(documentType, document) {
	const documentName = document.metaData.name;
	await deleteDocumentByName(documentType, documentName);
}

async function deleteDocumentByName(documentType, documentName) {
	try {
		if (documentType === 'Photo') {
			const externalId = getExternalId();
			await axiosCredentialInstance.delete(`clinician/${externalId}/photo`);
		} else {
		const params = {
			documentType,
			documentName,
		};
		await axiosDocumentInstance.delete('DeleteDocument', {
			params,
		});
		}
	} catch (error) {
		console.log('deleteDocumentByName error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'deleteDocumentByName', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'deleteDocumentByName'
			);
		}
	}
}

async function deleteDocumentV2(documentType, document) {
	try {
		if (documentType === 'Photo') {
			const externalId = getExternalId();
			await axiosCredentialInstance.delete(`clinician/${externalId}/photo`);
		} else {
		const documentId = document.sharepointUniqueId;
		const isDeleted = 'true';
		const params = {
			documentType,
			documentId,
			isDeleted,
		};
		await axiosDocumentInstance.delete('v2/DeleteDocument', {
			params,
		});
		}
	} catch (error) {
		console.log('deleteDocumentV2  error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'deleteDocumentV2', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'deleteDocumentV2'
			);
		}
	}
}

async function deleteMultipleDocuments(documentType, documents) {
	await asyncForEach(documents, async (item) => {
		const documentName = item.metaData.name;
		await deleteDocumentByName(documentType, documentName);
	});
}

async function deleteMultipleDocumentsV2(documentType, documents) {
	await asyncForEach(documents, async (item) => {
		const documentId = document.sharepointUniqueId;
		const isDeleted = 'true';
		const params = {
			documentType,
			documentId,
			isDeleted,
		};
		try {
			await axiosDocumentInstance.delete('v2/DeleteDocument', {
				params,
			});
		} catch (error) {
			console.log('v2/DeleteDocument  error=' + error);
			appInsights.trackException({
				exception: error,
				properties: {
					method: 'deleteMultipleDocumentsV2',
					severityLevel: SeverityLevel.Error,
				},
			});
			if (!!error.response && !!error.response.data) {
				console.log('throwing ApiError:' + JSON.stringify(error.response.data));
				throw new ApiError(error.response.data);
			} else if (!!error.message) {
				console.log('throwing ApiError:' + JSON.stringify(error.message));
				throw new ApiError(error.message);
			} else {
				throw ApiError.UnwrappedError(
					'Server Error',
					'There was a problem processing that request.',
					'v2/DeleteDocument '
				);
			}
		}
	});
}

// helpers

/**
 * Cleans up the keys in the metadata field because sharepoint doesn't like spaces in column names
 * This enables consuming components of this service to send in 'Document Type' instead of 'Document_x0020_Type'
 */
function cleanMetadata(metadata) {
	const newMetadata = {};
	Object.keys(metadata).map((key) => {
		const newKey = key.split(' ').join('_x0020_');
		newMetadata[newKey] = metadata[key];
		return newMetadata;
	});
	return newMetadata;
}

/**
 * Method returns documents attached to an assignment
 * @param {String} assignmentId
 */
async function getDocumentsFromAssignment(assignmentId) {
	let response;
	const params = {
		assignmentId,
	};
	try {
		response = await axiosDocumentInstance.get('GetAssignmentDocuments', {
			params,
		});
	} catch (error) {
		console.log('getDocumentsFromAssignment error=' + error);
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'getDocumentsFromAssignment',
				severityLevel: SeverityLevel.Error,
			},
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getDocumentsFromAssignment'
			);
		}
	}
	return response;
}

async function getDocumentsFromAssignmentWC(externalId, assignmentId) {
	let response;
	try {
		response = await axiosWCApiInstance(
			`clinicians/${externalId}/Assignments/${assignmentId}/Documents`
		);
	} catch (error) {
		console.log('getDocumentsFromAssignmentWC error=' + error);
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'getDocumentsFromAssignmentWC',
				severityLevel: SeverityLevel.Error,
			},
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getDocumentsFromAssignmentWC'
			);
		}
	}
	return response;
}

/* Data returned is in the following format:

	{
		"id": "014BUWSNV2IK2AZNYA7BHJUZMRUXCWC7B3",
		"sharepointUniqueId": "0cb442ba-00b7-4ef8-9a65-91a5c5617c3b",
		"metaData": {
			"url": "/clinicianportaldev/cvs/CVs/Vance-Kessler-1544406/Keenadon.jpg",
			"name": "Keenadon.jpg",
			"downloadUrl": "/clinicianportaldev/cvs/_layouts/15/download.aspx?UniqueId=0cb442ba-00b7-4ef8-9a65-91a5c5617c3b&Translate=false&tempauth=eyJ0eXAiOiJKV1QiLCJhbGciOiJub25lIn0.eyJhdWQiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAvamhjbG91ZC5zaGFyZXBvaW50LmNvbUA4ZjEzMjhjOC01ZGRkLTQyYjQtODMwOC02MGVhN2U2OGNiMzciLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNjAyMjQ2Nzk1IiwiZXhwIjoiMTYwMjI1MDM5NSIsImVuZHBvaW50dXJsIjoiM2QrQUpVc3N2NlBaQi9BZjc2SW5iU3NJWFV6REJlZHZmNDRKbTI5Z1cwcz0iLCJlbmRwb2ludHVybExlbmd0aCI6IjE1MCIsImlzbG9vcGJhY2siOiJUcnVlIiwiY2lkIjoiT0RNMk0ySXlNMk10Tm1VeFl5MDBNemRqTFdJMFlUa3RZVFZoTXpObE4yVm1Oall6IiwidmVyIjoiaGFzaGVkcHJvb2Z0b2tlbiIsInNpdGVpZCI6IlpXTmxNVFZrTkRndFkyRTJZeTAwWXpVeUxXRmlaamd0TW1JME56RXpObVUxWVRJMyIsImFwcF9kaXNwbGF5bmFtZSI6IkRvY3VtZW50IEFQSSBQb0MgLSBMVCIsImdpdmVuX25hbWUiOiJDbGluaWNpYW4gUG9ydGFsIiwiZmFtaWx5X25hbWUiOiJUZXN0MiIsImFwcGlkIjoiYTg3MjQ2ZWYtZDNlNC00ZDRhLWEzY2YtNDBiNTQ5NzVkMzVlIiwidGlkIjoiOGYxMzI4YzgtNWRkZC00MmI0LTgzMDgtNjBlYTdlNjhjYjM3IiwidXBuIjoiY3B0ZXN0MkBqaGNsb3VkLm9ubWljcm9zb2Z0LmNvbSIsInB1aWQiOiIxMDAzMjAwMDgxMDVBOUZBIiwiY2FjaGVrZXkiOiIwaC5mfG1lbWJlcnNoaXB8MTAwMzIwMDA4MTA1YTlmYUBsaXZlLmNvbSIsInNjcCI6ImFsbHNpdGVzLm1hbmFnZSBhbGxwcm9maWxlcy5yZWFkIiwidHQiOiIyIiwidXNlUGVyc2lzdGVudENvb2tpZSI6bnVsbH0.c2dMeWliWTM2TnJrWmgvTmczcFQyQVUrL0RoZ3F2aGlJLzR0Z2FEbzJnOD0&ApiVersion=2.0",
			"mimeType": "image/jpeg",
			"created": "2020-10-07T19:27:08",
			"additionalData": {
				"@odata.etag": "\"0cb442ba-00b7-4ef8-9a65-91a5c5617c3b,4\"",
				"FileLeafRef": "Keenadon.jpg",
				"Clinician_x0020_Id": 1544406.0,
				"Primary_x0020_Specialty": "Anesthesiology",
				"NCQA": false,
				"Document_x0020_Type": "Curriculum Vitae",
				"Inactive": false,
				"ContentType": "CV",
				"Created": "2020-10-07T19:27:08Z",
				"AuthorLookupId": "280",
				"Modified": "2020-10-07T19:27:52Z",
				"EditorLookupId": "280",
				"_CheckinComment": "",
				"LinkFilenameNoMenu": "Keenadon.jpg",
				"LinkFilename": "Keenadon.jpg",
				"DocIcon": "jpg",
				"FileSizeDisplay": "34683",
				"ItemChildCount": "0",
				"FolderChildCount": "0",
				"_ComplianceFlags": "",
				"_ComplianceTag": "",
				"_ComplianceTagWrittenTime": "",
				"_ComplianceTagUserId": "",
				"_CommentCount": "",
				"_LikeCount": "",
				"_DisplayName": "",
				"Edit": "0",
				"_UIVersionString": "4.0",
				"ParentVersionStringLookupId": "385",
				"ParentLeafNameLookupId": "385"
			}
		},
		"parentLibrary": {
			"id": "b!SF3h7GzKUkyr-CtHE25aJwzse9Fj5_tGup2E_K2lc6hpX4Nl4dI6SJGXw9s8zx5j",
			"name": "CVs\\External",
			"libraryType": "documentLibrary"
		}
	}
 * */
async function getDocumentsForTypes(clinicianId, documentTypes) {
	console.log('Getting ' + documentTypes + ' for ' + clinicianId);
	const promises = [];
	let response = null;
	try {
		let workingTypes = [...documentTypes];
			promises.push(null); // need something for the return value
		if (workingTypes.length > 0) {
			const docTypes = workingTypes.join(';');
			const params = {
				documentTypes: docTypes,
			};
			promises.push(
				axiosCredentialDocumentsInstance.get(`clinician/${clinicianId}/documents`, {
					params,
				})
			);
		} else {
			promises.push(null); // need something for the return value
		}

		await Promise.all(promises).then(([photoResponse, pResponse]) => {
			response = pResponse;
			if (!response) response = {};
			if (!response.data) response.data = [];
			if (!!photoResponse && !!photoResponse.data) {
				const photoJson = {
					id: clinicianId,
					sharepointUniqueId: clinicianId,
					metaData: {
						url: '',
						name: photoResponse.data.fileName,
						downloadUrl: '',
						mimeType: 'application/' + photoResponse.data.fileExtension,
						created: photoResponse.data.createdDate,
						additionalData: {
							Clinician_x0020_Id: clinicianId,
							Document_x0020_Type: 'Photo',
							Created: photoResponse.data.createdDate,
							Modified: photoResponse.data.createdDate,
							FileSizeDisplay: photoResponse.data.fileSize,
						},
					},
				};
				console.log('adding photo:' + JSON.stringify(photoJson));
				response.data.push(photoJson);
			}
		});
	} catch (error) {
		console.log('getDocumentsForTypes error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'getDocumentsForTypes', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getDocumentsForTypes'
			);
		}	
	}
	if (!response || !response.data) return [];
	return response.data;
}

async function getDocumentsForTypesWCAPI(clinicianId, documentTypes) {
	console.log('Getting ' + documentTypes + ' for ' + clinicianId);

	try {
		const workingTypes = [...documentTypes];
		if (!workingTypes || workingTypes.length === 0)
			return [];

		const params = {
			documentTypes: workingTypes.join(';'),
		};

		const response = await axiosWCApiInstance.get(`clinicians/${clinicianId}/Documents`, { params });
		if (!response?.data)
			return [];

		return response.data;
	} catch (error) {
		console.log('getDocumentsForTypesWCAPI error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'getDocumentsForTypesWCAPI', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getDocumentsForTypesWCAPI'
			);
		}
	}
}

async function asyncForEach(array, callback) {
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index, array);
	}
}

async function getAdobeSignDocumentUrl(clinicianId, agreementId, senderEmail) {
	let response;
	try {
		response = await axiosWCApiInstance.get(
			`/clinicians/${clinicianId}/adobesign/${agreementId}/${senderEmail}`
		);
	} catch (error) {
		console.log('getAdobeSignDocumentUrl error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'getAdobeSignDocumentUrl', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getAdobeSignDocumentUrl'
			);
		}
	}
	if (!response || !response.data) return '';
	return response?.data;
}

export {
	uploadDocument,
	downloadDocument,
	deleteDocument,
	deleteDocumentV2,
	deleteMultipleDocuments,
	deleteMultipleDocumentsV2,
	getDocumentsFromAssignment,
	getDocumentsFromAssignmentWC,
	getDocumentsForTypes,
	getAdobeSignDocumentUrl,
	uploadDocumentWCAPI,
	downloadDocumentWCAPI,
	getDocumentsForTypesWCAPI,
};