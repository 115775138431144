import React, { Component } from 'react';
import AppRouter from '../../router/AppRouter';
import PageContext from '../../contexts/pageContext';
import { Offline } from 'react-detect-offline';
import { withStyles, Dialog, DialogTitle, DialogContent, Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import WithContexts from '../../contexts/withContexts';
import { COLORS } from '../../utils/Application_Constants';

const styles = (theme) => ({
	root: {
		padding: '80px 0',
		minHeight: '800px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '60px',
			marginTop: '25px',
		},
		backgroundColor: COLORS.BLACK_HAZE,
	},
	progressButton: {
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		whiteSpace: 'nowrap',
	},
	dialogAction: {
		marginLeft: '10rem',
	},
});

class PlainLayout extends Component {
	constructor(props, context) {
		super(props);
		this.state = {
			pageName: '',
			openOfflineDialog: true,
		};
		context.setPageName = this.setPageName;
		this.idleTimer = null;
	}

	setPageName = (pageName) => {
		this.setState({ pageName: pageName });
	};

	toggleOfflineDialog = (view) => {
		this.setState({ openOfflineDialog: view });
	};

	renderOfflineContent = () => {
		const { openOfflineDialog } = this.state;
		return (
			<Dialog
				open={openOfflineDialog}
				scroll='body'
				maxWidth={'md'}
				fullWidth
				onClose={() => this.toggleOfflineDialog(false)}
			>
				<DialogTitle style={{ padding: '8px 16px' }}>
					<Grid item xs={12}>
						<h1>Offline Notification Message</h1>
					</Grid>
				</DialogTitle>
				<DialogContent style={{ padding: '8px 16px' }}>
					<h3>You are offline, Please check your internet connection</h3>
				</DialogContent>
			</Dialog>
		);
	};

	render() {
		return (
			<>
				<AppRouter />
				<Offline polling={{ url: 'https://ipv4.icanhazip.com' }}>
					{this.renderOfflineContent()}
				</Offline>
			</>
		);
	}
}

PlainLayout.contextType = PageContext;
//export default PlainLayout;

export default WithContexts(withTranslation()(withStyles(styles)(PlainLayout)));
