import {
	axiosCredentialInstance,
	axiosCredentialDocumentsInstance,
	axiosWCApiInstance,
} from '../configuration/axios/axiosConfiguration';
import { axiosSalesforceInstance } from '../configuration/axios/axiosConfiguration';
import { ApiError } from '../utils/ApiError';
import { appInsights } from '../AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { getConfigurationSetting } from './AppConfigService';

const useNewAPIs = await getConfigurationSetting('UseNewAPIs', true);

async function credentialingMethodWrapper(clinicianId, params, method, module) {
	// Type Parameter will define if Get or Post needs to be called.
	//Module will define credentialing, demographics,  training and so on.
	// Params will define any parameter dto associated with the request.

	let response = null;
	let url = '';
	if (useNewAPIs) {
		url = `clinicians/${clinicianId}`;
	} else {
		url = `clinician/${clinicianId}`;
	}

	if (!!module) {
		url = url + `/${module}`;
	}else {
		if (useNewAPIs) url = url + `/credapp`;//default the module to credapp in New APIs if its not passed in
	}

    const mappedParams = params;
	try {
		if (method === 'GET') {
			response = await axiosCredentialInstance.get(url);
			if (useNewAPIs) {
				if (module === 'credentialing') {
					//Map Names
					if (response.data.receivedDate) {
						response.data.credPackReceivedDate = response.data.receivedDate;
					}
					if (response.data.fileName) {
						response.data.credAppFileName = response.data.fileName;
					}
					//MAP Names
				}
			}
		} else if (method === 'POST') {
			if (useNewAPIs) {
				if (module === 'credentialing') {
					//Map Names
					if (params && params.credPackReceivedDate) {
						mappedParams.receivedDate = params.credPackReceivedDate;
					}
					if (params && params.credAppFileName) {
						mappedParams.fileName = params.credAppFileName;
					}
					//MAP Names
				}
			}
			response = await axiosCredentialInstance.post(url, mappedParams);
		} else if (method === 'PATCH') {
			if (useNewAPIs) {
				if (module === 'credentialing') {
					//Map Names
					if (params.credPackReceivedDate) {
						mappedParams.receivedDate = params.credPackReceivedDate;
					}
					if (params.credAppFileName) {
						mappedParams.fileName = params.credAppFileName;
					}
					//MAP Names
				}
			}
			response = await axiosCredentialInstance.patch(url, mappedParams);
		}
	} catch (error) {
		console.log(`${module} error=` + error);
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'credentialingMethodWrapper',
				severityLevel: SeverityLevel.Error,
			},
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				`${module}`
			);
		}
	}
	return response;
}

async function credentialingDocumentsMethodWrapper(clinicianId, params, method, module) {
	// Type Parameter will define if Get or Post needs to be called.
	//Module will define credentialing, demographics,  training and so on.
	// Params will define any parameter dto associated with the request.

	let response = null;
	let url = `clinician/${clinicianId}`;
	if (!!module) url = url + `/${module}`;

	try {
		if (method === 'GET') {
			response = await axiosCredentialDocumentsInstance.get(url);
		} else if (method === 'POST') {
			response = await axiosCredentialDocumentsInstance.post(url, params);
		} else if (method === 'PATCH') {
			response = await axiosCredentialDocumentsInstance.patch(url, params);
		}
	} catch (error) {
		console.log(`${module} error=` + error);
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'credentialingDocumentsMethodWrapper',
				severityLevel: SeverityLevel.Error,
			},
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				`${module}`
			);
		}
	}
	return response;
}

async function getCredAppStatus(clinicianId) {
	let response = null;
	try {
		response = await credentialingMethodWrapper(clinicianId, null, 'GET', 'credentialing');
		return response?.data;
	} catch (e) {
		return null;
	}
}

async function postCredAppStatus(clinicianId, dto) {
	let response = null;
	response = await credentialingMethodWrapper(clinicianId, dto, 'POST', 'credentialing');
	return response?.data;
}

async function getPersonalInformation(clinicianId) {
	let response;
	response = await credentialingMethodWrapper(clinicianId, null, 'GET', 'demographics');

	if (!response) return [];
	return response.data;
}

/**
 * Method puts the post clinicain personal information.
 * @param {*} account
 */
async function postPersonalInformation(personalInfo, clinicianId) {
	await credentialingMethodWrapper(clinicianId, personalInfo, 'POST', 'demographics');
}

async function patchCredAppStatus(clinicianId, dto) {
	let response = null;
	try {
		response = await axiosCredentialInstance.patch(
			`clinician/${clinicianId}/credentialing`,
			dto
		);
	} catch (error) {
		console.log('patchCredAppStatus error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'patchCredAppStatus', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'patchCredAppStatus'
			);
		}
	}
	return response;
}

async function patchPercentComplete(credStatusObject, clinicianId) {
	try {
		await axiosCredentialInstance.patch(
			`clinician/${clinicianId}/credentialing`,
			credStatusObject
		);
	} catch (error) {
		console.log('patchPercentComplete error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'patchPercentComplete', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'patchPercentComplete'
			);
		}
	}
}

async function getEducation(clinicianId) {
	let response;
	response = await credentialingMethodWrapper(clinicianId, null, 'GET', 'educations');

	if (!response) return null;
	return response?.data;
}

async function postEducation(clinicianId, saveData) {
	await credentialingMethodWrapper(clinicianId, saveData, 'POST', 'educations');
}

async function getTraining(clinicianId, type) {
	let response;

	response = await credentialingMethodWrapper(clinicianId, null, 'GET', type);

	if (!response) return [];
	return response.data;
}

async function postTraining(clinicianId, payload, type) {
	await credentialingMethodWrapper(clinicianId, payload, 'POST', type);
}

async function getMalpracticeCarriers(clinicianId, type = 'malpracticecarriers') {
	let response;

	response = await credentialingMethodWrapper(clinicianId, null, 'GET', type);

	if (!response || response.data.clinicianId === null) return [];
	return response.data;
}

async function postMalpracticeCarriers(clinicianId, payload, type) {
	await credentialingMethodWrapper(clinicianId, payload, 'POST', type);
}

async function getClinicianReferences(clinicianId, type) {
	let response;

	response = await credentialingMethodWrapper(clinicianId, null, 'GET', type);

	if (!response || response.data.clinicianId === null) return [];
	return response.data;
}

async function postClinicianReferences(clinicianId, payload, type) {
	await credentialingMethodWrapper(clinicianId, payload, 'POST', type);
}

async function getMalpracticeClaimHistories(clinicianId, type) {
	let response;
	response = await credentialingMethodWrapper(clinicianId, null, 'GET', type);
	if (!response || response.data.clinicianId === null) return [];
	return response.data;
}

async function postMalpracticeClaimHistories(clinicianId, payload, type) {
	await credentialingMethodWrapper(clinicianId, payload, 'POST', type);
}

async function getDisclosureQuestions(clinicianId) {
	let response;

	response = await credentialingMethodWrapper(clinicianId, null, 'GET', 'attestations');

	if (!response || response.data.clinicianId === null) return [];
	return response.data;
}

async function postDisclosureQuestions(clinicianId, payload) {
	await credentialingMethodWrapper(clinicianId, payload, 'POST', 'attestations');
}

async function patchDisclosureQuestions(clinicianId, payload) {
	await credentialingMethodWrapper(clinicianId, payload, 'PATCH', 'attestations');
}

async function getBoardCertifications(clinicianId) {
	let response;

	response = await credentialingMethodWrapper(clinicianId, null, 'GET', 'boardcertifications');

	if (!response) return null;
	return response?.data;
}

async function postBoardCertifications(clinicianId, saveData) {
	await credentialingMethodWrapper(clinicianId, saveData, 'POST', 'boardcertifications');
}

async function getOtherCertifications(clinicianId) {
	let response;

	response = await credentialingMethodWrapper(clinicianId, null, 'GET', 'certifications');

	if (!response) return null;
	return response?.data;
}

async function postOtherCertifications(clinicianId, saveData) {
	await credentialingMethodWrapper(clinicianId, saveData, 'POST', 'certifications');
}

async function getCertificationExams(clinicianId) {
	let response;
	response = await credentialingMethodWrapper(clinicianId, null, 'GET', 'examinations');
	if (!response) return [];
	return response.data;
}

async function postCertificationExams(clinicianId, saveData) {
	await credentialingMethodWrapper(clinicianId, saveData, 'POST', 'examinations');
}

async function getWorkHistories(clinicianId, type) {
	let response;

	response = await credentialingMethodWrapper(clinicianId, null, 'GET', type);

	if (!response) return [];
	return response.data;
}

async function postWorkHistories(clinicianId, payload, type) {
	await credentialingMethodWrapper(clinicianId, payload, 'POST', type);
}

async function getLicensure(clinicianId) {
	let response;

	response = await credentialingMethodWrapper(clinicianId, null, 'GET', 'licensures');

	if (!response) return [];
	return response.data;
}

async function postLicensure(clinicianId, saveData) {
	await credentialingMethodWrapper(clinicianId, saveData, 'POST', 'licensures');
}

async function downloadDocument(clinicianId, documentType, documentId) {
	let response;
	const params = {
		documentType: documentType,
	};
	try {
		response = await axiosCredentialDocumentsInstance.get(
			`clinician/${clinicianId}/documents/${documentId}`,
			{
				responseType: 'blob',
				params,
			}
		);
	} catch (error) {
		console.log('getDocuments error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'downloadDocument', severityLevel: SeverityLevel.Error },
		});
	}
	if (!response || !response.data) return [];
	return response?.data;
}

async function deleteDocument(clinicianId, documentId, documentName, documentType) { 
	try {
		if (documentType === 'Photo') {
			console.log('Deleting photo from Salesforce');
			await axiosCredentialInstance.delete(`clinician/${clinicianId}/photo`);
		} else {
			const params = {
				documentId: documentId,
				documentName: documentName,
				documentType: documentType,
			};
			await axiosCredentialDocumentsInstance.delete(`clinician/${clinicianId}/documents`, {
				params,
			});
		}
	} catch (error) {
		console.log('deleteDocument error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'deleteDocument', severityLevel: SeverityLevel.Error },
		});
		return error.message;
	}
	return null;
}

async function deletePhotoFromSalesforce(clinicianId) {
	console.log('Deleting photo from Salesforce');
	await axiosCredentialInstance.delete(`clinician/${clinicianId}/photo`);
}

async function deleteDocumentFromSharePoint(clinicianId, documentId, documentName, documentType) {
	const params = {
		documentId,
		documentName,
		documentType,
	};

	await axiosWCApiInstance.delete(`clinicians/${clinicianId}/Documents`, {
		params,
	});
}

async function deleteDocumentWCAPI(clinicianId, documentId, documentName, documentType) {
	try {
		if (documentType === 'Photo') {
			await deletePhotoFromSalesforce(clinicianId);
		}

		await deleteDocumentFromSharePoint(clinicianId, documentId, documentName, documentType);
	} catch (error) {
		console.log('deleteDocumentWCAPI error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'deleteDocumentWCAPI', severityLevel: SeverityLevel.Error },
		});
		return error.message;
	}
	return null;
}

async function getGapsByType(clinicianId, type) {
	let response;
	response = await credentialingMethodWrapper(clinicianId, null, 'GET', type);

	if (!response) return [];
	return response.data;
}

async function postGapsByType(clinicianId, payload, type) {
	await credentialingMethodWrapper(clinicianId, payload, 'POST', type);
}

async function getClinicianCredApp(clinicianId) {
	let response;
	response = await credentialingMethodWrapper(clinicianId, null, 'GET', null);

	if (!response || response.data.clinicianId === null) return null;
	return response.data;
}

async function getClinicianCredAppCreatePdf(clinicianId) {
	let response;
	response = await credentialingMethodWrapper(clinicianId, null, 'POST', 'pdf');
	if (!response || !response.data) return [];
	return response?.data;
}

async function getClinicianCredAppCreateReAttestPdf(clinicianId) {
	let response;
	response = await credentialingDocumentsMethodWrapper(clinicianId, null, 'POST', 'reattest/pdf');
	if (!response || !response.data) return [];
	return response?.data;
}

async function getClinicianCredAppPdfStatus(clinicianId, credentialingId) {
	let response;
	try {
		response = await axiosCredentialInstance.get(
			`clinician/${clinicianId}/pdf/${credentialingId}`
		);
		//alert('credentialingId status' + JSON.stringify(response.data))
	} catch (error) {
		console.log('getClinicianCredAppPdfStatus error=' + error);
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'getClinicianCredAppPdfStatus',
				severityLevel: SeverityLevel.Error,
			},
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getClinicianCredAppPdfStatus'
			);
		}
	}
	// if (!response || !response.data) return [];
	return response?.data;
}

async function getClinicianCredAppSigningUrl(clinicianId, attachmentId) {
	let response;
	try {
		response = await axiosCredentialInstance.post(
			`clinician/${clinicianId}/signingUrl/${attachmentId}`
		);
		// alert('getClinicianCredAppSigningUrl URL' + JSON.stringify(response.data))
	} catch (error) {
		console.log('getClinicianCredAppSigningUrl error=' + error);
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'getClinicianCredAppSigningUrl',
				severityLevel: SeverityLevel.Error,
			},
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getClinicianCredAppSigningUrl'
			);
		}
	}
	if (!response || !response.data) return '';
	return response?.data;
}

async function getClinicianCredAppDocumentURL(clinicianId) {
	let response;
	try {
		response = await axiosCredentialDocumentsInstance.get(
			`clinician/${clinicianId}/credentialing/document`
		);
	} catch (error) {
		console.log('getClinicianCredAppDocumentURL error=' + error);
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'getClinicianCredAppDocumentURL',
				severityLevel: SeverityLevel.Error,
			},
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getClinicianCredAppDocumentURL'
			);
		}
	}
	return response?.data;
}

async function getClinicianCredAppDocumentLastApprovedURL(clinicianId) {
	let response;
	try {
		response = await axiosCredentialDocumentsInstance.get(
			`clinician/${clinicianId}/credentialing/documentlastapproved`
		);
	} catch (error) {
		console.log('getClinicianCredAppDocumentLastApprovedURL error=' + error);
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'getClinicianCredAppDocumentLastApprovedURL',
				severityLevel: SeverityLevel.Error,
			},
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getClinicianCredAppDocumentLastApprovedURL'
			);
		}
	}
	return response?.data;
}

async function deleteOtherCertifications(clinicianId, deleteRecords) {
	try {
		await axiosCredentialInstance.delete(`clinician/${clinicianId}/certifications`, {
			data: deleteRecords,
		});
	} catch (error) {
		console.log('deleteOtherCertifications error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'deleteOtherCertifications', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'deleteOtherCertifications'
			);
		}
		//return error.message;
	}
	return null;
}

async function deleteCertificationExams(clinicianId, deleteRecords) {
	try {
		await axiosCredentialInstance.delete(`clinician/${clinicianId}/examinations`, {
			data: deleteRecords,
		});
	} catch (error) {
		console.log('deleteCertificationExams error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'deleteCertificationExams', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'deleteCertificationExams'
			);
		}
		//return error.message;
	}
	return null;
}

async function deleteBoardCertifications(clinicianId, deleteRecords) {
	try {
		await axiosCredentialInstance.delete(`clinician/${clinicianId}/boardcertifications`, {
			data: deleteRecords,
		});
	} catch (error) {
		console.log('deleteBoardCertifications error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'deleteBoardCertifications', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'deleteBoardCertifications'
			);
		}
		//return error.message;
	}
	return null;
}

async function deleteLicensure(clinicianId, deleteRecords) {
	try {
		await axiosCredentialInstance.delete(`clinician/${clinicianId}/licensures`, {
			data: deleteRecords,
		});
	} catch (error) {
		console.log('deleteLicensure error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'deleteLicensure', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'deleteLicensure'
			);
		}
		//return error.message;
	}
	return null;
}

async function deleteEducation(clinicianId, deleteRecords) {
	try {
		await axiosCredentialInstance.delete(`clinician/${clinicianId}/educations`, {
			data: deleteRecords,
		});
	} catch (error) {
		console.log('deleteEducation error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'deleteEducation', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'deleteEducation'
			);
		}
		//return error.message;
	}
	return null;
}

async function deleteWorkHistories(clinicianId, deleteRecords, type) {
	try {
		await axiosCredentialInstance.delete(`clinician/${clinicianId}/${type}`, {
			data: deleteRecords,
		});
	} catch (error) {
		console.log('deleteWorkHistories error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'deleteWorkHistories', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'deleteWorkHistories'
			);
		}
		//return error.message;
	}
	return null;
}

async function deleteMalpracticeClaimHistories(clinicianId, deleteRecords, type) {
	try {
		await axiosCredentialInstance.delete(`clinician/${clinicianId}/${type}`, {
			data: deleteRecords,
		});
	} catch (error) {
		console.log('deleteMalpracticeClaimHistories error=' + error);
		appInsights.trackException({
			exception: error,
			properties: {
				method: 'deleteMalpracticeClaimHistories',
				severityLevel: SeverityLevel.Error,
			},
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'deleteMalpracticeClaimHistories'
			);
		}
		//return error.message;
	}
	return null;
}

async function deleteMalpracticeCarriers(clinicianId, deleteRecords, type) {
	try {
		await axiosCredentialInstance.delete(`clinician/${clinicianId}/${type}`, {
			data: deleteRecords,
		});
	} catch (error) {
		console.log('deleteMalpracticeCarriers error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'deleteMalpracticeCarriers', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'deleteMalpracticeCarriers'
			);
		}
		//return error.message;
	}
	return null;
}

async function deleteTraining(clinicianId, deleteRecords, type) {
	try {
		await axiosCredentialInstance.delete(`clinician/${clinicianId}/${type}`, {
			data: deleteRecords,
		});
	} catch (error) {
		console.log('deleteTraining error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'deleteTraining', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'deleteTraining'
			);
		}
		//return error.message;
	}
	return null;
}

async function deleteClinicianReferences(clinicianId, deleteRecords, type) {
	try {
		await axiosCredentialInstance.delete(`clinician/${clinicianId}/${type}`, {
			data: deleteRecords,
		});
	} catch (error) {
		console.log('deleteClinicianReferences error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'deleteClinicianReferences', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'deleteClinicianReferences'
			);
		}
		//return error.message;
	}
	return null;
}

async function getClinicianFlags(externalId) {
	let response;
	try {
		response = await axiosSalesforceInstance.get('GetClinicianFlags', {
			params: {
				clinicianExternalId: externalId,
			},
		});
	} catch (error) {
		console.log('getClinicianFlags error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'getClinicianFlags', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getClinicianFlags'
			);
		}
		//return error.message;
	}
	if (!response || !response.data) return '';
	return response?.data;
}

async function postClinicianFlags(credFlagObject, externalId) {
	try {
		await axiosSalesforceInstance.post('PostClinicianFlags', credFlagObject, {
			params: {
				clinicianExternalId: externalId,
			},
		});
	} catch (error) {
		console.log('postClinicianFlags error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'postClinicianFlags', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'postClinicianFlags'
			);
		}
	}
}

async function patchClinicianFlags(credFlagObject, externalId) {
	try {
		await axiosSalesforceInstance.patch('PatchClinicianFlags', credFlagObject, {
			params: {
				clinicianExternalId: externalId,
			},
		});
	} catch (error) {
		console.log('patchClinicianFlags error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'patchClinicianFlags', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'patchClinicianFlags'
			);
		}
	}
}

async function postAzureB2cUserClaims(requestBody) {
	try {
		await axiosSalesforceInstance.post('UpdateAzureB2cUserClaims', requestBody);
	} catch (error) {
		console.log('postAzureB2cUserClaims error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'postAzureB2cUserClaims', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'postAzureB2cUserClaims'
			);
		}
	}
}

async function patchClinician(requestBody, externalId) {
	try {
		await axiosWCApiInstance.patch(`clinicians/${externalId}`, requestBody);
	} catch (error) {
		console.log(`patchClinician error=${error}`);
		appInsights.trackException({
			exception: error,
			properties: { method: 'patchClinician', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log(`throwing ApiError:${JSON.stringify(error.response.data)}`);
			throw new ApiError(error.response.data);
		} else if (error.message) {
			console.log(`throwing ApiError:${JSON.stringify(error.message)}`);
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'patchClinician'
			);
		}
	}
}

export {
	getPersonalInformation,
	postPersonalInformation,
	getEducation,
	postEducation,
	getTraining,
	postTraining,
	getBoardCertifications,
	postBoardCertifications,
	getOtherCertifications,
	postOtherCertifications,
	getCertificationExams,
	postCertificationExams,
	getWorkHistories,
	postWorkHistories,
	getMalpracticeCarriers,
	postMalpracticeCarriers,
	getMalpracticeClaimHistories,
	postMalpracticeClaimHistories,
	getDisclosureQuestions,
	postDisclosureQuestions,
	getClinicianReferences,
	postClinicianReferences,
	downloadDocument,
	deleteDocument,
	getLicensure,
	postLicensure,
	getGapsByType,
	postGapsByType,
	getClinicianCredApp,
	getCredAppStatus,
	postCredAppStatus,
	getClinicianCredAppCreatePdf,
	getClinicianCredAppPdfStatus,
	getClinicianCredAppSigningUrl,
	deleteOtherCertifications,
	deleteCertificationExams,
	deleteBoardCertifications,
	deleteLicensure,
	deleteEducation,
	deleteWorkHistories,
	deleteMalpracticeClaimHistories,
	deleteMalpracticeCarriers,
	deleteTraining,
	deleteClinicianReferences,
	patchPercentComplete,
	getClinicianCredAppDocumentURL,
	getClinicianCredAppDocumentLastApprovedURL,
	getClinicianFlags,
	postClinicianFlags,
	patchClinicianFlags,
	patchCredAppStatus,
	patchDisclosureQuestions,
	getClinicianCredAppCreateReAttestPdf,
	deleteDocumentWCAPI,
	postAzureB2cUserClaims,
	patchClinician,
};
